import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign, faGift } from '@fortawesome/free-solid-svg-icons';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import { GrCircleInformation } from "react-icons/gr";
import EntireDurationIcon from '../assets/icons/prixPourEnsNuits.png';
import PerNightIcon from '../assets/icons/prixParNuit.png';
import styles from '../IconsForNIghts.module.css';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 650, // Tu peux ajuster cette valeur selon tes besoins
      fontSize: "13px"
    },
  });

const IconForNights = ({ ruleType, amount, numberOfNights }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const parsedAmount = parseFloat(amount.replace(',', '.'));
   
    const tooltipTitleOffert = amount === "0,00"
        ? 'C\'est aussi un peu votre jour... Heu nuit ! Car les futurs mariés ont décidé de vous offrir votre séjour ! 🥳' 
        : `Les futurs mariés ont décidé d'appliquer un tarif de ${amount} €.`;

    const tooltipTitleEntireDuration = amount === "0,00"
    ? `${numberOfNights > 1 ? `Les ${numberOfNights} nuits vous sont intégralement offertes par vos hôtes !` : `La ${numberOfNights} nuit vous est intégralement offerte par vos hôtes`}`
    : `Le tarif est appliqué pour l'ensemble du séjour, même si vous dormez ${numberOfNights} nuit${numberOfNights > 1 ? 's' : ''}.`;

    const tooltipTitlePerNight = parsedAmount === 0
    ? `${numberOfNights > 1 ? `Les ${numberOfNights} nuits vous sont intégralement offertes par vos hôtes !` : `La ${numberOfNights} nuit vous est intégralement offerte par vos hôtes`}`
    : `Le tarif est appliqué appliqué par nuit. Dans votre cas : ${amount} x ${numberOfNights} = ${(parsedAmount * numberOfNights).toFixed(2)} €.`; // Use parsedAmount for calculation

    const tooltipPlacement = isSmallScreen ? 'bottom' : 'right';

    return (
        <div className={styles.iconForNight}>
            <CustomTooltip 
                title={tooltipTitleOffert}
                placement={tooltipPlacement}
                arrow
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={amount === "0,00" ? faGift : faEuroSign} size='lg' style={{ color: "#fff" }} />
                    <h1 className={styles.iconForNightH1}>{amount !== "0,00" ? `${amount}` : 'Offert'}</h1>
                    <GrCircleInformation style={{ color: "#fff", marginLeft: "10px", fontSize: "16px" }} />
                </div>
            </CustomTooltip>
            {ruleType === 'entireDuration' && (
                <CustomTooltip 
                    title={tooltipTitleEntireDuration}
                    placement={tooltipPlacement}
                    arrow
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={EntireDurationIcon} alt="Entire Duration" className={styles.iconSize} />
                        <GrCircleInformation style={{ color: "#fff", marginLeft: "10px", fontSize: "16px" }} />
                    </div>
                </CustomTooltip>
            )}
            {ruleType === 'perNight' && (
                <CustomTooltip 
                    title={tooltipTitlePerNight}  
                    placement={tooltipPlacement}
                    arrow
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={PerNightIcon} alt="Per Night" className={styles.iconSize} />
                        <GrCircleInformation style={{ color: "#fff", marginLeft: "10px", fontSize: "16px" }} />
                    </div>
                </CustomTooltip>
            )}
        </div>
    );
};

export default IconForNights;