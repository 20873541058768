import React from "react";
import { ResponsiveSankey } from '@nivo/sankey'
import styles from '../../../SankeyChart.module.css'

const RoomAffectationSankey = React.memo(({ data }) => {

    // const [legendConfig, setLegendConfig] = useState({
    //     anchor: 'left',
    //     direction: 'column',
    //     justify: false,
    //     translateX: -50,
    //     translateY: -50,
    //     itemsSpacing: 4,
    //     itemWidth: 100,
    //     itemHeight: 20,
    //     itemDirection: 'right-to-left',
    //     itemOpacity: 1,
    //     itemTextColor: '#777',
    //     symbolSize: 14,
    //     effects: [
    //         {
    //             on: 'hover',
    //             style: {
    //                 itemTextColor: '#000',
    //                 itemBackground: '#f7fafb'
    //             }
    //         }
    //     ]
    // });

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (window.innerWidth <= 1920 ) {
    //             setLegendConfig({
    //                 anchor: 'bottom',
    //                 direction: 'row',
    //                 justify: false,
    //                 translateX: -90,
    //                 translateY: -30,
    //                 itemsSpacing: 4,
    //                 itemWidth: 55,
    //                 itemHeight: 20,
    //                 itemDirection: 'top-to-bottom',
    //                 itemOpacity: 1,
    //                 itemTextColor: '#777',
    //                 symbolSize: 14,
    //                 effects: [
    //                     {
    //                         on: 'hover',
    //                         style: {
    //                             itemTextColor: '#000',
    //                             itemBackground: '#f7fafb'
    //                         }
    //                     }
    //                 ]
    //             });
    //         } else {
    //             setLegendConfig({
    //                 anchor: 'left',
    //                 direction: 'column',
    //                 justify: false,
    //                 translateX: -50,
    //                 translateY: -50,
    //                 itemsSpacing: 4,
    //                 itemWidth: 100,
    //                 itemHeight: 20,
    //                 itemDirection: 'right-to-left',
    //                 itemOpacity: 1,
    //                 itemTextColor: '#777',
    //                 symbolSize: 14,
    //                 effects: [
    //                     {
    //                         on: 'hover',
    //                         style: {
    //                             itemTextColor: '#000',
    //                             itemBackground: '#f7fafb'
    //                         }
    //                     }
    //                 ]
    //             });
    //         }
    //     };

    //     window.addEventListener('resize', handleResize);

    //     // Appel initial
    //     handleResize();

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    return (
        <div className={styles.sankeyChartContainer}>
            <ResponsiveSankey
                data={data}
                align="justify"
                colors={{ scheme: 'paired' }}
                nodeOpacity={1}
                nodeHoverOthersOpacity={0.35}
                nodeThickness={18}
                nodeSpacing={24}
                nodeBorderWidth={0}
                nodeBorderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.7
                        ]
                    ]
                }}
                nodeBorderRadius={3}
                linkOpacity={0.5}
                linkHoverOthersOpacity={0.1}
                linkContract={3}
                enableLinkGradient={true}
                labelPosition="outside"
                labelOrientation="vertical"
                labelPadding={16}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1
                        ]
                    ]
                }}
                // legends={[
                //     {
                //         anchor: 'top',
                //         direction: 'row',
                //         translateX: 70,
                //         translateY: -40,
                //         itemWidth: 100,
                //         itemHeight: 14,
                //         itemDirection: 'left-to-right',
                //         itemsSpacing: 2,
                //         itemTextColor: '#999',
                //         symbolSize: 14,
                //         effects: [
                //             {
                //                 on: 'hover',
                //                 style: {
                //                     itemTextColor: '#000'
                //                 }
                //             }
                //         ]
                //     }
                // ]}
            />
        </div>
    );
});

export default RoomAffectationSankey;