import React, { useRef, useState, useEffect } from "react";
import styles from '../SegmentedControl.module.css'

const SegmentedControl = ({
    name,
    segments,
    callback,
    defaultIndex = 0,
    controlRef,
    disabled
}) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    const componentReady = useRef();

    // Determine when the component is "ready"
    useEffect(() => {
        componentReady.current = true;
    }, []);

    useEffect(() => {
        const activeSegmentRef = segments[activeIndex].ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef.current;
        const { style } = controlRef.current;

        style.setProperty("--highlight-width", `${offsetWidth}px`);
        style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }, [activeIndex, callback, controlRef, segments]);

    const onInputChange = (value, index) => {
        if (!disabled) {
        setActiveIndex(index);
        callback(value, index);
        }
    };

    return (
            <div className={styles.controlsContainer} ref={controlRef}>
               <div className={`${styles.controls} ${componentReady.current ? styles.ready : styles.idle} ${disabled ? styles.disabled : ''}`}>
                    {segments?.map((item, i) => (
                        <div
                            key={item.value}
                            className={`${styles.segment} ${i === activeIndex ? styles.active : styles.inactive}`}
                            ref={item.ref}
                        >
                            <input
                                type="radio"
                                value={item.value}
                                id={item.label}
                                name={name}
                                onChange={() => onInputChange(item.value, i)}
                                checked={i === activeIndex}
                            />
                            <label htmlFor={item.label}>{item.label}</label>
                        </div>
                    ))}
                </div>
            </div>
    );
};

export default SegmentedControl;
