import React from 'react';
import styles from '../ImageModal.module.css'; // Assurez-vous d'avoir les styles appropriés
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ImageModal = ({ imageUrl, onClose }) => {
    const handleOverlayClick = (event) => {
        // Vérifie si le clic est sur l'overlay et non sur l'enfant (iframe)
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div className={styles.modalContent}>
                <img src={imageUrl} alt="Agrandissement" />
                <button onClick={onClose} className={styles.closeButton}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
        </div>
    );
};

export default ImageModal;