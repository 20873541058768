import sanClient from '../sanityClient';
import { getVenueID } from '../api/api';


export const fetchBuildingsRooms = async (userId, projectId) => {
  const query = `*[_type == "building" && weddingVenue._ref == $venueId]{
    _id,
    buildingName,
    totalBeds,
    rooms[]{
      _key,
      name,
      beds,
      "flags": flags[]->{
        title,
        _id,
        "iconUrl": icon.asset->url
      },
      groomRoom,
      floor,
      "bedSize": bedSize[]{
        "sizeType": sizeType->{
          title,
          _id,
          "iconUrl": icon.asset->url
        },
        quantity
      },
      "equipment": equipment[]->{
        title,
        _id,
        "iconUrl": icon.asset->url
      }
    },
    suites[]{
      suiteName,
      totalSuiteBeds,
      "suiteFlags": suiteFlags[]->{
        title,
        _id,
        "iconUrl": icon.asset->url
      },
      roomsInSuite[]{
        _key,
        name,
        beds,
        "flags": flags[]->{
          title,
          _id,
          "iconUrl": icon.asset->url
        },
        groomRoom,
        floor,
        "bedSize": bedSize[]{
          "sizeType": sizeType->{
            title,
            _id,
            "iconUrl": icon.asset->url
          },
          quantity
        },
        "equipment": equipment[]->{
          title,
          _id,
          "iconUrl": icon.asset->url
        }
      }
    },
    images
  }`;
  try {
    const venueData = await getVenueID(userId, projectId);
    const venueId = venueData.data.venueId;
    const Sanitytdata = await sanClient.fetch(query, { venueId });
    return Sanitytdata;
  } catch (error) {
    console.error('Erreur lors de la requête des bâtiments dans Sanity:', error);
    throw error;
  }
};


export const fetchGroomRoom = async (userId, projectId) => {
  const query = `*[_type == "building" && weddingVenue._ref == $venueId && count(rooms[coalesce(groomRoom, false) == true]) > 0]{
    _id,
    buildingName,
    totalBeds,
    "groomRooms": rooms[coalesce(groomRoom, false) == true]{ // Filtrer pour garder seulement les chambres groomRoom
      _key,
      name,
      beds,
      "flags": flags[]->{
        title,
        _id,
        "iconUrl": icon.asset->url
      },
      groomRoom,
      floor,
      "bedSize": bedSize[]{
        "sizeType": sizeType->{
          title,
          _id,
          "iconUrl": icon.asset->url
        },
        quantity
      },
      "equipment": equipment[]->{
        title,
        _id,
        "iconUrl": icon.asset->url
      }
    },
    images
  }`;

  try {
    const venueData = await getVenueID(userId, projectId);
    const venueId = venueData.data.venueId;
    const Sanitytdata = await sanClient.fetch(query, { venueId });
    return Sanitytdata;
  } catch (error) {
    console.error('Erreur lors de la requête des bâtiments dans Sanity:', error);
    throw error;
  }
};

export const fetchBuildingCapacity = async (buildingId) => {
  const query = `*[_type == "building" && _id == $buildingId] {
    totalBeds
  }`;

  try {
    const Sanitytdata = await sanClient.fetch(query, { buildingId });
    return Sanitytdata;
  } catch (error) {
    console.error('Erreur lors de la requête des bâtiments dans Sanity:', error);
    throw error;
  }
};
