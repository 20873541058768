import sanClient from '../sanityClient';
import { getLatLngProjectVenue, getDescAndHotels, getSartEndDates } from '../api/api';

export const fetchProjectInfo = async (userId, projectId) => {
  try {
    const projectDescAndHotels = await getDescAndHotels(userId, projectId);
    return projectDescAndHotels.data;
  } catch (error) {
    console.error('Erreur lors de la requête api:', error);
    throw error;
  }
};

export const fetchDatesInfo = async (projectId) => {
  try {
    const datesInfo = await getSartEndDates(projectId);
    return datesInfo;
  } catch (error) {
    console.error('Erreur lors de la requête api:', error);
    throw error;
  }
};

export const fetchSanityVenueInfo = async (userId, projectId) => {

  const query = `*[_type == "weddingVenue" && latitude == $latitude && longitude == $longitude]{
      name,
      address,
      website,
      description,
       "premiereImage": gallerie[2]{
      asset->{
        _id,
        url
      }
    }, 
    "deuxièmeImage": gallerie[4]{
      asset->{
        _id,
        url
      }
    },
    "troisièmeImage": gallerie[15]{
      asset->{
        _id,
        url
      }
    }, 
    "quatrièmeImage": gallerie[17]{
      asset->{
        _id,
        url
      }
    }
  }`;
  try {
    const venueLatLng = await getLatLngProjectVenue(userId, projectId);
    const latitude = venueLatLng.data.venue.latitude;
    const longitude = venueLatLng.data.venue.longitude;

    const data = await sanClient.fetch(query, { latitude, longitude });
    return data;
  } catch (error) {
    console.error('Erreur lors de la requête Sanity:', error);
    throw error;
  }
};

