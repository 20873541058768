import React, { useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import EmailEditor from 'react-email-editor';
import { Circles } from 'react-loader-spinner';
import styles from '../../EmailEditorYG.module.css';
import { saveEmailDesign, getCurrentUser, updateProjectStatus } from '../../api/api';

const MyEmailEditor = ({ editorType, emailContent, onClose, setSuccessMessage, setErrorMessage }) => {
    const emailEditorRef = useRef(null);
    const { projectId } = useParams();
    const [preview, setPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();


    const onReady = () => {
        // editor is ready
        console.log('onReady');
    };

    const onLoad = () => {
        if (editorType === 'venue') {
            emailEditorRef.current.editor.loadDesign(emailContent);
            emailEditorRef.current.editor.showPreview('desktop');
            setPreview(true);
        } else if (editorType === 'bnb') {
            emailEditorRef.current.editor.loadDesign(emailContent);
            emailEditorRef.current.editor.showPreview('desktop');
            setPreview(true);
        }
    };

    const togglePreview = () => {
        const unlayer = emailEditorRef.current?.editor;

        if (preview) {
            unlayer?.hidePreview();
            setPreview(false);
        } else {
            unlayer?.showPreview('desktop');
            setPreview(true);
        }
    };

    const saveDesign = () => {
        setIsLoading(true);

        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { html } = data;

            try {
                const userResponse = await getCurrentUser();
                const userId = userResponse.data._id;

                const response = await saveEmailDesign(userId, projectId, html, editorType);
                const updateCustStatus = updateProjectStatus(userId, projectId, 'customizationStatus', 'Terminé');
                const updateOverallStatus = updateProjectStatus(userId, projectId, 'overallStatus', 'Terminé');

                const [custResponse] = await Promise.all([updateCustStatus, updateOverallStatus]);


                if (response.status === 200 && custResponse.status === 200) {
                    setSuccessMessage('L\'email a été envoyé avec succès au backend.');
                    setTimeout(() => {
                        setSuccessMessage(null); // Réinitialisez le message de succès après 3 secondes
                        onClose(); // Fermez le modal après avoir affiché le message pendant un moment
                    }, 3000);
                    setTimeout(onClose, 1000);

                    history.push(`/dashboard/${projectId}`);

                } else {
                    throw new Error('Erreur lors de l\'envoi du design HTML au backend');
                }
            } catch (error) {
                console.error('Erreur lors de l\'envoi du design HTML :', error);
                setErrorMessage('Erreur lors de l\'envoi de l\'email au backend.');
                setTimeout(() => {
                    setErrorMessage(null); // Réinitialisez le message d'erreur après 3 secondes
                }, 3000);
            } finally {
                setIsLoading(false); // Termine l'animation de chargement
            }
        });
    };

    return (
        <div style={{ zIndex: 9999 }}>
            {isLoading && (
                <div className={styles.loadingContainer}>
                    <Circles
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        color="#5465ff"
                    />
                    <p className={styles.loadingMessage}>Envoi des emails à vos invités...</p>
                </div>
            )}
            {!isLoading && (
                <div className={styles.customTabContainer}>
                    <button onClick={togglePreview} className={styles.customSaveTab}>
                        {preview ? 'Cacher' : 'Afficher'} Prévisualisation
                    </button>
                    <button onClick={saveDesign} className={styles.customSaveTab}>
                        Sauvegarder et Envoyer
                    </button>
                </div>
            )}
            <EmailEditor
                ref={emailEditorRef}
                onReady={onReady}
                onLoad={onLoad}
                minHeight='1300px'
                options={{
                    projectId: 12345, // Remplacez par votre ID de projet Unlayer
                    appearance: {
                        theme: "dark",
                    },
                    tabs: {
                        content: {
                            enabled: false,
                        },
                        blocks: {
                            enabled: false,
                        },
                        body: {
                            enabled: false,
                        },
                        audit: {
                            enabled: false,
                        },
                        'custom#save': {
                            enabled: true,
                        }
                    },
                    tools: {
                        columns: {
                            enabled: false
                        },
                        image: {
                            enabled: false
                        },
                        heading: {
                            enabled: false
                        },
                        text: {
                            enabled: false
                        },
                        button: {
                            enabled: false
                        },
                        divider: {
                            enabled: false
                        },
                        html: {
                            enabled: false
                        },
                        menu: {
                            enabled: false
                        }

                    },
                    fonts: {
                        showDefaultFonts: false,
                        customFonts: [
                            {
                                label: 'Roboto',
                                value: 'Roboto, sans-serif',
                                url: 'https://fonts.googleapis.com/css?family=Roboto'
                            },
                            {
                                label: "Andale Mono",
                                value: "andale mono,times"
                            },
                            {
                                label: "Arial",
                                value: "arial,helvetica,sans-serif"
                            },
                            {
                                label: "Arial Black",
                                value: "arial black,avant garde,arial"
                            },
                            {
                                label: "Book Antiqua",
                                value: "book antiqua,palatino"
                            },
                            {
                                label: "Comic Sans MS",
                                value: "comic sans ms,sans-serif"
                            },
                            {
                                label: "Courier New",
                                value: "courier new,courier"
                            },
                            {
                                label: "Georgia",
                                value: "georgia,palatino"
                            },
                            {
                                label: "Helvetica",
                                value: "helvetica,sans-serif"
                            },
                            {
                                label: "Impact",
                                value: "impact,chicago"
                            },
                            {
                                label: "Symbol",
                                value: "symbol"
                            },
                            {
                                label: "Tahoma",
                                value: "tahoma,arial,helvetica,sans-serif"
                            },
                            {
                                label: "Terminal",
                                value: "terminal,monaco"
                            },
                            {
                                label: "Times New Roman",
                                value: "times new roman,times"
                            },
                            {
                                label: "Trebuchet MS",
                                value: "trebuchet ms,geneva"
                            },
                            {
                                label: "Verdana",
                                value: "verdana,geneva"
                            },
                            {
                                label: "Lobster Two",
                                value: "'Lobster Two',cursive",
                                url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700"
                            },
                            {
                                label: "Playfair Display",
                                value: "'Playfair Display',serif",
                                url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700"
                            },
                            {
                                label: "Rubik",
                                value: "'Rubik',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Rubik:400,700"
                            },
                            {
                                label: "Source Sans Pro",
                                value: "'Source Sans Pro',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700"
                            },
                            {
                                label: "Open Sans",
                                value: "'Open Sans',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700"
                            },
                            {
                                label: "Crimson Text",
                                value: "'Crimson Text',serif",
                                url: "https://fonts.googleapis.com/css?family=Crimson+Text:400,700"
                            },
                            {
                                label: "Montserrat",
                                value: "'Montserrat',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Montserrat:400,700"
                            },
                            {
                                label: "Old Standard TT",
                                value: "'Old Standard TT',serif",
                                url: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700"
                            },
                            {
                                label: "Lato",
                                value: "'Lato',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Lato:400,700"
                            },
                            {
                                label: "Raleway",
                                value: "'Raleway',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Raleway:400,700"
                            },
                            {
                                label: "Cabin",
                                value: "'Cabin',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Cabin:400,700"
                            },
                            {
                                label: "Pacifico",
                                value: "'Pacifico',cursive",
                                url: "https://fonts.googleapis.com/css?family=Pacifico"
                            }
                        ]
                    },
                }}
            />
        </div>
    );
};

export default MyEmailEditor;