import React from 'react';
import styles from '../../stripeDashboard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcStripe } from '@fortawesome/free-brands-svg-icons';

const StripeDashboard = ({ onOpenDashboard }) => {
  return (
    <div className={styles.dashboard}>
      <h2>Votre portefeuille</h2> 
      <button 
        type="button"
        className={styles.button} 
        onClick={onOpenDashboard}
      >
        Accéder à mon dashboard <FontAwesomeIcon icon={faCcStripe} size='2xl' style={{color: "#ffffff", marginLeft: '5px'}} />
      </button>
    </div>
  );
};

export default StripeDashboard;