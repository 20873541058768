import React, { useState, useEffect } from 'react';
import { useRateConfig } from '../contexts/RateConfigContext';

import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { getNumberOfNights, setPrice } from '../api/api';
import styles from '../PriceApplicationSelector.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmark } from '@fortawesome/free-solid-svg-icons';

const PriceApplicationSelector = () => {
    const { projectId } = useParams();
    const [numberOfNights, setNumberOfNights] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);
    const [areSaved, setAreSaved] = useState(false);
    const [areSavedFailed, setareSavedFailed] = useState(false);
    // const [pricing, setRate] = useState({ rate: '', ruleType: 'perNight', specialFamilyRate: null });
    const { rate, setRate, ruleType, setRuleType, refreshConfigs, resetContext } = useRateConfig();

     useEffect(() => {
        const fetchNumberOfNights = async () => {
            try {
                const nights = await getNumberOfNights(projectId);
                setNumberOfNights(nights);
            } catch (error) {
                console.error('Erreur lors de la récupération du nombre de nuits:', error);
            }
        };

        fetchNumberOfNights();
    }, [projectId]);

    const rateOptions = [
        { value: 'perNight', label: 'Par nuit' },
        { value: 'entireDuration', label: 'Pour l\'ensemble des nuits' }
    ];
    
    const handleRateChange = (e) => {
        setRate(e.target.value);
    };

    // Exemple de fonction pour soumettre les règles de tarification au backend
    const submitPricing = async () => {
        try {
            const response = await setPrice(projectId, rate, ruleType);

            if (response.success) {
                setSuccessMessage("Prix et options correctement enregitrés !");
                setTimeout(() => {
                    setSuccessMessage(null);
                }, 3000);
                setAreSaved(true);

                await refreshConfigs(projectId);
                resetContext();
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour du prix:', error);
            setError("Une erreur s'est produite lors de la mise à jour du prix.");
            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
            setareSavedFailed(true);
        }
    };

    const handleruleTypeChange = (selectedOption) => {
        setRuleType(selectedOption.value);
        // Mettre à jour le localStorage
        const ratesToSave = { rate: rate, ruleType: selectedOption.value };
        localStorage.setItem('RatesInfo', JSON.stringify(ratesToSave));
    };

    useEffect(() => {
        if (rate && ruleType) {
            const ratesToSave = { rate: rate, ruleType: ruleType };
            localStorage.setItem('RatesInfo', JSON.stringify(ratesToSave));
        }
    }, [rate, ruleType]);

    useEffect(() => {
        const savedRates = localStorage.getItem('RatesInfo');
        if (savedRates) {
            const { rate, ruleType } = JSON.parse(savedRates);
            if (rate && ruleType) {
                setRate(rate);
                setRuleType(ruleType);
            }
        }
    }, []);

    return (
        <section id="price" className={styles.rateInputContainer}>
            <h2 className={styles.calculatorTitle}>
                Fixez un prix et choisissez comment le calculer
            </h2>
            {numberOfNights === 1 ? (
                <>
                    <p className={styles.priceSelectorDescription}>
                        Définissez le prix du couchage pour vos invités.
                    </p>
                    <div className={styles.priceInputRow}>
                        <div className={styles.inputWithEuro}>
                            <input
                                className={styles.inputWithEuroInput}
                                type="number"
                                value={rate}
                                onChange={handleRateChange}
                                placeholder="Tarif"
                                min={0}
                                step={0.01}
                            />
                        </div>
                        <span>S’applique</span>
                        <Select
                            options={rateOptions}
                            value={rateOptions.find(option => option.value === (numberOfNights === 1 ? 'perNight' : ruleType))}
                            isDisabled={true}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    width: 250,
                                    borderRadius: '10px'
                                }),
                            }}
                        />
                    </div>

                </>
            ) : (
                <>
                    <p className={styles.priceSelectorDescription}>
                        Définissez le prix du couchage pour vos invités et précisez comment vous l'appliquez : par nuit ou pour l'ensemble des nuits.
                    </p>
                    <div className={styles.priceInputRow}>
                        <div className={styles.inputWithEuro}>
                            <input
                                className={styles.inputWithEuroInput}
                                type="number"
                                onChange={handleRateChange}
                                value={rate}
                                placeholder="Tarif"
                                min={0}
                                step={0.01}
                            />
                        </div>

                        <span>S’applique</span>

                        <Select
                            options={rateOptions}
                            value={rateOptions.find(option => option.value === ruleType)}
                            onChange={handleruleTypeChange}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    width: 250, // Vous pouvez ajuster la largeur ici
                                    borderRadius: '10px'
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: 1000, // Augmenter le z-index pour s'assurer que le menu est au-dessus des autres éléments
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    border: `1px dotted`,
                                    height: '100%',
                                    borderRadius: '4px', // Ajout d'un border-radius
                                    backgroundColor: state.isSelected ? '#3324bb' : state.isFocused ? '#E0E0E0' : provided.backgroundColor,
                                    color: state.isFocused ? 'white' : provided.color,
                                    '&:hover': {
                                        backgroundColor: '#7a7ccf',
                                        color: 'white',
                                        borderRadius: '4px', // Appliquer aussi le border-radius au hover
                                    },
                                }),
                            }}
                        />
                    </div>
                </>
            )}
            <div className={styles.buttonContainer}>
                <button
                    className={styles.buttonConfirmer}
                    onClick={submitPricing}
                >
                    Enregistrer le prix
                </button>
                {areSaved && <FontAwesomeIcon icon={faCheckCircle} size="xl" className="ml-2 text-green-500" />}
                {areSavedFailed && <FontAwesomeIcon icon={faXmark} size="xl" className="ml-2 text-red-500" />}
            </div>
            {error && <p className="text-red-500 mt-10">{error}</p>}
            {successMessage && <p className="text-green-500 mt-10">{successMessage}</p>}
        </section>
    );
};

export default PriceApplicationSelector;
