import React, { useEffect } from 'react';
import { Link } from 'react-scroll'; // Assuming you're using react-scroll for navigation
import IconDashboard from '../assets/icons/Icone_dashboard.png';
import IconCagnotte from '../assets/icons/Icone_cagnotte.png';
import IconInvites from '../assets/icons/Icone_invites.png';
import IconMaps from '../assets/icons/Icone_map.png';
import IconPerso from '../assets/icons/Icone_personnalisation.png';
import IconPrix from '../assets/icons/Icone_prix.png';
import MainsMaries from '../assets/Images/Mains_mariés_rogné.jpg';
import '../NosServicesSection.css'; // Import the specific CSS for this component

const detectScale = () => {
  const scale = window.devicePixelRatio || 1;
  if (scale > 1) {
    document.body.classList.add('scaled');
  }
};

export default function NosServicesSection() {
 
  useEffect(() => {
    detectScale();
  }, []);

  return (
    <section id="nos-services" className="nos-services">
      <div>
        <h1 className='font-bold text-4xl mb-8 roboto'>
          Nos Services
        </h1>
        <h2 className='text-2xl roboto text-gray-700'>
          Offrez à vos convives une expérience unique et sur-mesure de réservations de chambres sur le lieu ou à proximité de votre mariage
        </h2>
      </div>
      <div className="grid">
        {/* Colonne de gauche */}
        <div className="flex flex-col items-center justify-start">
          <img src={IconInvites} alt="Icon Invites" className="service-icon mb-5 iconZoom" />
          <p className="roboto text-gray-500">Importez votre/vos listes d'invités et configurez les options auxquelles ils auront accès (formats .csv, .xlsx)</p>
          <img src={IconMaps} alt="Icon Maps" className="service-icon mt-5 mb-5 iconZoom" />
          <p className="roboto text-gray-500">Ajoutez votre lieu de mariage pour y retrouver les espaces de couchages, chambres et les autres hébergements aux alentours</p>
          <img src={IconPerso} alt="Icon Personnalisation" className="service-icon mt-5 mb-5 iconZoom" />
          <p className="roboto text-gray-500">Définissez en détail votre espace de réservation (invités dédiés, date de rappel et de clôture, texte personnalisable, photos...)</p>
        </div>

        {/* Colonne du milieu */}
        <div className="middle-column flex items-center justify-center">
          <img src={MainsMaries} alt="Mains de Mariés" className="w-full h-auto object-cover mx-auto" />
        </div>

        {/* Colonne de droite */}
        <div className="flex flex-col items-center justify-start">
          <img src={IconPrix} alt="Icon Prix" className="service-icon mb-5 iconZoom" />
          <p className="roboto text-gray-500">Fixer un prix pour la réservation des chambres et/ou offrez les à une ou plusieurs personnes</p>
          <img src={IconDashboard} alt="Icon Dashboard" className="service-icon mt-5 mb-5 iconZoom" />
          <p className="roboto text-gray-500">Suivez en temps réel l'état des réservations. Prévisualisez le montant de votre cagnotte. Relancez vos invités avant la date de clôture</p>
          <img src={IconCagnotte} alt="Icon Cagnotte" className="service-icon mt-5 mb-5 iconZoom" />
          <p className="roboto text-gray-500">Choisissez d'opter pour une participation pécunière de la part de vos convives, et percevez la de votre portefeuille en ligne sur votre compte bancaire</p>
        </div>
      </div>
    </section>
  );
}