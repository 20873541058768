import React, { useState } from 'react';
import styles from '../RoomCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


const RoomCardCust = ({ room, isSelected, onSelect }) => {
  //handleReserveRoom
  const [isExpanded, setIsExpanded] = useState(false);

  const roomCardClasses = [
    // room.groomRoom ? (isSelected ? styles.groomRoomSelected : styles.groomRoom) : '',
    styles.roomCustBorder,
    room.booked ? styles.booked : '',
    isSelected ? styles.selected : ''
  ].join(' ');


  return (
    <div
      className={roomCardClasses}
      onClick={() => {
        onSelect(room._key || room.roomId);
      }}
      style={{
        cursor: 'pointer',
        margin: '10px',
        borderRadius: '5px',
        border: room.groomRoom ? (isSelected ? '2px solid #ad0c4a' : '2px solid #ff0055') : (isSelected ? '1px solid #2d004b' : '1px solid #aaaaaa')
      }}
    >
      {room.groomRoom && (
        <span style={{ display: 'flex', alignItems: 'center', color: "#ff0055", padding: '5px' }}>
          <FontAwesomeIcon icon={faHeart} />
          <span className={styles.groomRoomp}>Recommandée pour les mariés</span>
        </span>
      )}
      <div className={styles.roomCardCustContent}>
        <div className={styles.roomCardInfo}>
          <h3 className={styles.roomCardH3}>
            {room.groomRoom ? `${room.name} | ${room.buildingName}` : room.name} 
          </h3>
          {room.booked && <p style={{ color: '#5465ff' }}>Cette chambre est déjà réservée</p>}
          <p>{room.beds || room.bedsCount} personnes</p>
          {isExpanded && (
            <>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: '10px' }}>
                {room.flags && room.flags.map(flag => (
                  <img key={flag._id} src={flag.iconUrl} alt={flag.title} title={flag.title} style={{ marginRight: '5px', width: '2.5em', height: 'auto' }} />
                  // 

                ))}
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: '10px' }}>
                {room.equipment && room.equipment.map(equip => (
                  <img key={equip._id} src={equip.iconUrl} alt={equip.title} title={equip.title} style={{ marginRight: '5px', width: '2.5em', height: 'auto' }} />
                  // 
                ))}
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: '10px' }}>
                {room.bedSize && room.bedSize.map(size => (
                  <div key={size.sizeType._id} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <span style={{ marginRight: '5px' }}>{size.quantity} x</span>
                    <img src={size.sizeType.iconUrl} alt={size.sizeType.title} title={size.sizeType.title} style={{ width: '2.5em', height: 'auto' }} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <button onClick={() => setIsExpanded(!isExpanded)} className={styles.expandButton}>
        {isExpanded ? "Moins d'infos" : "Plus d'infos"}
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className={styles.chevronIcon} />
      </button>
    </div>
  );
};


export default RoomCardCust;