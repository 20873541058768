import React, { useState, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import moment from 'moment';
import 'moment/locale/fr';
import styles from '../CountDown.module.css'

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;



const getTimeSeconds = (time) => (time) | 0;
const getTimeMinutes = (time) => (Math.floor((time % hourSeconds) / minuteSeconds)) | 0;
const getTimeHours = (time) => (Math.floor((time % daySeconds) / hourSeconds)) | 0;
const getTimeDays = (time) => (Math.floor(time / daySeconds)) | 0;

const renderTime = (dimension, time) => {
    return (
        <div >
            <div className={styles.countDownTime}>{time}</div>
            <div>{dimension}</div>
        </div>
    );
};


const CountdownTimer = ({ endDate }) => {
    const [circleSize, setCircleSize] = useState(180);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 360) {
                setCircleSize(120);
            } else if (window.innerWidth < 450) {
                setCircleSize(150);
            } else {
                setCircleSize(180);
            }
        };

        handleResize(); // Set initial size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const timerProps = {
        isPlaying: true,
        size: circleSize, // dynamic size
        strokeWidth: 6
    };

    const adjustedStartDate = moment.utc(endDate).add(2, 'hours');
    const startTime = Date.now() / 1000; // Temps actuel en secondes
    const endTime = new Date(adjustedStartDate).getTime() / 1000; // Durée du timer en secondes
    const duration = Math.max(0, endTime - startTime);

    const days = Math.floor(duration / daySeconds);
    const daysDuration = Math.floor(days * daySeconds);
    const hours = Math.floor((duration % daySeconds) / hourSeconds);
    const minutes = Math.floor((duration % hourSeconds) / minuteSeconds);
    const seconds = Math.floor(duration % minuteSeconds);

    return (
        <div className={styles.countDown}>
            <svg style={{ position: 'absolute', width: '0', height: '0' }}>
                <defs>
                    <linearGradient id="daysGradient" x1="1" y1="0" x2="0" y2="0">
                        <stop offset="10%" stopColor="#212866" />
                        <stop offset="90%" stopColor="#080a19" />
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient id="hoursGradient" x1="1" y1="0" x2="0" y2="0">
                        <stop offset="10%" stopColor="#3a46b2" />
                        <stop offset="90%" stopColor="#212866" />
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient id="minutesGradient" x1="1" y1="0" x2="0" y2="0">
                        <stop offset="10%" stopColor="#5465ff" />
                        <stop offset="90%" stopColor="#3a46b2" />
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient id="secondsGradient" x1="1" y1="0" x2="0" y2="0">
                        <stop offset="10%" stopColor="#7683ff" />
                        <stop offset="90%" stopColor="#5465ff" />
                    </linearGradient>
                </defs>
            </svg>
            <div className={styles.countDownDiv}>
                <CountdownCircleTimer
                    {...timerProps}
                    colors="url(#daysGradient)"
                    duration={daysDuration}
                    initialRemainingTime={duration}
                    onComplete={(totalElapsedTime) => ({
                        shouldRepeat: (days - getTimeDays(totalElapsedTime)) > 0,
                        delay: 1
                    })}
                >
                    {({ elapsedTime }) => (
                        <span>
                            {renderTime("jours", getTimeDays(daysDuration - elapsedTime))}
                        </span>
                    )}
                </CountdownCircleTimer>
            </div>
            <div className={styles.countDownDiv}>
                <CountdownCircleTimer
                    {...timerProps}
                    colors="url(#hoursGradient)"
                    duration={daySeconds}
                    initialRemainingTime={duration % daySeconds}
                    onComplete={() => ({
                        shouldRepeat: true
                    })}
                >
                    {({ elapsedTime }) => (
                        <span>
                            {renderTime("heures", getTimeHours(daySeconds - elapsedTime))}
                        </span>
                    )}
                </CountdownCircleTimer>
            </div>
            <div className={styles.countDownDiv}>
                <CountdownCircleTimer
                    {...timerProps}
                    colors="url(#minutesGradient)"
                    duration={hourSeconds}
                    initialRemainingTime={duration % hourSeconds}
                    onComplete={(totalElapsedTime) => ({
                        shouldRepeat: (duration - totalElapsedTime) > minuteSeconds,
                    })}
                >
                    {({ elapsedTime }) => (
                        <span>
                            {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
                        </span>
                    )}
                </CountdownCircleTimer>
            </div>
            <div className={styles.countDownDiv}>
                <CountdownCircleTimer
                    {...timerProps}
                    colors="url(#secondsGradient)"
                    duration={minuteSeconds}
                    initialRemainingTime={duration % minuteSeconds}
                    onComplete={(totalElapsedTime) => ({
                        shouldRepeat: duration - totalElapsedTime > 0
                    })}
                >
                    {({ elapsedTime }) => (
                        <span>
                            {renderTime("secondes", getTimeSeconds(minuteSeconds - elapsedTime))}
                        </span>
                    )}
                </CountdownCircleTimer>
            </div>
        </div>
    );
};


export default CountdownTimer;
