import React, { useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Carousel from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../RoomCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GrCircleInformation } from "react-icons/gr";
import CarousselArrows from './CarousselArrows';
import '../Slider.css';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 650,
  },
});

const RoomCard = ({ room, isSelected, onSelect, isSelectedByAnother, isReservedByAnother, isCanceledByAnother, isConfirmedByAonther, extraBedCost, amount, onOpenModal }) => {
  //handleReserveRoom
  const [isExpanded, setIsExpanded] = useState(false);
  const imagesToShow = room.roomImgUrl ? room.roomImgUrl : room.buildingImgUrl;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CarousselArrows direction="next" />,
    prevArrow: <CarousselArrows direction="prev" />
  };

  const roomCardClasses = [
    imagesToShow.length === 1 ? styles.roomCardNoCarousel : styles.roomCardCarousel,
    room.booked ? styles.booked : '',
    isSelected ? styles.selected : '',
    room.groomRoom ? (isSelected ? styles.groomRoomSelected : styles.groomRoom) : '',
    isReservedByAnother ? styles.inCart : '',
    isSelectedByAnother ? styles.consulting : '',
    isCanceledByAnother ? styles.roomCard : '',
    isConfirmedByAonther ? styles.booked : '',
  ].join(' ');


  const totalAmountWithExtraBeds = amount + (extraBedCost * room.extraBedsUsed);
  const shouldShowTooltip = amount < totalAmountWithExtraBeds;

  const tooltipTitle = `Pour cette chambre, ${room.extraBedsUsed} lit${room.extraBedsUsed > 1 ? 's' : ''} supplémentaire${room.extraBedsUsed > 1 ? 's' : ''} ${room.extraBedsUsed > 1 ? 'sont' : 'est'} nécessaire${room.extraBedsUsed > 1 ? 's' : ''}. Coût: ${amount}€ + (${room.extraBedsUsed} x ${extraBedCost}€) = ${totalAmountWithExtraBeds}€`;

  const formattedTotalAmount = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(totalAmountWithExtraBeds);

  const getFloorLabel = (floor) => {
    if (!floor) return ''; // Retourne une chaîne vide si floor n'est pas défini
    return floor === 'rdc' ? 'Rdc' : `${floor} étage`;
  };

  const pmrFlag = room.flags?.find(flag => flag.title === "PMR");

  return (
    <div
      className={`${styles.roomCard} ${roomCardClasses}`}
      onClick={() => {
        onSelect(room._key || room.roomId);
      }}
      style={{
        cursor: 'pointer',
        // padding: '10px',
        margin: '10px',
        borderRadius: '5px',
        backgroundColor: isSelectedByAnother || isReservedByAnother ? '#f0f0f0' : 'white',
      }}
    >
      {imagesToShow && imagesToShow.length > 0 && (
        <>
          {imagesToShow.length === 1 ? (
            <div className={styles.roomCardImageContainer}>
              <div className={styles.imageContainer}>
                <img src={imagesToShow[0]} className={`${styles.image} ${styles.roomCardNoCarousel}`} />
              </div>
            </div>
          ) : (
            <div className={styles.roomCardImageContainerCarousel}>
              <Carousel {...settings}>
                {imagesToShow.map((imgUrl, index) => (
                  <div key={`${imgUrl}-${index}`} className={styles.roomCardImageContainerCarousel}>
                    <img src={imgUrl} className={`${styles.image} ${styles.roomCardCarouselImg}`} />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </>
      )}

      {room.groomRoom && (
        <span style={{ display: 'flex', alignItems: 'center', color: "#ff0055" }}>
          <FontAwesomeIcon icon={faHeart} />
          <span className={styles.groomRoomp}>Recommandée pour les mariés</span>
        </span>
      )}
      <div className={styles.roomCardContent}>
        <div className={styles.roomCardInfo}>
          <h2>
            {room.buildingName}
          </h2>
          <h3 className={styles.roomCardH3}>
            {room.groomRoom ? `${room.name} | ${room.buildingName}` : room.name} {pmrFlag && <img src={pmrFlag.iconUrl} title="Chambre PMR" style={{ width: '20px', height: '20px', marginLeft: '5px' }} />}
          </h3>
          {room.booked && <p style={{ color: '#5465ff' }}>Cette chambre est déjà réservée</p>}
          {isSelectedByAnother && !isReservedByAnother && (
            <p style={{ color: '#5465ff' }}>Cette chambre est consultée par d'autres invités</p>
          )}
          {isReservedByAnother && (
            <p style={{ color: '#ff5465' }}>Un autre invité réserve actuellement cette chambre, elle pourrait ne plus être disponible</p>
          )}
          <p>{room.beds || room.bedsCount} personnes</p>
          <CustomTooltip
            title={`Afin de pouvoir vous loger, vous aurez besoin de ${room.extraBedsUsed} lit${room.extraBedsUsed > 1 ? 's' : ''} supplémentaire${room.extraBedsUsed > 1 ? 's.' : ''}`}
            placement="bottom"
          >
            {room.extraBedsUsed > 0 && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p className={styles.roomCardExtraBed}>Besoin en lits d'appoint : {room.extraBedsUsed}</p>
                <GrCircleInformation style={{ color: "#5465ff", marginLeft: "10px", fontSize: "16px" }} />
              </div>

            )}
          </CustomTooltip>
          {isExpanded && (
            <>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: '10px' }}>
                {room.flags && room.flags.map(flag => (
                  <img key={flag._id} src={flag.iconUrl} title={flag.title} style={{ marginRight: '5px', width: '2.5em', height: 'auto' }} />
                  // 

                ))}
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: '10px' }}>
                {room.equipment && room.equipment.map(equip => (
                  <img key={equip._id} src={equip.iconUrl} title={equip.title} style={{ marginRight: '5px', width: '2.5em', height: 'auto' }} />
                  // 
                ))}
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: '10px' }}>
                {room.bedSize && room.bedSize.map(size => (
                  <div key={size.sizeType._id} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <span style={{ marginRight: '5px' }}>{size.quantity} x</span>
                    <img src={size.sizeType.iconUrl} title={size.sizeType.title} style={{ width: '2.5em', height: 'auto' }} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div>
          {shouldShowTooltip ? (
            <CustomTooltip title={tooltipTitle}>
              <h3 className={styles.roomCardH3}>{formattedTotalAmount} <GrCircleInformation style={{ color: "#5465ff", marginLeft: "10px", fontSize: "16px" }} /></h3>
            </CustomTooltip>
          ) : (
            <h3 className={styles.roomCardH3} >{formattedTotalAmount}</h3>
          )}
        </div>
      </div>
      <button onClick={() => setIsExpanded(!isExpanded)} className={styles.expandButton}>
        {isExpanded ? "Moins d'infos" : "Plus d'infos"}
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className={styles.chevronIcon} />
      </button>
      {isSelected && (
        <div className={styles.buttonContainer}>
          <button
            className={styles.buttonConfirmer}
            onClick={(e) => {
              e.stopPropagation();
              onOpenModal(room);
              // handleReserveRoom(room);
            }}
          >
            Réserver cette chambre
          </button>
        </div>
      )}
    </div>
  );
};


export default RoomCard;