import React from 'react';
import styles from '../NavDot.module.css';

function Dot({ label, targetId, dotClass, labelClass }) {
    const [hover, setHover] = React.useState(false);

    const handleClick = () => {
        const target = document.getElementById(targetId);
        if (target) {
            window.scrollTo({
                top: target.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div 
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleClick}
            className={styles.dot}
        >
            {hover && (
                <div className={styles.label}>
                    {label}
                </div>
            )}
        </div>
    );
}

export default Dot;
