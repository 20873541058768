import React from 'react';
import '../DeviceNotification.css'; // Import the CSS for styling

const DeviceNotification = ({ onClose }) => {
  return (
    <div className="device-notification">
      <p>Le site n'est pas optimisé pour les smartphones et les tablettes. Pour une meilleure expérience, veuillez utiliser un ordinateur.</p>
      <button onClick={onClose} className="close-button">Fermer</button>
    </div>
  );
};

export default DeviceNotification;
