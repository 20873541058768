import React, { useState } from 'react';
import styles from '../DonationInput.module.css';

const DonationInput = ({ initialAmount, onAmountChange }) => {
  const [amount, setAmount] = useState(initialAmount); // Start with a minimum of initialAmount + 10

  // Handle input change and ensure minimum amount
  const handleAmountChange = (newAmount) => {
    if (newAmount >= initialAmount + 10 || newAmount === initialAmount) {
      setAmount(newAmount);
      onAmountChange(newAmount); // Notify parent component of the change
    }
  };

  // Quick add function to increase amount by specified increment
  const handleQuickAdd = (increment) => {
    const newAmount = amount + increment;
    setAmount(newAmount);
    onAmountChange(newAmount);
  };

  return (
    <div className={styles.inputDonationContainer}>
      <label htmlFor="donationAmount" className={styles.labelInputDonation}>
        Montant du Don:
      </label>
      <div className={styles.inputContainer}>
        <input
          type="number"
          id="donationAmount"
          value={amount}
          onChange={(e) => handleAmountChange(parseFloat(e.target.value))}
          min={initialAmount + 10}
          step={10} // Step by 10 when using chevrons
          className={styles.inputDonation}
        />
        <span className={styles.devise}>€</span>
      </div>
      <div className={styles.quickAddContainer}>
        <button onClick={() => handleQuickAdd(10)} className={styles.quickAddButton}>+10€</button>
        <button onClick={() => handleQuickAdd(20)} className={styles.quickAddButton}>+20€</button>
        <button onClick={() => handleQuickAdd(50)} className={styles.quickAddButton}>+50€</button>
        <button onClick={() => handleQuickAdd(100)} className={styles.quickAddButton}>+100€</button>
      </div>
    </div>
  );
};

export default DonationInput;
