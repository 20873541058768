export const mapRelationshipToGroupID = (relationship) => {
    // Votre logique ici
    switch (relationship) {
        case 'Parent':
            return 1;
        case 'Partenaire':
            return 2;
        case 'Célibataire':
            return 3;
        case 'Enfant':
            return 4;
        default:
            return -1;
    }
};