import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createNewProject } from '../api/api';
import { AuthContext } from '../contexts/AuthContext';
import '../Creer.css';

export default function Creer() {
  const history = useHistory();
  const { user } = useContext(AuthContext); // Utilisation de useContext ici
  const userId = user ? user._id : null; // Récupération de l'ID utilisateur
  const [description, setDescription] = useState("");

  if (!user) {
    // Rediriger vers la page de connexion ou afficher un message d'erreur
    history.push('/login-register');
  }

  const handleNextClick = async () => {
    try {
      const desc = description || null;
      const response = await createNewProject({ userId, description: desc });

      if (response.data && response.data._id) {
        history.push(`/creer/lieu-du-mariage/${response.data._id}`);
        //history.push(`/creer/depot-fichier/${response.data._id}`); // Redirige vers la prochaine étape avec l'ID du projet
      } else {
        console.error('Erreur : ID du projet non reçu');
      }
    } catch (error) {
      console.error('Erreur lors de la création du nouveau projet:', error);
      // Gérer l'erreur (afficher un message à l'utilisateur, etc.)
    }
  };


  return (
    <div className="creer-container roboto">
      <h1>Créer votre nouveau projet</h1>
      <div className="input-container">
        <label>Veuillez renseigner le nom de votre projet.</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleNextClick();  // La fonction qui gère la confirmation
            }
          }}
          placeholder="Mariage de Paul & Ange"
        />
      </div>
      <button
        className="creer-button"
        onClick={handleNextClick}
      >
        Nouveau projet
      </button>
    </div>
  );

}
