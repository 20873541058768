import React, { useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import 'font-awesome/css/font-awesome.min.css';
import '../NouvelleListe.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';

function GenericTableComponent({ data, handleEdit, handleDelete, handleOfferChange, handleRelationshipChange, rowCount, handleCheckboxClick, isExpanded, toggleExpand, savedSort, setSavedSort, isColorMode }) {
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'NOM',
        Cell: ({ row }) => {
          const [localValue, setLocalValue] = React.useState(row.original.NOM);

          const handleBlur = () => {
            const actualId = row.original._id || row.original.id;
            handleEdit(actualId, 'NOM', localValue);
          };
          const handleFocus = (event) => event.target.select();

          return (
            <input
              value={localValue}
              onChange={e => setLocalValue(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              style={{
                backgroundColor: isColorMode && row.original.subGroupID ? row.original.subGroupID.color : 'transparent',
                color: isColorMode && row.original.subGroupID ? row.original.subGroupID.textColor : 'inherit'
              }}
            />
          );
        }
      },
      {
        Header: 'Prénom',
        accessor: 'PRENOM',
        Cell: ({ row }) => {
          const [localValue, setLocalValue] = React.useState(row.original.PRENOM);

          const handleBlur = () => {
            const actualId = row.original._id || row.original.id;
            handleEdit(actualId, 'PRENOM', localValue);
          };
          const handleFocus = (event) => event.target.select();

          return (
            <input
              value={localValue}
              onChange={e => setLocalValue(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              style={{
                backgroundColor: isColorMode && row.original.subGroupID ? row.original.subGroupID.color : 'transparent',
                color: isColorMode && row.original.subGroupID ? row.original.subGroupID.textColor : 'inherit'
              }}
            />
          );
        }
      },
      {
        Header: 'Email',
        accessor: 'EMAIL',
        Cell: ({ row }) => {
          const [localValue, setLocalValue] = React.useState(row.original.EMAIL);

          const handleBlur = () => {
            const actualId = row.original._id || row.original.id;
            handleEdit(actualId, 'EMAIL', localValue);
          };
          const handleFocus = (event) => event.target.select();

          return (
            <input
              value={localValue}
              onChange={e => setLocalValue(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              style={{
                backgroundColor: isColorMode && row.original.subGroupID ? row.original.subGroupID.color : 'transparent',
                color: isColorMode && row.original.subGroupID ? row.original.subGroupID.textColor : 'inherit'
              }}
            />
          );
        }
      },
      {
        Header: 'Téléphone',
        accessor: 'TELEPHONE',
        Cell: ({ row }) => {
          const [localValue, setLocalValue] = React.useState(row.original.TELEPHONE);

          const handleBlur = () => {
            const actualId = row.original._id || row.original.id;
            handleEdit(actualId, 'TELEPHONE', localValue);
          };
          const handleFocus = (event) => event.target.select();

          return (
            <input
              value={localValue}
              onChange={e => setLocalValue(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              style={{
                backgroundColor: isColorMode && row.original.subGroupID ? row.original.subGroupID.color : 'transparent',
                color: isColorMode && row.original.subGroupID ? row.original.subGroupID.textColor : 'inherit'
              }}
            />
          );
        }
      },
      {
        Header: 'Relation',
        accessor: 'relationship',
        Cell: ({ row }) => {
          const actualId = row.original._id || row.original.id;  // Utilisez l'_id ou id, selon ce qui est disponible
          return (
            <select
              value={row.original.relationship || 'Sélectionner'}
              onChange={e => handleRelationshipChange(e, actualId)}
            >
              <option value="">Sélectionner</option>
              <option value="Parent">Parent</option>
              <option value="Enfant">Enfant</option>
              <option value="Célibataire">Seul/Célibataire</option>
              <option value="Partenaire">Couple/Partenaires</option>
              <option value="Groupe">Autre Groupe</option>
            </select>
          );
        },
      },
      {
        Header: 'Offert',
        accessor: 'offert',
        Cell: ({ row }) => {
          const actualId = row.original._id || row.original.id;  // Utilisez l'_id ou id, selon ce qui est disponible
          return (
            <div className="center-content">
              <input
                className="center-content"
                type="checkbox"
                checked={row.original.offert || false}
                onChange={() => handleOfferChange(actualId)}
              />
            </div>
          );
        },
      },
      {
        Header: 'Action',
        Cell: ({ row }) => {
          const actualId = row.original._id || row.original.id;  // Utilisez l'_id ou id, selon ce qui est disponible
          return (
            <div>
              <button onClick={() => handleDelete(actualId)}>
                <i className="fa fa-trash"></i> Supprimer
              </button>
            </div>
          );
        },
      },
      {
        Header: 'Sélection',
        accessor: 'isSelected',
        Cell: ({ row }) => {
          const actualId = row.original._id || row.original.id;  // Utilisez l'_id ou id, selon ce qui est disponible
          return (
            <div className="center-content">
              <input
                className="center-content"
                type="checkbox"
                checked={row.original.isSelected || false}
                onChange={() => handleCheckboxClick(actualId)}
              />
            </div>
          );
        },
      },
      {
        Header: 'Nom du groupe',
        accessor: 'subGroupID.custGroupName',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy }
  } = useTable({
    columns,
    data: Array.isArray(data) ? data : [],
    rowCount,
    initialState: { sortBy: savedSort }
  },
    useSortBy);

  useEffect(() => {
    setSavedSort(sortBy);
  }, [sortBy]);

  if (!Array.isArray(data)) {
    return <div>Les données ne sont pas un tableau valide.</div>;
  }

  return (
    <div className="table-and-button-container">
      <div className={`table-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <table {...getTableProps()} style={{ width: '100%', margin: '20px 0' }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon icon={faSortDown} style={{color: '#ffffff', marginLeft: '4px'}} />
                          : <FontAwesomeIcon icon={faSortUp} style={{color: '#ffffff', marginLeft: '4px'}} />
                        : <FontAwesomeIcon icon={faSort} style={{color: '#ffffff', marginLeft: '4px'}} />}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}
                      style={{
                        backgroundColor: row.original.subGroupID ? row.original.subGroupID.color : 'transparent',
                        color: row.original.subGroupID ? row.original.subGroupID.textColor : 'inherit'
                      }}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div className="expandButton">
        <button onClick={toggleExpand}>
          <i className={`fas fa-chevron-${isExpanded ? 'Réduire' : 'Étendre'}`}></i>
        </button>
      </div> */}
      </div >
      {/* <div className="expandButton" onClick={toggleExpand}>
        <i className={`fas fa-chevron-${isExpanded ? 'Réduire' : 'Étendre'}`}></i>
      </div> */}
      {rowCount >= 20 && (
        <div className="expandButton" onClick={toggleExpand}>
          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
        </div>
      )}
    </div>
  );
}

export default GenericTableComponent;