import React from "react";
import styles from '../Privacy.module.css';
import { useHistory } from 'react-router-dom';

const Privacy = () => {

    const history = useHistory();

    const navigate = (path) => {
        history.push(path);
    };

    return (

        <div className={styles.container}>
            <div className={styles.subContainer}>
                <h1 className={styles.title}>
                    Politique de Confidentialité de Your Guests
                </h1>
                <p className={styles.dateUpdate}>
                    Dernière mise à jour : 03/05/2024
                </p>

                Chez <a className={styles.hrefColor} onClick={() => navigate("/")}>Your-Guests</a>,  l’une de nos principales priorités est la confidentialité de nos utilisateurs. Cette Politique de confidentialité documente les types d’informations que nous recueillons et enregistrons, ainsi que les manières dont nous les utilisons.

                Si vous avez des questions supplémentaires ou besoin de plus d'informations sur notre Politique de confidentialité, n'hésitez pas à nous contacter.

                Collecte et utilisation des informations

                Nous recueillons plusieurs types d’informations à des fins diverses afin de fournir et d’améliorer notre service à vous. Les types d’informations recueillies comprennent :

                <ul className={styles.textUl}>
                    <li className={styles.textLiP}>
                        <p className={styles.boldtext}>Informations personnelles :</p> Lors de l’utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent inclure, mais sans s'y limiter, votre nom, numéro de téléphone, adresse postale, adresse électronique.
                    </li>
                    <li className={styles.textLiP}>
                        <p className={styles.boldtext}>Données d’utilisation :</p> Nous pouvons également collecter des informations sur l’accès et l’utilisation du service. Ces données d’utilisation peuvent inclure des informations telles que l'adresse de protocole Internet de votre ordinateur (ex. adresse IP), le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, des identifiants uniques de dispositifs et d'autres données de diagnostic.
                    </li>
                </ul>
                <h2 className={styles.subTitle}>
                    Partage des données
                </h2>

                Vos informations, y compris les données personnelles, peuvent être transférées à — et maintenues sur — des ordinateurs situés en dehors de votre état, province, pays ou autre juridiction gouvernementale où les lois de protection des données peuvent différer de celles de votre juridiction.

                Votre consentement à cette Politique de confidentialité suivi de votre soumission de telles informations représente votre accord à ce transfert.

                Your Guests prendra toutes les mesures raisonnablement nécessaires pour s'assurer que vos données sont traitées en toute sécurité et conformément à cette Politique de confidentialité.

                <h2 className={styles.subTitle}>
                    Sécurité des données
                </h2>

                La sécurité de vos données est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sûre à 100%. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur sécurité absolue.

                <h2 className={styles.subTitle}>
                    Vos droits
                </h2>

                Your Guests vise à prendre des mesures raisonnables pour vous permettre de corriger, amender, supprimer ou limiter l'utilisation de vos données personnelles.

                Si vous souhaitez être informé des données personnelles que nous détenons à votre sujet et si vous voulez qu'elles soient retirées de nos systèmes, veuillez nous contacter.

                Dans certaines circonstances, vous avez les droits suivants :

                <ul className={styles.textUl}>
                    <li className={styles.textLiP}>Le droit d'accéder, de mettre à jour ou de supprimer les informations que nous avons sur vous.</li>
                    <li className={styles.textLiP}>Le droit de rectification.</li>
                    <li className={styles.textLiP}>Le droit à l'oubli.</li>
                    <li className={styles.textLiP}>Le droit de restreindre.</li>
                    <li className={styles.textLiP}>Le droit à la portabilité des données.</li>
                    <li className={styles.textLiP}>Le droit de retirer votre consentement.</li>
                </ul>

                <h2 className={styles.subTitle}>
                    Contactez-nous
                </h2>
                Pour toute question ou commentaire concernant notre Politique de confidentialité, veuillez nous contacter à via <a className={styles.hrefColor} onClick={() => navigate("/contact")}>notre page de contact</a>.
            </div>
        </div>
    );
};

export default Privacy;