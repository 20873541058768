import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import styles from '../BillIcon.module.css'; // Import the CSS file

function BillIcon() {
  return (
    <FontAwesomeIcon icon={faCoins} size="4x" className={styles.faBounce} style={{ color: '#FFD700' }}/>
  );
}

export default BillIcon;
