import React, { createContext, useState, useContext } from 'react';
import { getConfigs } from '../api/api';

const RateConfigContext = createContext({
    rate: '', 
    setRate: () => { },
    ruleType: 'perNight', 
    setRuleType: () => { },
    specialFamilyRateAmount: 0,
    setSpecialFamilyRateAmount: () => { },
    specialGroupRateAmount: 0,
    setSpecialGroupRateAmount: () => { },
    specialChildRateAmount: 0,
    setSpecialChildRateAmount: () => { },
    familyRatesValue: 'noOffer',
    setFamilyRatesValue: () => { },
    childrenRatesValue: 'noOffer',
    setChildrenRatesValue: () => { },
    headCoupleValue: 'head',
    setHeadCoupleValue: () => { },
});

export const useRateConfig = () => useContext(RateConfigContext);

export const RateConfigProvider = ({ children }) => {
    const [rate, setRate] = useState(''); // Valeur initiale pour le contexte
    const [ruleType, setRuleType] = useState('perNight');
    const [specialFamilyRateAmount, setSpecialFamilyRateAmount] = useState(0);
    const [specialGroupRateAmount, setSpecialGroupRateAmount] = useState(0);
    const [specialChildRateAmount, setSpecialChildRateAmount] = useState(0);
    const [familyRatesValue, setFamilyRatesValue] = useState("noOffer");
    const [childrenRatesValue, setChildrenRatesValue] = useState("noOffer");
    const [groupRatesValue, setGroupRatesValue] = useState("noOffer");
    const [headCoupleValue, setHeadCoupleValue] = useState("head");
    const [guestsConfigs, setGuestsConfigs] = useState([]);

    
const refreshConfigs = async (projectId) => {
    try {
        const updatedConfigs = await getConfigs(projectId);
        const paymentRules = updatedConfigs.paymentRules;
        const generalConfig = paymentRules.find(config => config.appliesTo === 'everyone');

        paymentRules.forEach(config => {
            if (config.appliesTo !== 'everyone' && !config.ruleType) {
                config.ruleType = generalConfig.ruleType; // Utiliser le ruleType de la config générale
            }
        });

        setGuestsConfigs(paymentRules);
    } catch (error) {
        console.error('Erreur lors de la mise à jour des configurations:', error);
    }
};

    const resetContext = () => {
      setRate('');
      setRuleType('perNight'); 
      setSpecialFamilyRateAmount(0);
      setSpecialGroupRateAmount(0);
      setSpecialChildRateAmount(0); 
      setFamilyRatesValue('noOffer'); 
      setChildrenRatesValue('noOffer'); 
      setGroupRatesValue('noOffer');
      setHeadCoupleValue('head');
  };

    const contextValue = {
        rate,
        setRate,
        ruleType,
        setRuleType,
        specialFamilyRateAmount,
        setSpecialFamilyRateAmount,
        specialGroupRateAmount,
        setSpecialGroupRateAmount,
        specialChildRateAmount,
        setSpecialChildRateAmount,
        familyRatesValue,
        setFamilyRatesValue,
        groupRatesValue,
        setGroupRatesValue,
        childrenRatesValue,
        setChildrenRatesValue,
        headCoupleValue,
        setHeadCoupleValue,
        refreshConfigs,
        resetContext
    };

    return (
        <RateConfigContext.Provider value={contextValue}>
            {children}
        </RateConfigContext.Provider>
    );
};