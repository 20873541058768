import React, { useRef, useState, useEffect } from 'react';
import { useRateConfig } from '../contexts/RateConfigContext';
import { useParams } from 'react-router-dom';
import SegmentedControl from './SegmentedControl';
import { getChildrenCount, getFamilyCount, getOtherGroupCount, submitConfig, getConfigs } from '../api/api';
import stylesInput from '../PriceApplicationSelector.module.css';
import stylesSection from '../SpecialRatesSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmark } from '@fortawesome/free-solid-svg-icons';

const SpecialRatesSection = () => {
    const { projectId } = useParams();
    const {
        specialFamilyRateAmount, setSpecialFamilyRateAmount,
        specialChildRateAmount, setSpecialChildRateAmount,
        specialGroupRateAmount, setSpecialGroupRateAmount,
        familyRatesValue, setFamilyRatesValue,
        childrenRatesValue, setChildrenRatesValue,
        groupRatesValue, setGroupRatesValue,
        headCoupleValue, setHeadCoupleValue,
        refreshConfigs, resetContext
    } = useRateConfig();

    /* USE STATES */
    const [childrenCount, setChildrenCount] = useState(null);
    const [familyCount, setFamilyCount] = useState(null);
    const [otherGroupCount, setOtherGroupCount] = useState(null);
    const [specialFamilyRateEnabled, setSpecialFamilyRateEnabled] = useState(false);
    const [specialGroupRateEnabled, setSpecialGroupRateEnabled] = useState(false);
    const [specialChildRateEnabled, setSpecialChildRateEnabled] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);
    const [areSaved, setAreSaved] = useState(false);
    const [areSavedFailed, setAreSavedFailed] = useState(false);
    const [specialChildRateOption, setSpecialChildRateOption] = useState("");
    const [rateType, setRateType] = useState('');

    /* USE REF */
    const familyRatesRef = useRef(null);
    const familySegmentSpecialRatesRef = useRef(null);
    const familySegmentNoOfferRef = useRef(null);
    const childrenRatesRef = useRef(null);
    const childrenSegmentSpecialRatesRef = useRef(null);
    const childrenSegmentNoOfferRef = useRef(null);
    const childrenSegmentOfferStayRef = useRef(null);
    const groupRatesRef = useRef(null);
    const groupSegmentSpecialRatesRef = useRef(null);
    const groupSegmentNoOfferRef = useRef(null);
    const headCoupleRatesRef = useRef(null);
    const headCoupleSegmentHeadRef = useRef(null);
    const headCoupleSegmentCoupleRef = useRef(null);

    const handleFamilyRateChange = (e) => {
        setSpecialFamilyRateAmount(e.target.value);
    };

    const handleGroupRateChange = (e) => {
        setSpecialGroupRateAmount(e.target.value);
        setSpecialGroupRateEnabled("specialRate");
    };

    const handleChildRateChange = (e) => {
        setSpecialChildRateAmount(e.target.value);
        setSpecialChildRateEnabled("specialRate")
    };

        const setConfig = async () => {
        try {
            const updatedConfigs = await getConfigs(projectId);
    
            // Accéder au tableau paymentRules pour trouver la configuration générale
            const generalConfig = updatedConfigs.paymentRules.find(config => config.appliesTo === 'everyone');
            const generalRateType = generalConfig ? generalConfig.ruleType : 'perNight';
            
            const dataToSend = {
                projectId: projectId,
                rateType: generalRateType,
                specialFamilyRate: familyRatesValue === "specialRate",
                specialFamilyRateAmount: specialFamilyRateEnabled ? specialFamilyRateAmount : 0,
                specialChildRate: childrenRatesValue === "specialRate",
                specialChildRateAmount: childrenRatesValue === "specialRate" ? specialChildRateAmount : 0,
                specialGroupRate: groupRatesValue === "specialRate",
                specialGroupRateAmount: specialGroupRateEnabled ? specialGroupRateAmount : 0,
                appliesBy: headCoupleValue, // "head" ou "couple"
                specialChildRateOption: specialChildRateOption,
            };
     
            const response = await submitConfig(dataToSend);
              
            if (response.success) {
                setSuccessMessage("Configuration correctement enregistrée !");
                setTimeout(() => {
                    setSuccessMessage(null);
                }, 3000);
                setAreSaved(true);
    
                // Appeler refreshConfigs pour mettre à jour les données
                await refreshConfigs(projectId);
                resetContext();
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la configuration :', error);
            setError("Une erreur s'est produite lors de la mise à jour de la configuration.");
            setTimeout(() => {
                setError(null);
            }, 3000);
            setAreSavedFailed(true);
        }
    };

    useEffect(() => {
        const isSpecialRate = familyRatesValue === "specialRate";
        setSpecialFamilyRateEnabled(isSpecialRate);
        if (isSpecialRate) {
            setChildrenRatesValue("noOffer");
            setSpecialChildRateOption ("noOffer"); // Ou toute autre logique nécessaire
        }
    }, [familyRatesValue]);

    useEffect(() => {
        async function fetchData() {
            try {
                const childrenResponse = await getChildrenCount(projectId);
                const familyResponse = await getFamilyCount(projectId);
                const otherGroupResponse = await getOtherGroupCount(projectId);
                setChildrenCount(childrenResponse.data.count);
                setFamilyCount(familyResponse.data.count);
                setOtherGroupCount(otherGroupResponse.data.count);
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
                // Gérer l'erreur selon les besoins
            }
        }
        fetchData();
    }, [projectId]);

    return (
        <section id="specialRates" >
            <div className={stylesSection.container}>
                {familyCount > 0 && (
                    <div className={stylesSection.controlItem}>
                        <h2>
                            Fixer un tarif spécial familles ?
                        </h2>
                        <SegmentedControl
                            name="FamilyRates"
                            label="Fixer un tarif spécial familles ?"
                            callback={(val) => {
                                setFamilyRatesValue(val);
                                if (val === "noOffer") {
                                    setSpecialFamilyRateAmount(0);
                                }
                            }}
                            controlRef={familyRatesRef}
                            defaultIndex={1}
                            segments={[
                                {
                                    label: "Oui",
                                    value: "specialRate",
                                    ref: familySegmentSpecialRatesRef,
                                },
                                {
                                    label: "Non",
                                    value: "noOffer",
                                    ref: familySegmentNoOfferRef,
                                },
                            ]}
                        />
                        {familyRatesValue === "specialRate" && (
                            <div className={stylesInput.inputWithEuro}>
                                <input
                                    className={stylesInput.inputWithEuroInput}
                                    type="number"
                                    value={specialFamilyRateAmount}
                                    onChange={handleFamilyRateChange}
                                    placeholder="Tarif"
                                    min={0}
                                    step={0.01}
                                />
                            </div>
                        )}
                        <p className={stylesSection.selectedItem}>
                            {familyCount > 0 && (
                                `Il y a ${familyCount} famille${familyCount > 1 ? 's' : ''} parmi vos invités.`
                            )}
                        </p>
                    </div>
                )}
                {childrenCount > 0 && (
                    <div className={stylesSection.controlItem}>
                        <h2>
                            Fixer un tarif spécial enfants ?
                            {specialFamilyRateEnabled && (
                                <span className={`fa fa-info-circle ml-3 ${stylesSection.IconInfo}`} title="Le tarif spécial enfants est désactivé car un tarif spécial famille est appliqué."> </span>
                            )}
                        </h2>
                        <SegmentedControl
                            name="ChildrenRates"
                            key={specialFamilyRateEnabled ? "disabled" : "enabled"}
                            callback={(val) => {
                                setChildrenRatesValue(val);
                                setSpecialChildRateOption(val); // Met à jour l'option pour les tarifs enfants
                            }}
                            controlRef={childrenRatesRef}
                            defaultIndex={1}
                            disabled={specialFamilyRateEnabled}
                            className={specialFamilyRateEnabled ? stylesSection.segmentedControlDisabled : ''}
                            segments={[
                                {
                                    label: "Oui",
                                    value: "specialRate",
                                    ref: childrenSegmentSpecialRatesRef,
                                },
                                {
                                    label: "Non",
                                    value: "noOffer",
                                    ref: childrenSegmentNoOfferRef,
                                },
                                {
                                    label: "Offrir",
                                    value: "offerStay",
                                    ref: childrenSegmentOfferStayRef,
                                },
                            ]}
                        />
                        {childrenRatesValue === "specialRate" && (
                            <div className={stylesInput.inputWithEuro}>
                                <input
                                    className={stylesInput.inputWithEuroInput}
                                    type="number"
                                    value={specialChildRateAmount}
                                    onChange={handleChildRateChange}
                                    placeholder="Tarif"
                                    min={0}
                                    step={0.01}
                                />
                            </div>
                        )}
                        <p className={stylesSection.selectedItem}>
                            {childrenCount > 0 && (
                                `Il y a ${childrenCount} enfant${childrenCount > 1 ? 's' : ''} parmi vos invités.`
                            )}
                        </p>
                    </div>
                )}
                {otherGroupCount > 0 && (
                <div className={stylesSection.controlItem}>
                    <h2>
                        Fixer un tarif spécial groupes ?
                    </h2>
                    <SegmentedControl
                            name="GroupRates"
                            label="Fixer un tarif spécial groupes ?"
                            callback={(val) => {
                                setGroupRatesValue(val);
                                if (val === "noOffer") {
                                    setSpecialGroupRateAmount(0);
                                }
                            }}
                            controlRef={groupRatesRef}
                            defaultIndex={1}
                            segments={[
                                {
                                    label: "Oui",
                                    value: "specialRate",
                                    ref: groupSegmentSpecialRatesRef,
                                },
                                {
                                    label: "Non",
                                    value: "noOffer",
                                    ref: groupSegmentNoOfferRef,
                                },
                            ]}
                        />
                        {groupRatesValue === "specialRate" && (
                            <div className={stylesInput.inputWithEuro}>
                                <input
                                    className={stylesInput.inputWithEuroInput}
                                    type="number"
                                    value={specialGroupRateAmount}
                                    onChange={handleGroupRateChange}
                                    placeholder="Tarif"
                                    min={0}
                                    step={0.01}
                                />
                            </div>
                        )}
                        <p className={stylesSection.selectedItem}>
                            {otherGroupCount > 0 && (
                                `Il y a ${otherGroupCount} groupe${otherGroupCount > 1 ? 's' : ''} non couples ou familles parmi vos invités.`
                            )}
                        </p>
                </div>
                )}
                <div className={stylesSection.controlItem}>
                    <h2>
                        Appliquer le tarif par personne ou par couple
                    </h2>
                    <SegmentedControl
                        name="HeadCouple"
                        callback={(val) => setHeadCoupleValue(val)}
                        controlRef={headCoupleRatesRef}
                        segments={[
                            {
                                label: "Par personne",
                                value: "head",
                                ref: headCoupleSegmentHeadRef,
                            },
                            {
                                label: "Par couple",
                                value: "couple",
                                ref: headCoupleSegmentCoupleRef,
                            },
                        ]}
                    />
                </div>                
            </div>
            <div className={stylesSection.buttonContainer}>
                <button
                    className={stylesSection.buttonConfirmer}
                    onClick={setConfig}
                >
                    Enregistrer la configuration
                </button>
                {areSaved && <FontAwesomeIcon icon={faCheckCircle} size="xl" className="ml-2 text-green-500" />}
                {areSavedFailed && <FontAwesomeIcon icon={faXmark} size="xl" className="ml-2 text-red-500" />}
            </div>
            {error && <p className="text-red-500 mt-10">{error}</p>}
            {successMessage && <p className="text-green-500 mt-10">{successMessage}</p>}
        </section>
    );
};

export default SpecialRatesSection;