import React, { useState } from 'react';
import styles from '../PartnerRegistration.module.css'

// Regular expression for email validation
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailValidation = ({ onValidEmail }) => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(null); // Tracks if email is valid or invalid

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    
    // Validate email using regex
    const isEmailValid = EMAIL_REGEX.test(value);
    setIsValid(isEmailValid);
    
    if (isEmailValid) {
      onValidEmail(value); // Trigger callback if email is valid
    }
  };

  return (
    <div className={styles.formRow}>
      <label>Email de contact:</label>
      <input 
        type="email" 
        value={email}
        onChange={handleInputChange}
        className={styles.modalInput}
        style={{
          borderColor: isValid === null ? '#ccc' : isValid ? 'green' : 'red'
        }}
      />
      {isValid === false && <span style={{ color: 'red' }}> Email invalide</span>}
      {isValid === true && <span style={{ color: 'green' }}> Email valide</span>}
    </div>
  );
};

export default EmailValidation;
