import React, { useState, useRef, useEffect } from 'react';
import { setDate } from '../api/api';
import { useParams } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from "date-fns/locale/fr";
import ModalDates from './ModalDates';
import moment from 'moment';

import stylesSection from '../DateSelectorSection.module.css';
import stylesDates from '../DateSelector.module.css';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmark } from '@fortawesome/free-solid-svg-icons';

registerLocale("fr", fr);

const DateSelectorSection = ({ dates, setDates }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);
    const [areSaved, setAreSaved] = useState(false);
    const [areSavedFailed, setareSavedFailed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { projectId } = useParams();
    const endDatePickerRef = useRef(null);
    const [startDateSelected, setStartDateSelected] = useState(false);

    useEffect(() => {
        const savedDates = localStorage.getItem('bookingDates');
        if (savedDates) {
            const { startDate, endDate } = JSON.parse(savedDates);
            if (startDate && endDate) {
                setStartDate(new Date(startDate));
                setEndDate(new Date(endDate));
            }
        }
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            const datesToSave = { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
            localStorage.setItem('bookingDates', JSON.stringify(datesToSave));
        }
    }, [startDate, endDate]);

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleDefineHours = () => {
        handleModalClose();
        // Logique pour redéfinir les heures (si nécessaire)
    };

    const handleSkipHours = () => {
        handleModalClose();
        submitDates();
    };

    const handleStartDateChange = (date) => {
        setDates({ ...dates, startDate: date });
        setStartDate(date);
        // Vérifiez si l'heure n'est pas minuit pour considérer que l'heure est sélectionnée
        if (date && date.getHours() !== 0) {
            setStartDateSelected(true);
        }
    };

    const handleEndDateChange = (date) => {
        setDates({ ...dates, endDate: date });
        setEndDate(date);
        // Vérifiez si l'heure n'est pas minuit pour considérer que l'heure est sélectionnée
    };

    useEffect(() => {
        if (startDateSelected) {
            endDatePickerRef.current.setOpen(true);
            // Réinitialisez le flag pour la prochaine sélection
            setStartDateSelected(false);
        }
    }, [startDateSelected]);

    const submitDates = async () => {
        if (!startDate || !endDate) {
            setError("Veuillez sélectionner des dates valides.");
            return;
        }

        if (startDate >= endDate) {
            setError("La date de fin doit être postérieure à la date de début.");
            return;
        }

        try {
            if (moment(startDate).isValid() && moment(endDate).isValid()) {
                const startDateUtcString = moment(startDate).format();
                const endDateUtcString = moment(endDate).format();

                const data = { startDate: startDateUtcString, endDate: endDateUtcString };
                const response = await setDate(projectId, data);
        
                if (response.success) {
                    setSuccessMessage("Dates correctement enregitrées !");
                    setTimeout(() => {
                        setSuccessMessage(null);
                    }, 3000);
                    setAreSaved(true);
                }
            } else {
                console.log('Problème dans les dates');
            }
        } catch (error) {
            // console.error('Erreur lors de la mise à jour des dates:', error);
            setError("Une erreur s'est produite lors de la mise à jour des dates.");
            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);
            setareSavedFailed(true);
        }
    };

    const handleSubmit = () => {
        const hoursNotDefined = startDate.getHours() === 0 && startDate.getMinutes() === 0 && startDate.getSeconds() === 0 &&
            endDate.getHours() === 0 && endDate.getMinutes() === 0 && endDate.getSeconds() === 0;
        if (hoursNotDefined) {
            setShowModal(true);
        } else {
            submitDates();
        }
    };

    return (
        <section id="dates" className={stylesSection.dateSelectorSection}>
            <h2 className={stylesSection.dateTitle}>
                Définissez vos dates de mariage
            </h2>
            <p className={stylesSection.dateSelectorDescription}>
                Indiquez les dates de début et de fin de votre mariage. Choisissez l'heure d'arrivée et le moment où la fête doit se terminer pour vos invités.<br /> Pas de stress, c'est vous qui décidez !
            </p>
            <div className={stylesDates.dateSelectorContainer}>
                <span> Du </span>
                <DatePicker
                    locale="fr"
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Arrivée"
                    dateFormat="dd/MM/yyyy HH:mm"
                    placeholderText="Date de début"
                    className={stylesDates.dateSelectorInput}
                />
                <span> Au </span>
                <DatePicker
                    ref={endDatePickerRef}
                    locale="fr"
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Départ"
                    dateFormat="dd/MM/yyyy HH:mm"
                    placeholderText="Date de fin"
                    className={stylesDates.dateSelectorInput}
                />
            </div>
            <div className={stylesSection.buttonContainer}>
                <button
                    className={stylesSection.buttonConfirmer}
                    onClick={handleSubmit}
                >
                    Enregistrer les dates
                </button>
                {areSaved && <FontAwesomeIcon icon={faCheckCircle} size="xl" className="ml-2 text-green-500" />}
                {areSavedFailed && <FontAwesomeIcon icon={faXmark} size="xl" className="ml-2 text-red-500" />}
            </div>
            {error && <p className="text-red-500 mt-10">{error}</p>}
            {successMessage && <p className="text-green-500 mt-10">{successMessage}</p>}

            <ModalDates
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onLeftButtonClick={handleDefineHours}
                onRightButtonClick={handleSkipHours}
            />
        </section>
    );
};

export default DateSelectorSection;