import React, { useEffect, useRef } from "react";
import { urlFor } from "./imageUrlBuilder";
import '../ModalVenue.css';

const ModalVenue = ({ isOpen, onClose, content, onSave }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const scrollImages = (direction) => {
        const container = document.querySelector('.modal-images');
        const scrollAmount = 200; // La distance de défilement pour chaque clic

        if (direction === 'left') {
            container.scrollLeft -= scrollAmount;
        } else if (direction === 'right') {
            container.scrollLeft += scrollAmount;
        }
    }

    if (!isOpen) {
        return null;
    }

    function truncateText(text, maxLength) {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    }

    return (
        <div className="modal-container">
            <div className="modal-content">
                <div className="modal-top">
                    {content.logo && (
                        <img src={urlFor(content.logo).auto('format').width(100).url()} alt={content.alt} className="modal-logo" />
                    )}
                    <h2 className="modal-title">{content.title}</h2>
                </div>
                <div className="modal-description">
                    {truncateText(content.description, 250)}
                </div>
                <div className="modal-images-container">
                    <div className="scroll-arrow left" onClick={() => scrollImages('left')}>&lt;</div>
                    <div className="modal-images">
                        {content.gallery && content.gallery.slice(0, 5).map((gallery, index) => (
                            <img
                                key={index}
                                src={urlFor(gallery).auto('format').width(200).url()}
                                alt={`Image ${index + 1}`}
                                className="modal-image"
                            />
                        ))}
                    </div>
                    <div className="scroll-arrow right" onClick={() => scrollImages('right')}>&gt;</div>
                </div>
                <div className="modal-actions">
                    <button className="modal-button mr-20" onClick={onClose}>Revenir</button>
                    <button className="modal-button ml-20" onClick={onSave}>Confirmer</button>
                </div>
            </div>
        </div>
    );
};

export default ModalVenue;
