import { ResponsiveBar } from '@nivo/bar';
import styles from '../../../BarChart.module.css'
import { format } from 'd3-format';

const ChoiceCalculationBar = ({ data }) => {
    
const euroFormat = format(".2f");

    return (
        <div className={styles.barChartContainer}>
            <h2 className={styles.title}>Répartition de votre tarification</h2>
            <ResponsiveBar
                data={data}
                keys={['price']}
                indexBy="category"
                layout="horizontal"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                valueFormat={value => `${euroFormat(value)} €`}
                margin={{ top: 10, right: 10, bottom: 60, left: 80 }}
                colors={{ scheme: 'purple_orange' }} //nivo
                // colorBy='indexValue'
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    truncateTickAt: 0
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    truncateTickAt: 0
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ theme: 'grid.line.stroke' }}
            />
        </div>

    );
};

export default ChoiceCalculationBar;