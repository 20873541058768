import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getVenueMetrics, getVenueID, getCurrentUser, getVenueName } from '../../api/api';
import SankeyChart from './Sankey/SankeyChart';


const AssignedRoomsLogics = ({ aggregationResults }) => {
    const { projectId } = useParams();
    const [data, setData] = useState({ nodes: [], links: [] });
    const [venueMetrics, setVenueMetrics] = useState(null);
    const [venueName, setVenueName] = useState("");

    useEffect(() => {
        const fetchVenueMetrics = async () => {
            try {
                const userResponse = await getCurrentUser();
                const userId = userResponse.data._id;

                const venueDataID = await getVenueID(userId, projectId);
                const venueId = venueDataID.data.venueId;

                const venueDataName = await getVenueName(userId, projectId);
                const venueName = venueDataName.data.venueName;
                setVenueName(venueName);

                const metrics = await getVenueMetrics(projectId, venueId);
                setVenueMetrics(metrics);
            } catch (error) {
                console.error('Erreur lors de la récupération des métriques du lieu:', error);
            }
        };

        fetchVenueMetrics();
    }, [projectId]); // Dépendance sur projectId uniquement

    useEffect(() => {

        if (aggregationResults && venueMetrics) {
            let transformedData = transformDataToSankey(aggregationResults, venueMetrics[0]);

            const validatedLinks = transformedData.links.map(link => ({
                ...link,
                value: link.value != null ? link.value : 0 // Utilisez 0 si la valeur est null
            }));

            transformedData = {
                ...transformedData,
                links: validatedLinks
            };

            setData(transformedData); // Mettez à jour l'état avec les données validées et transformées
        }
    }, [aggregationResults, venueMetrics]);


    const transformDataToSankey = (aggregationResults, venueMetrics) => {
        const metrics = venueMetrics;
        const totalRoomsSuites = metrics.totalRooms + metrics.totalSuites;

        const totalBedsOccupied = aggregationResults.totalBedsOccupied;
        const totalCouchagesPerdus = aggregationResults.totalCouchagesPerdus;
        const totalCouples = aggregationResults.totalCouples;
        const totalFamilies = aggregationResults.totalFamilies;
        const totalSingles = aggregationResults.totalSingles;
        const totalGroups = aggregationResults.totalOtherGroups;
        const totalRoomsAssigned = aggregationResults.totalRoomsAssigned;
        const totalExtraBedsUsed = aggregationResults.totalExtraBedsUsed;
        const extraBedsUsedByFamilies = aggregationResults.extraBedsUsedByFamilies;
        const extraBedsUsedByCouples = aggregationResults.extraBedsUsedByCouples;
        const extraBedsUsedBySingles = aggregationResults.extraBedsUsedBySingles;
        const extraBedsUsedByGroups = aggregationResults.extraBedsUsedByOtherGroups;
        const totalGuestsNonLoges = aggregationResults.totalGuestsNonLoges;

        const newData =
        {
            nodes: [
                { id: venueName },
                { id: "Familles" },
                { id: "Couples" },
                { id: "Seuls/Célibataires" },
                { id: "Autres Groupes" },
                { id: "Chambres Attribuées" },
                { id: "Lits d'Appoint" },
                { id: "Couchages Occupés" },
                { id: "Couchages Perdus" },
                { id: "Couchages du Lieu" },
                { id: "Chambres/suites du Lieu" },
                { id: "Invité(s) non logé(s)" },
            ],
            links: [
                { source: venueName, target: "Chambres/suites du Lieu", value: totalRoomsSuites },
                { source: "Chambres/suites du Lieu", target: "Chambres Attribuées", value: totalRoomsAssigned },
                { source: "Chambres Attribuées", target: "Couchages Occupés", value: totalBedsOccupied - totalCouchagesPerdus },
                { source: "Chambres Attribuées", target: "Couchages Perdus", value: totalCouchagesPerdus },
                { source: "Chambres Attribuées", target: "Invité(s) non logé(s)", value: totalGuestsNonLoges },
                { source: "Couchages Occupés", target: "Lits d'Appoint", value: totalExtraBedsUsed },
                { source: "Couchages Occupés", target: "Familles", value: totalFamilies },
                { source: "Lits d'Appoint", target: "Familles", value: extraBedsUsedByFamilies },
                { source: "Couchages Occupés", target: "Couples", value: totalCouples },
                { source: "Lits d'Appoint", target: "Couples", value: extraBedsUsedByCouples },
                { source: "Couchages Occupés", target: "Seuls/Célibataires", value: totalSingles },
                { source: "Lits d'Appoint", target: "Seuls/Célibataires", value: extraBedsUsedBySingles },
                { source: "Couchages Occupés", target: "Autres Groupes", value: totalGroups },
                { source: "Lits d'Appoint", target: "Autres Groupes", value: extraBedsUsedByGroups },
            ]
        };
        return newData;
    };

    return (
        <div>
            {/* {venueMetrics && aggregationResults ? (
                <h3>
                    C: {venueMetrics.totalBeds }
                    A: {venueMetrics.totalExtraBeds }
                    R: {venueMetrics.totalRooms }
                    P: {aggregationResults.totalCouchagesPerdus}
                </h3>
            ) : (
                <div>Chargement des données...</div>
            )} */}
            {data.nodes.length > 0 && data.links.length > 0 ? (
                <SankeyChart data={data} />
            ) : (
                <div>Chargement des données...</div>
            )}

        </div>
    );
};

export default AssignedRoomsLogics;