import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { sendForgotPasswordEmail, resetPassword, validateResetCode } from '../api/api'; // Use centralized API instance
import { useHistory } from 'react-router-dom';
import '../Register.css'; // Use the existing Register CSS for styling

function ResetPasswordForm() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [isProfessional, setIsProfessional] = useState(false); // Toggle between professional or private
  const [codeSent, setCodeSent] = useState(false); // Track if code has been sent
  const [resetCode, setResetCode] = useState(''); // Store the reset code
  const [isCodeValid, setIsCodeValid] = useState(false); // Track if the code is valid
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  // Handle sending reset code
  const handleSendResetCode = async () => {
    try {
      await sendForgotPasswordEmail(email, isProfessional);
      setCodeSent(true);
      notification.success({ message: 'Code envoyé avec succès.', description: `Un code de réinitialisation a été envoyé à l'adresse e-mail ${email}.`, duration: 4 });
    } catch (error) {
      notification.error({ message: 'Échec de l\'envoi du code.', description: error.response?.data?.message || 'Une erreur est survenue.', duration: 4 });
    }
  };

  const handleValidateCode = async () => {
    try {
      await validateResetCode(email, resetCode); // No need to store the response if it's not used
      notification.success({ message: 'Code validé avec succès.' });
      setIsCodeValid(true);
    } catch (error) {
      notification.error({
        message: 'Échec de la validation du code.',
        description: error.response?.data?.message || 'Une erreur est survenue.',
        duration: 4,
      });
      setIsCodeValid(false); // Reset code is invalid
    }
  };


  // Handle validating reset code and updating password
  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      notification.error({ message: 'Les mots de passe ne correspondent pas.' });
      return;
    }

    try {
      await resetPassword(email, resetCode, newPassword); // Actual API call for password reset
      notification.success({ message: 'Mot de passe mis à jour avec succès.', description: 'Vous pouvez vous reconnecter', duration: 6 });
      setPasswordUpdated(true); // Set flag indicating password has been updated
    } catch (error) {
      notification.error({
        message: 'Échec de la mise à jour du mot de passe.',
        description: error.response?.data?.message || 'Une erreur est survenue.',
        duration: 4,
      });
    }
  };
  const toggleProfessional = () => {
    setIsProfessional(!isProfessional);
  };

  useEffect(() => {
    // Step 3: Redirect after password has been updated
    if (passwordUpdated) {
      history.push('/login-register');
    }
  }, [passwordUpdated, history]);

  return (
    <div className="container-form">
      <div className="form-container">
        {/* Toggle between Professional and Private */}
        <div className='pricing-toggle-container'>
          <span className='toggle-label'>Particulier</span>
          <input type="checkbox" id="toggle" className='toggle-checkbox' checked={isProfessional} onChange={toggleProfessional} />
          <label htmlFor="toggle" className='toggle-switch'></label>
          <span className='toggle-label'>Professionnel</span>
        </div>

        {/* Display Email Input or Code Input */}
        {!codeSent ? (
          <div>
            <h1 className='reset-title'>Réinitialisez votre mot de passe</h1>
            <form
              className='reset-form'
              onSubmit={(e) => { e.preventDefault(); handleSendResetCode(); }}
            >
              <label
                className='reset-label'
              >
                Email :
                <input
                  className='reset-input'
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder='Veuillez saisir votre email'
                />
              </label>
              <div className='reset-button-container'>
                <button
                  className='reset-button'
                  type="submit"
                >
                  Envoyer le code de réinitialisation
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <h1 className='reset-title'>Entrez le code de réinitialisation</h1>
            <form
              className='reset-form'
              onSubmit={(e) => {
                e.preventDefault();
                // If the code is already validated, update the password. Otherwise, validate the code.
                isCodeValid ? handleUpdatePassword() : handleValidateCode();
              }}
            >
              {!isCodeValid && (
                <>
                  <label className='reset-label'>
                    Code de réinitialisation
                    <input
                      className='reset-input'
                      type="text"
                      value={resetCode}
                      onChange={(e) => setResetCode(e.target.value)}
                      required
                    />
                  </label>
                </>
              )}

              {/* Password input fields appear only after code is validated */}
              {isCodeValid ? (
                <>
                  <label
                    className='reset-label'
                  >
                    Nouveau mot de passe :
                    <input
                      className='reset-input'
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </label>
                  <label
                    className='reset-label'
                  >
                    Confirmer le mot de passe :
                    <input
                      className='reset-input'
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </label>
                </>
              ) : null}
              <div className='reset-button-container'>
                <button
                  className='reset-button'
                  type="submit">{isCodeValid ? 'Mettre à jour le mot de passe' : 'Valider le code'}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPasswordForm;
