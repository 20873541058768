import React from 'react'

export default function Error() {
  return (
    <div>
        <h1>
        Error Page
        </h1>
    </div>
  )
}
