import React, { useState, useEffect } from 'react';
import { RateConfigProvider } from '../contexts/RateConfigContext';
import { GuestsPreBookedProvider } from '../contexts/GuestsPreBookedContext';
import { useParams } from 'react-router-dom';
import DragDropInterface from '../components/DragDropInterface';
import PaymentCalculationSelector from '../components/PaymentCalculationSelector';
import GroomRoomSelection from '../components/GroomRoomSelection';
import CustomMail from '../components/CustomMail';
import ChartsLogic from '../components/NivoCharts/ChartsLogic';
import AssignedRoomsLogics from '../components/NivoCharts/AssignedRoomsLogic'
import { fetchData, } from '../api/api'; // Assurez-vous que le chemin d'importation est correct
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ScrollToTop from '../components/ScrollToTop';
import styles from '../CustomBooking.module.css';

const CustomBooking = () => {
    const [domainGuests, setDomainGuests] = useState([]);
    const [hotelGuests, setHotelGuests] = useState([]);
    const [allGroups, setAllGroups] = useState({});
    const [activeSection, setActiveSection] = useState(null);
    const [aggregationResults, setAggregationResults] = useState(null);
    const { projectId } = useParams();


    function toggleDescription(sectionId) {
        const openDescriptions = document.querySelectorAll(`.${styles.sidebarDescriptionOpen}`);
        openDescriptions.forEach(desc => {
            if (desc.id !== `description-${sectionId}`) {
                desc.classList.remove(styles.sidebarDescriptionOpen);
            }
        });

        const description = document.getElementById(`description-${sectionId}`);
        if (description) {
            description.classList.toggle(styles.sidebarDescriptionOpen);
        }
    }

    const changeSection = (section) => {
        setActiveSection(section);
        toggleDescription(section);
    };

    useEffect(() => {
        const fetchGuests = async () => {
            try {
                const response = await fetchData(projectId);
                if (response && response.data) {
                    const domainGuestsTemp = [];
                    const hotelGuestsTemp = [];
                    const allGroupsTemp = {};

                    response.data.forEach(guest => {
                        const groupId = guest.subGroupID.id;
                        if (!allGroupsTemp[groupId]) {
                            allGroupsTemp[groupId] = { guests: [], custGroupName: guest.subGroupID.custGroupName };
                        }
                        allGroupsTemp[groupId].guests.push(guest);

                        if (guest.subGroupID.sleepInVenue) {
                            domainGuestsTemp.push(guest);
                        } else if (guest.subGroupID.sleepInBnB) {
                            hotelGuestsTemp.push(guest);
                        }
                    });

                    const initialAllGroups = {};
                    response.data.forEach(guest => {
                        const groupId = guest.subGroupID.id;
                        if (!initialAllGroups[groupId]) {
                            initialAllGroups[groupId] = { guests: [], custGroupName: guest.subGroupID.custGroupName };
                        }
                        initialAllGroups[groupId].guests.push(guest);
                    });

                    setDomainGuests(domainGuestsTemp);
                    setHotelGuests(hotelGuestsTemp);
                    setAllGroups(initialAllGroups);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des invités:', error);
            }
        };

        fetchGuests();
    }, [projectId]);

    // useEffect(() => {
    //     console.log("aggregationResults updated:", aggregationResults);
    //   }, [aggregationResults]);

    return (
        <RateConfigProvider>
            <div className={styles.custContainer}>
                <div class={styles.sidebarCustBook}>
                    <h1 className={styles.h1Header}>Personnaliser votre réservation</h1>

                    <div className={styles.sidebarSection}>
                        <h2
                            className={styles.sidebarSectionH2}
                            onClick={() => changeSection('dragDropInterface')}
                        >
                            Répartir les invités
                        </h2>
                        <div className={styles.sidebarDescription} id="description-dragDropInterface">
                            <p>
                                Vos invités ont été regroupés par les groupes que vous avez définis à l'étape précédente (Famille, Couple/Partenaire, Célibataire, Autres Groupes) .<br />
                                Cliquez puis glissez-déposez chaque groupe dans l'une des deux zones de droite selon la répartition que vous souhaitez effectuer.
                            </p>
                        </div>

                        <h2
                            className={styles.sidebarSectionH2}
                            onClick={() => changeSection('PaymentCalculationSelector')}
                        >
                            Configurer les dates et le prix
                        </h2>
                        <div className={styles.sidebarDescription} id="description-PaymentCalculationSelector">
                            <p>
                                Afin de calculer correctement les nuités, sélectionnez les dates de début et de fin de la célébration de votre mariage. <br />
                                Fixez un prix, puis configurez les options afin de créer des réservations sur mesures.
                            </p>
                        </div>

                        <h2
                            className={styles.sidebarSectionH2}
                            onClick={() => changeSection('GroomRoomSelection')}
                        >
                            Choisir votre chambre
                        </h2>
                        <div className={styles.sidebarDescription} id="description-GroomRoomSelection">
                            <p>
                                Si vous prévoyez vous aussi de dormir sur le même lieu que vos invités, il vous faut nécessairement choisir en premier la chambre qui sera la vôtre. <br />
                                Le faire dès à présent vous permet d'éviter qu'un de vos invités puisse la réserver à votre place.
                                Vous pouvez également choisir les invités le même bâtiment ou les chambres autour de la vôtre.
                            </p>
                        </div>

                        <h2
                            className={styles.sidebarSectionH2}
                            onClick={() => changeSection('CustomMail')}
                        >
                            Rédiger le mail d'invitation
                        </h2>
                        <div className={styles.sidebarDescription} id="description-CustomMail">
                            <p>
                                Informez vos invités que les réservations sont ouvertes en leur envoyant un email. Vous pouvez personnaliser votre message ! <br />
                                Vous pouvez aussi laisser le message par défault si vous le préférez.
                            </p>
                        </div>
                    </div>

                </div>
                <div className={styles.divider}></div>
                <div className={styles.mainContent}>
                    <div className={styles.upperPart}>
                        {!activeSection && (
                            <div className={styles.introText}>
                                <h2 className={styles.titleIntroText}>Bienvenue sur la page de personnalisation des réservations !</h2>
                                <p className={styles.introP}>Cette page est consacrée à la personnalisation de votre expérience de réservation, depuis la répartition de vos invités jusqu'à l'élaboration des e-mails d'invitation. Veuillez sélectionner une section pour commencer à personnaliser.</p>
                            </div>
                        )}
                        {activeSection === 'dragDropInterface' && (
                            <dragDropInterface className={styles.bookingSection}>
                                <DndProvider backend={HTML5Backend}>
                                    <DragDropInterface
                                        domainGuests={domainGuests}
                                        hotelGuests={hotelGuests}
                                        allGroups={allGroups}
                                        setDomainGuests={setDomainGuests}
                                        setHotelGuests={setHotelGuests}
                                        setAllGroups={setAllGroups}
                                        setAggregationResults={setAggregationResults}
                                    />
                                </DndProvider>
                            </dragDropInterface>
                        )}
                        {activeSection === 'PaymentCalculationSelector' && (
                            <PaymentCalculationSelector                
                                className={styles.bookingSection}
                            />
                        )}
                        {activeSection === 'GroomRoomSelection' && (
                            <GuestsPreBookedProvider>
                                <GroomRoomSelection
                                    className={styles.bookingSection}
                                />
                            </GuestsPreBookedProvider>
                        )}
                        {activeSection === 'CustomMail' && (
                            <CustomMail
                                className={styles.bookingSection}
                            />
                        )}
                        <div className={styles.horizontalDivider}></div>
                    </div>
                    <div>
                        <div>
                            {activeSection === 'dragDropInterface' ? (
                                <AssignedRoomsLogics aggregationResults={aggregationResults} />
                            ) : (
                                <ChartsLogic />
                            )}
                        </div>
                    </div>

                    {/* <div className={styles.backgroundImage}>
                        {/* {lastSectionReached && (
                        <BsArrowRightCircle className={styles.rightArrowIcon} />
        
                        <p className={styles.pText}>J'ai terminé !</p>
                    </div> */}

                </div>

                <ScrollToTop />
            </div>
        </RateConfigProvider>
    );
}

export default CustomBooking;

