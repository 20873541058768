import React, { useState, useEffect }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { BsArrowRight } from "react-icons/bs";
import styles from '../PricingTable.module.css'; // Import your custom CSS for styling

const PricingTable = ({ onSelectPlan }) => {
    const [isAnnual, setIsAnnual] = useState(true);
    const [isMobile, setIsMobile] = useState(false);  

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Check screen size on initial render
        handleResize();

        // Add event listener to track resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const tiers = [
        {
            name: 'Formule Essentielle',
            price: isAnnual ? '1900€ / an HT' : '158,34€ / mois HT',
            discount: isAnnual ? ' (-25%)' : '',
            features: {
                discount: '10% sur les commissions',
                analytics: 'Analyses de base',
                modifications: '',
                marketing: '',
                visibility: ''
            },
            support: {
                support: 'Assitance aux clients du lieu',
                session: '',
                contact: ''
            },
            event: {
                eventPromo: '1 promotion d\'événement par an',
                fairExposure: '',
                vipEvents: '',
            }
        },
        {
            name: 'Formule Avancée',
            price: isAnnual ? '3000€ / an HT' : '250€ / mois HT',
            discount: isAnnual ? ' (-23%)' : '',
            features: {
                discount: '10% sur les commissions',
                analytics: 'Analyses de base',
                modifications: '5 modifications du lieu par an',
                marketing: '',
                visibility: ''
            },
            support: {
                support: 'Assitance prioritaire aux clients du lieu',
                session: '',
                contact: ''
            },
            event: {
                eventPromo: '3 promotions d\'événement par an',
                fairExposure: 'Exposition au salon du mariage',
                vipEvents: '',
            }
        },
        {
            name: 'Formule Premium',
            price: isAnnual ? '5000€ / an HT' : '416,67€ / mois HT',
            discount: isAnnual ? ' (-21%)' : '',
            features: {
                discount: '10% sur les commissions',
                modifications: 'Illimité',
                analytics: 'Analyses de base',
                marketing: 'Campagne marketing personnalisée',
                visibility: 'Apparaît en premier dans la liste des partenaires'
            },
            support: {
                support: 'Assitance prioritaire aux clients du lieu',
                session: 'Session administrateur (Sanity)',
                contact: 'Contact de support dédié'
            },
            event: {
                eventPromo: '5 promotions d\'événement par an',
                fairExposure: 'Exposition au salon du mariage',
                vipEvents: 'Invitations VIP aux événements'
            }
        }
    ];

    const togglePricing = () => {
        setIsAnnual(!isAnnual);
    };

    const handlePlanSelection = (plan) => {
        onSelectPlan(plan, isAnnual); // Notify parent of plan selection
    };

    return (
        <div className={styles.pricingTableContainer}>

            {!isMobile && (
            <div className={styles.pricingTable}>
                {/* Features Column */}
                <div className={styles.featuresColumn}>
                    <div className={styles.pricingToggleContainer}>
                        <span className={styles.toggleLabel}>Mensuel</span>
                        <input type="checkbox" id="toggle" className={styles.toggleCheckbox} checked={isAnnual} onChange={togglePricing}  />
                        <label htmlFor="toggle" className={styles.toggleSwitch}></label>
                        <span className={styles.toggleLabel}>Annuel</span>
                    </div>
                    <div className={styles.featureHeader}>Features</div>
                    <div className={styles.feature}>Réduction sur commissions</div>
                    <div className={styles.feature}>Modifications du lieu</div>
                    <div className={styles.feature}>Analyses de base</div>
                    <div className={styles.feature}>Campagne marketing</div>
                    <div className={styles.feature}>Visibilité</div>

                    <div className={styles.featureHeader}>Évènements</div>
                    <div className={styles.feature}>Promotion d'événement</div>
                    <div className={styles.feature}>Exposition au salon du mariage</div>
                    <div className={styles.feature}>Invitations VIP</div>

                    <div className={styles.featureHeader}>Support</div>
                    <div className={styles.feature}>Support client</div>
                    <div className={styles.feature}>Compte administrateur</div>
                    <div className={styles.feature}>Contact</div>
                </div>

                {/* Tiers Columns */}
                {tiers.map((tier, index) => (
                    <div className={`${styles.tierColumn} ${tier.name === 'Formule Avancée' ? styles.tierColumnFormuleAvancée : ''}`} key={index}>
                        <div className={styles.tierHeader}>
                            <h2 className={`$styles.tierTitle} ${tier.name === 'Formule Avancée' ? styles.tierTitleRecommended :''}`}>{tier.name}</h2>
                            <div className={`${styles.tierPriceWrapper} ${tier.name === 'Formule Avancée' ? styles.avancéePrice : ''}`}>
                                <span className={styles.tierPrice} style={{ color: tier.name === 'Formule Avancée' ? '#ffffff' : '#1d2856' }}>
                                    {tier.price.split(' ')[0]}
                                </span>
                                <span className={styles.tierPriceSmall} style={{ color: 'rgb(148 163 184)' }}>
                                    {tier.price.split(' ').slice(1).join(' ')}
                                </span>
                                <span className={styles.discountLabel}>
                                    {tier.discount}
                                </span>
                            </div>
                            <div className={styles.selectPlanButtonContainer}>
                            <button 
                                className={styles.selectPlanButton}
                                onClick={() => handlePlanSelection(tier)}
                            >
                                Choisir ce plan
                                <span className={styles.arrowContainer}>
                                    <BsArrowRight className={styles.arrow} />
                                </span>
                            </button>
                            </div>
                            {tier.name === 'Formule Avancée' && <div className={styles.recommendedTag}>Recommandé</div>}
                        </div>                       
                        {/* Features List */}
                        <div className={styles.featuresList}>
                            {/* Features Section */}
                            <div className={`${styles.featureGroup}`}>
                                {Object.keys(tier.features).map((featureKey, featureIndex) => (
                                    <div className={styles.featureItem} key={featureIndex}>
                                        <span className={styles.featureContent}>
                                            {tier.features[featureKey] ? <FontAwesomeIcon icon={faCheck} size="sm"  style={{ color: '#00916f' }} /> : ''}
                                            {tier.features[featureKey] || '-'}
                                        </span>
                                    </div>
                                ))}
                            </div>

                            {/* Event Section */}
                            <div className={`${styles.featureGroup} ${styles.eventContainer}`}>
                                {Object.keys(tier.event).map((eventKey, eventIndex) => (
                                    <div className={`${styles.featureItem} ${styles.event}`} key={eventIndex}>
                                        <span className={styles.featureContent}>
                                            {tier.event[eventKey] ? <FontAwesomeIcon icon={faCheck} size="sm"  style={{ color: '#00916f' }} /> : ''}
                                            {tier.event[eventKey] || '-'}
                                        </span>
                                    </div>
                                ))}
                            </div>

                            {/* Support Section */}
                            <div className={`${styles.featureGroup} ${styles.supportContainer}`}>
                                {Object.keys(tier.support).map((supportKey, supportIndex) => (
                                    <div className={`${styles.featureItem} ${styles.support}`} key={supportIndex}>
                                        <span className={styles.featureContent}>
                                            {tier.support[supportKey] ? <FontAwesomeIcon icon={faCheck} size="sm"  style={{ color: '#00916f' }} /> : ''}
                                            {tier.support[supportKey] || '-'}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            )}
            {isMobile && (
                <div className={styles.pricingTable}>
                    {tiers.map((tier, index) => (
                        <div className={`${styles.tierColumn} ${tier.name === 'Formule Avancée' ? styles.tierColumnFormuleAvancée : ''}`} key={index}>
                            {/* Mobile View Header and Price */}
                            <div className={styles.tierHeader}>
                            <h2 className={`$styles.tierTitle} ${tier.name === 'Formule Avancée' ? styles.tierTitleRecommended :''}`}>{tier.name}</h2>
                                <div className={`${styles.tierPriceWrapper} ${tier.name === 'Formule Avancée' ? styles.avancéePrice : ''}`}>
                                    <span className={styles.tierPrice} style={{ color: tier.name === 'Formule Avancée' ? '#ffffff' : '#1d2856' }}>
                                        {tier.price.split(' ')[0]}
                                    </span>
                                    <span className={styles.tierPriceSmall} style={{ color: 'rgb(148 163 184)' }}>
                                        {tier.price.split(' ').slice(1).join(' ')}
                                    </span>
                                    <span className={styles.discountLabel}>
                                        {tier.discount}
                                    </span>
                                </div>
                                {tier.name === 'Formule Avancée' && <div className={styles.recommendedTag}>Recommandé</div>}
                            </div>
                            {/* Mobile Features List */}
                            <div className={`${styles.featureHeader} ${tier.name === 'Formule Avancée' ? styles.featureHeaderRecommended :''}`}>Features</div>
                            <div className={styles.featureGroup}>
                                {Object.keys(tier.features).map((featureKey, featureIndex) => (
                                    <div className={styles.featureItem} key={featureIndex}>
                                        <span className={styles.featureContent}>
                                            {tier.features[featureKey] ? <FontAwesomeIcon icon={faCheck} size="sm"  style={{ color: '#00916f' }} /> : ''}
                                            {tier.features[featureKey] || '-'}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className={`${styles.featureHeader} ${tier.name === 'Formule Avancée' ? styles.featureHeaderRecommended :''}`}>Évènements</div>
                            <div className={styles.featureGroup}>
                                {Object.keys(tier.event).map((eventKey, eventIndex) => (
                                    <div className={styles.featureItem} key={eventIndex}>
                                        <span className={styles.featureContent}>
                                            {tier.event[eventKey] ? <FontAwesomeIcon icon={faCheck} size="sm"  style={{ color: '#00916f' }} /> : ''}
                                            {tier.event[eventKey] || '-'}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className={`${styles.featureHeader} ${tier.name === 'Formule Avancée' ? styles.featureHeaderRecommended :''}`}>Support</div>
                            <div className={styles.featureGroup}>
                                {Object.keys(tier.support).map((supportKey, supportIndex) => (
                                    <div className={styles.featureItem} key={supportIndex}>
                                        <span className={styles.featureContent}>
                                            {tier.support[supportKey] ? <FontAwesomeIcon icon={faCheck} size="sm"  style={{ color: '#00916f' }} /> : ''}
                                            {tier.support[supportKey] || '-'}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PricingTable;
