// export default MyCookieBanner;
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomizeBanner from '../components/CustomizeBanner';

const CookieConsentBanner = ({ handleCloseBanner }) => {
  const [showBanner, setShowBanner] = useState(true);

  const history = useHistory();
  const [showCustomizeBanner, setShowCustomizeBanner] = useState(false);
  const [functionalCookies, setFunctionalCookies] = useState(false);
  const [targetedAdsCookies, setTargetedAdsCookies] = useState(false);
  const [socialMediaCookies, setSocialMediaCookies] = useState(false);
  const [performanceCookies, setPerformanceCookies] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted) {
      setShowBanner(false);
    }
  }, []);

  // useEffect(() => { // Ajout de useEffect ici
  //   const cookiesArray = document.cookie.split(';').map(cookie => cookie.trim());

  //   const hasFunctionalCookies = cookiesArray.some(item => item.startsWith('functionalCookies='));
  //   const hasTargetedAdsCookies = cookiesArray.some(item => item.startsWith('targetedAdsCookies='));
  //   const hasSocialMediaCookies = cookiesArray.some(item => item.startsWith('socialMediaCookies='));
  //   const hasPerformanceCookies = cookiesArray.some(item => item.startsWith('performanceCookies='));
  //   const hasCookiesAccepted = cookiesArray.some(item => item.startsWith('cookiesAccepted='));

  //   if (hasFunctionalCookies && hasTargetedAdsCookies && hasSocialMediaCookies && hasPerformanceCookies && hasCookiesAccepted) {
  //     setShowBanner(false);
  //   }
  // }, []);

  const handleAcceptAll = () => {
    setFunctionalCookies(true);
    setTargetedAdsCookies(true);
    setSocialMediaCookies(true);
    setPerformanceCookies(true);
  
    // Sauvegardez ces préférences dans les cookies du navigateur
    document.cookie = 'functionalCookies=true; path=/; SameSite=Lax; secure=true; cookiesAccepted=true; max-age=31536000';
    document.cookie = 'targetedAdsCookies=true; path=/; SameSite=Lax secure=true; cookiesAccepted=true; max-age=31536000';
    document.cookie = 'socialMediaCookies=true; path=/; SameSite=Lax secure=true; cookiesAccepted=true; max-age=31536000';
    document.cookie = 'performanceCookies=true; path=/; SameSite=Lax secure=true; cookiesAccepted=true; max-age=31536000';

    localStorage.setItem('functionalCookies', 'true');
    localStorage.setItem('targetedAdsCookies', 'true');
    localStorage.setItem('socialMediaCookies', 'true');
    localStorage.setItem('performanceCookies', 'true');
    localStorage.setItem('cookiesAccepted', 'true');
    
  
    setShowBanner(false);
    handleCloseBanner();
  };  

  const handleSavePreferences = () => {
    // Sauvegardez ces préférences dans les cookies du navigateur
    document.cookie = `functionalCookies=${functionalCookies}; path=/; SameSite=Lax; secure=true; cookiesAccepted=true; max-age=31536000`;
    document.cookie = `targetedAdsCookies=${targetedAdsCookies}; path=/; SameSite=Lax; secure=true; cookiesAccepted=true; max-age=31536000`;
    document.cookie = `socialMediaCookies=${socialMediaCookies}; path=/; SameSite=Lax; secure=true; cookiesAccepted=true; max-age=31536000`;
    document.cookie = `performanceCookies=${performanceCookies}; path=/; SameSite=Lax; secure=true; cookiesAccepted=true; max-age=31536000`;
  
    setShowBanner(false);
  };
  

  const handleRejectAll = () => {
    setFunctionalCookies(false); 
    setTargetedAdsCookies(false);
    setSocialMediaCookies(false); 
    setPerformanceCookies(false); 

    localStorage.setItem('functionalCookies', 'false');
    localStorage.setItem('targetedAdsCookies', 'false');
    localStorage.setItem('socialMediaCookies', 'false');
    localStorage.setItem('performanceCookies', 'false');
    localStorage.setItem('cookiesAccepted', 'true');

    setShowBanner(false);
    handleCloseBanner();
  };


  return showBanner ? (
    <>
    {showBanner && (
    <>
    <div className="cookie-overlay"></div>
    <div className="cookie-banner">
        <div>
          <p className='roboto'>
            Nous utilisons des cookies, y compris des cookies tiers, à des fins de fonctionnement, d’analyses statistiques, de personnalisation de votre expérience, afin de vous proposer des contenus ciblés adaptés à vos centres d’intérêts et analyser la performance de nos campagnes publicitaires.<br /><br /> 
            Vous pouvez accepter ces cookies en cliquant sur « Accepter tout » ou cliquer sur « Personnaliser mes choix » pour gérer vos préférences. <br /><br />
            Vous pouvez à tout moment modifier vos préférences en bas de page du site your-guests.com. Pour en savoir plus sur les catégories de cookies ainsi que sur les émetteurs de cookie sur notre site, consultez ici notre notice dédiée à la {' '}
            <strong  
             className="clickable-text"
             onClick={() => history.push('/privacy')}>gestion des cookies
            </strong>.</p>
          <button onClick={() => setShowCustomizeBanner(true)}>Personnaliser mes choix</button>
          <button onClick={handleRejectAll}>Refuser tout</button>
          <button onClick={handleAcceptAll}>Accepter tout</button>
        </div>
    </div>
    </>
    )}
    {showCustomizeBanner && (
        <CustomizeBanner 
          functionalCookies={functionalCookies} 
          setFunctionalCookies={setFunctionalCookies}
          targetedAdsCookies={targetedAdsCookies}
          setTargetedAdsCookies={setTargetedAdsCookies}
          socialMediaCookies={socialMediaCookies}
          setSocialMediaCookies={setSocialMediaCookies}
          performanceCookies={performanceCookies}
          setPerformanceCookies={setPerformanceCookies}
          handleSavePreferences={handleSavePreferences}
          setShowCustomizeBanner={setShowCustomizeBanner}
          handleRejectAll={handleRejectAll}
          handleAcceptAll={handleAcceptAll}
        />
      )}
    </>
  ) : null;
};

export default CookieConsentBanner;
