import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { useBusinessLogic } from "../utils/businessLogic";
import { fetchData, getCurrentUser, updateProjectStatus, setAgreement } from "../api/api";
import GenericTableComponent from "../components/GenericTableComponent";
import ModalGuests from '../components/ModalGuests';
import ModalDisclaimer from "../components/ModalDisclaimer";
import { getRandomColor, getTextColor } from "../utils/colorUtils";
import ScrollToTop from "../components/ScrollToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { Circles } from 'react-loader-spinner';

import '../NouvelleListe.css';
import 'font-awesome/css/font-awesome.min.css';

const ListeInvites = () => {
  const [fileData, setFileData] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uniqueKey, setUniqueKey] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [subGroupName, setSubGroupName] = useState('');
  const [isFamily, setIsFamily] = useState([]);
  const [isPartner, setIsPartner] = useState([]);
  const [isOtherGroup, setIsOtherGroup] = useState([]);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [savedSort, setSavedSort] = useState([]);
  const [isButtonFixed, setIsButtonFixed] = useState(false);
  const [isColorMode, setIsColorMode] = useState(true);
  const [modalDisclaimerIsOpen, setModalDisclaimerIsOpen] = useState(false);

  const handleTableExpand = () => {
    setIsTableExpanded(!isTableExpanded);
  };

  const {
    handleSubmit,
    handleAddRow,
    handleEdit,
    handleOfferChange,
    handleDelete,
    handleRelationshipChange,
    handleCheckboxClick
  } = useBusinessLogic(fileData, setFileData, setHasUnsavedChanges, setSuccessMessage, setErrorMessage, setIsFamily, setIsPartner, setIsOtherGroup, uniqueKey, setUniqueKey, setModalIsOpen);

  const { projectId } = useParams();
  const history = useHistory();

  useEffect(() => {
    const handleScroll = () => {
      const buttonElement = document.getElementById("addRowButton");
      const rect = buttonElement.getBoundingClientRect();
      if (rect.top < 0 && !isButtonFixed) {
        setIsButtonFixed(true);
      } else if (rect.top >= 0 && isButtonFixed) {
        setIsButtonFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isButtonFixed]);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        setLoading(true);
        const res = await fetchData(projectId);
        let dataToSet;

        if (Array.isArray(res.data) && res.data.length > 0) {
          dataToSet = JSON.parse(JSON.stringify(res.data));
        } else {
          dataToSet = [{ id: Date.now(), NOM: 'Nom', PRENOM: 'Prénom', EMAIL: 'Email@email.com', TELEPHONE: '06...', relationship: '', offert: false }];
        }

        setFileData(dataToSet);
        setLoading(false);

      } catch (error) {
        console.error("Erreur lors de la récupération des données du fichier:", error);
        setError("Une erreur est survenue lors de la récupération des données.");
        setLoading(false);
      }
    };

    fetchDataFromApi();
  }, [projectId]);


  useEffect(() => {
    if (fileData.length > 0) {
      const ids = fileData.map(row => row.id || row._id); // Utilisez l'_id ou id, selon ce qui est disponible
      const idCount = ids.reduce((acc, id) => {
        acc[id] = (acc[id] || 0) + 1;
        return acc;
      }, {});
    }
  }, [fileData]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir quitter ?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const getNextGroupID = (prefix) => {
    const ids = fileData.map(row => {
      if (row.subGroupID && row.subGroupID.id && row.subGroupID.id.startsWith(prefix)) {
        return parseInt(row.subGroupID.id.replace(prefix, ''), 10);
      }
      return 0;
    });
    const maxID = Math.max(...ids);
    return `${prefix}${maxID + 1}`;
  };

  const confirmGroup = async () => {  // Ajout de async ici

    const color = getRandomColor();
    const textColor = getTextColor(color);

    let newID;

    if (isFamily) {
      newID = getNextGroupID('F');
      updateFileData(newID, 'Famille', subGroupName, color, textColor);
    } else if (isPartner) {
      newID = getNextGroupID('P');
      updateFileData(newID, 'Partenaire', subGroupName, color, textColor);
    } else if (isOtherGroup) {
      newID = getNextGroupID('G');
      updateFileData(newID, 'Groupe', subGroupName, color, textColor)
    }
    // await handleSubmit(projectId);  // Ajout de await ici
    setModalIsOpen(false);
  }

  const updateFileData = (newID, groupName, subGroupName, color, textColor) => {
    const newSubGroupID = {
      id: newID,
      name: groupName,
      custGroupName: subGroupName,
      color: color,
      textColor: textColor
    };

    const updatedFileData = [...fileData].map(row =>
      row.isSelected ? { ...row, subGroupID: newSubGroupID } : row
    );
    setFileData(updatedFileData);
  };

  const openModalIfValidGroup = () => {
    const filteredRows = fileData.filter(row => row.isSelected);

    if (filteredRows.length === 0) {
      // Affichez un message d'erreur ou une alerte
      alert("Vous n'avez pas sélectionné d'invités.");
      return;
    }

    const isFamilyGroup = filteredRows.length >= 2 && filteredRows.every(row => ["Parent", "Enfant"].includes(row.relationship));
    const isPartnerGroup = filteredRows.length === 2 && filteredRows.every(row => row.relationship === "Partenaire");
    const isFriendGroup = filteredRows.length >= 3 && filteredRows.every(row => row.relationship === "Groupe");

    if (isFamilyGroup) {
      setIsFamily(true);
      setIsPartner(false);
      setIsOtherGroup(false);
      setModalIsOpen(true);
    } else if (isPartnerGroup) {
      setIsPartner(true);
      setIsFamily(false);
      setIsOtherGroup(false);
      setModalIsOpen(true);
    } else if (isFriendGroup) {
      setIsOtherGroup(true);
      setIsPartner(false);
      setIsFamily(false);
      setModalIsOpen(true);
    } else {
      alert("Les invités sélectionnés ne forment pas un groupe valide.");
    }
  };

  const handleNextClick = async () => {
    setModalDisclaimerIsOpen(true);
  };

  const handleDisclaimerAgree = async () => {
    try {
      const userResponse = await getCurrentUser();
      const userId = userResponse.data._id;

      const updateGuestListStatus = updateProjectStatus(userId, projectId, 'guestListStatus', 'Terminé');
      const updateCustStatus = updateProjectStatus(userId, projectId, 'customizationStatus', 'En cours');
      const [guestListResponse, custResponse] = await Promise.all([updateGuestListStatus, updateCustStatus]);

      const setAgreementConsent = await setAgreement(userId, projectId);

      if (guestListResponse.status === 200 && custResponse.status === 200 && setAgreementConsent.status === 200) {
        history.push(`/creer/personnaliser-reservation/${projectId}`);
      } else {
        console.log("Erreur lors de la mise à jour des statuts");
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du projet:', error);
    }

    setModalDisclaimerIsOpen(false);
  };

  const handleDisclaimerCancel = () => {
    setModalDisclaimerIsOpen(false);
  };

  const handlePrevious = async () => {
    const userResponse = await getCurrentUser();
    const userId = userResponse.data._id;
    await updateProjectStatus(userId, projectId, 'guestListStatus', 'Non commencé');
    await updateProjectStatus(userId, projectId, 'venueStatus', 'En cours');
    history.push(`/creer/depot-fichier/${projectId}`);
  };

  return (
    <div className="grid-container">
      <div className="instruction-container">
        <h2>Comment utiliser ce tableau :</h2>
        <ul>
          <li className="listTable"><strong>Consentement :</strong> Assurez-vous d'avoir obtenu le consentement de vos invités à qui vous souhaiteriez faire patager une chambre. Si vous avez un doute, contactez-les afin de le dissiper.</li>
          <li className="listTable"><strong>Relations :</strong> Nous conseillons fortement d'utiliser ce champs pour regrouper vos invités par couple, famille, amis. La catégorie "Autre Groupe" vous permet de regrouper entre eux des amis, plusieurs couples. La catégorie "Partenaire" peut désigner un couple ou 2 personnes seules acceptant de partager une chambre. Chaque relation créée définit un groupe d'invités qui partageront une chambre ou une suite.</li>
          <li className="listTable"><strong>Offert :</strong> Cochez cette case si le coût du logement est pris en charge par les mariés.</li>
          <li className="listTable"><strong>Nom du groupe :</strong> N'hésitez pas à donner en nom de groupe, le nom ou le surnom avec lequel vous avez l'habitude d'appeler votre/vos invités. Ou encore avec un nom qui permet de les identifier, par exemple : Parents de la mariée. Ce nom sera celui que vos invités verront sur leur page de réservation de chambre.</li>
          <li className="listTable"><strong>Sauvegarder :</strong> À chaque création de groupe, il est <strong>nécessaire de sauvegarder</strong> avant de passer au groupe suivant.</li>
        </ul>

      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="ajouter-ligne-button-container">
        <button
          id="addRowButton"
          className={`buttons ${isButtonFixed ? 'fixed-button' : ''}`}
          onClick={handleAddRow}
        >
          <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} /> Ajouter une ligne
        </button>
        <ModalGuests
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}>
          <h2 className="title-modal">Entrer le nom du groupe</h2>
          <input
            className="lecture-fichier-input"
            type="text"
            placeholder="Nom personnalisé du groupe"
            onChange={(e) => setSubGroupName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                confirmGroup();  // La fonction qui gère la confirmation
              }
            }}
          />
          <button
            className="buttons"
            onClick={confirmGroup}>Confirmer
          </button>
        </ModalGuests>
        {loading && (
                <div className='loadingContainer'>
                    <Circles
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        color="#5465ff"
                    />
                </div>
            )}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && Array.isArray(fileData) &&
          <GenericTableComponent
            savedSort={savedSort}
            setSavedSort={setSavedSort}
            isExpanded={isTableExpanded}
            toggleExpand={handleTableExpand}
            key={`${uniqueKey}-${isColorMode ? 'colored' : 'uncolored'}`}
            data={fileData}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleOfferChange={handleOfferChange}
            handleRelationshipChange={handleRelationshipChange}
            handleCheckboxClick={handleCheckboxClick}
            rowCount={fileData.length}
            isColorMode={isColorMode}
          />}
        <div className="sauvegarder-button-container">
          <button
            className="buttons"
            onClick={handlePrevious}
          >
            Précédent
          </button>
          <button
            className="buttons"
            onClick={openModalIfValidGroup}
          >
            Créer un groupe
          </button>
          <button
            className="buttonSave"
            onClick={() => handleSubmit(projectId)}
          >
            <FontAwesomeIcon icon={faFloppyDisk} style={{ color: "#ffffff", marginRight: '4px' }} /> Sauvegarder
          </button>
          <button
            className="buttons"
            onClick={handleNextClick}
          >
            Suivant
          </button>
        </div>
        <ModalDisclaimer
          isOpen={modalDisclaimerIsOpen}
          onAgree={handleDisclaimerAgree}
          onCancel={handleDisclaimerCancel}
        />
      </div>
      <ScrollToTop />
    </div>
  );
};

export default ListeInvites;
