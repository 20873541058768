import React, { createContext, useState, useContext, useEffect } from 'react';

const GuestsPreBookedContext = createContext({
    remainingCapacity: null,
    setRemainingCapacity: () => { console.warn("setRemainingCapacity not provided"); },
    selectedGroups: {},
    setSelectedGroups: () => { console.warn("setSelectedGroups not provided"); },
    allGroups: {},
    setAllGroups: () => {},
    buildingDetails: {},
    setBuildingDetails: () => {},
    isBooked: false,
    setIsBooked: () => {},
});

export const GuestsPreBookedProvider = ({ children }) => {
    const [selectedGroups, setSelectedGroups] = useState({});
    const [allGroups, setAllGroups] = useState({});
    
    const [remainingCapacity, setRemainingCapacity] = useState(() => {
        const savedRemainingCapacity = localStorage.getItem("remainingCapacity");
        return savedRemainingCapacity ? JSON.parse(savedRemainingCapacity) : null; // Initialiser à 0 si rien n'est trouvé
    });
    const [buildingDetails, setBuildingDetails] = useState(() => {
        const savedDetails = localStorage.getItem("buildingDetails");
        return savedDetails ? JSON.parse(savedDetails) : {}; // Initialisez avec une valeur par défaut si rien n'est trouvé
    });
    const [isBooked, setIsBooked] = useState(() => {
        const savedIsBooked = localStorage.getItem("isBooked");
        return savedIsBooked ? JSON.parse(savedIsBooked) : false;
    });

    useEffect(() => {
        localStorage.setItem("buildingDetails", JSON.stringify(buildingDetails));
    }, [buildingDetails]);

    useEffect(() => {
        localStorage.setItem("isBooked", JSON.stringify(isBooked));
    }, [isBooked]);

    useEffect(() => {
        localStorage.setItem("remainingCapacity", JSON.stringify(remainingCapacity));
    }, [remainingCapacity]);

    return (
        <GuestsPreBookedContext.Provider value={{ 
            remainingCapacity, 
            setRemainingCapacity, 
            selectedGroups, 
            setSelectedGroups, 
            allGroups, 
            setAllGroups,
            buildingDetails, 
            setBuildingDetails, 
            isBooked, 
            setIsBooked,
        }}>
            {children}
        </GuestsPreBookedContext.Provider>
    );
};

export const usePreBookedGuests = () => useContext(GuestsPreBookedContext);
