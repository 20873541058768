import React, { useState, useEffect } from 'react';
import {  Element, animateScroll as scroller } from 'react-scroll';
import DateSelectorSection from './DateSelectorSection';
import SpecialRatesSection from './SpecialRatesSection';
import PriceApplicationSelector from './PriceApplicationSelector';
// import DotButton from './DotButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUp, faCircleDown } from '@fortawesome/free-regular-svg-icons';
import styles from '../PaymentCalculationSelector.module.css';

const PaymentCalculationSelector = () => {
  const [currentSection, setCurrentSection] = useState('dates');
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const isFirstSection = currentSection === 'dates';
  const isLastSection = currentSection === 'specialRates';

  const handleSectionChange = (section) => {
    setCurrentSection(section);
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  const goToNextSection = () => {
    const sections = ['dates', 'price', 'specialRates'];
    const currentIndex = sections.indexOf(currentSection);
    const nextSection = sections[(currentIndex + 1) % sections.length];
    handleSectionChange(nextSection);
  };

  const goToPreviousSection = () => {
    const sections = ['dates', 'price', 'specialRates'];
    const currentIndex = sections.indexOf(currentSection);
    const previousSection = sections[(currentIndex - 1 + sections.length) % sections.length];
    handleSectionChange(previousSection);
  };



  return (
    <section id="paymentCalculationSelector" className={styles.paymentCalculatorSection}>

      <div className={styles.sectionsContainer}>
        {currentSection === 'dates' && (
          <Element name="dates" className={styles.element}>
            <DateSelectorSection dates={dates} setDates={setDates} />
          </Element>
        )}
        {currentSection === 'price' && (
          <Element name="price" className={styles.element}>
            <PriceApplicationSelector />
          </Element>
        )}
        {currentSection === 'specialRates' && (
          <Element name="specialRates" className={styles.element}>
            <SpecialRatesSection />
          </Element>
        )}
      </div>


      <button
        className={`${styles.navButton} ${styles.navButtonTopLeft}`}
        onClick={goToPreviousSection}
        disabled={isFirstSection}
      >
        <FontAwesomeIcon icon={faCircleUp} size='xl' color={isFirstSection ? 'grey' : 'black'} />
      </button>

      <button
        className={`${styles.navButton} ${styles.navButtonBottomRight}`}
        onClick={goToNextSection}
        disabled={isLastSection}
      >
        <FontAwesomeIcon icon={faCircleDown} size='xl' color={isLastSection ? 'grey' : 'black'} />
      </button>

    </section >
  );
};
export default PaymentCalculationSelector;