import React from 'react';
import styles from '../VirtualTourModal.module.css'; // Importez vos styles CSS pour le modal

const VirtualTourModal = ({ virtualTourUrl, onClose }) => {
    const handleOverlayClick = (event) => {
        // Vérifie si le clic est sur l'overlay et non sur l'enfant (iframe)
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    
    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div className={styles.modalContent}>
                <iframe src={virtualTourUrl} width="100%" height="100%" title="Visite Virtuelle"></iframe>
            </div>
        </div>
    );
};

export default VirtualTourModal;
