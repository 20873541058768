import React, { useState } from 'react';

const CustomizeBanner = ({
  functionalCookies, setFunctionalCookies,
  targetedAdsCookies, setTargetedAdsCookies,
  socialMediaCookies, setSocialMediaCookies,
  performanceCookies, setPerformanceCookies,
  handleSavePreferences,
  handleRejectAll,
  handleAcceptAll,
  setShowCustomizeBanner
}) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      {/* <div className="cookie-overlay"></div> */}
      <div className={`cookie-banner ${showDetails ? 'detailed-view' : ''}`}>
        <h2>Personnalisez vos préférences de cookies</h2>
        <p className='roboto'>
              Un cookie est un fichier texte susceptible d'être enregistré dans un espace dédié du disque dur de votre terminal (ordinateur, tablette, smartphone, etc.) à l'occasion de la consultation d'un service en ligne grâce à votre navigateur. Un cookie permet à son émetteur d'identifier le terminal dans lequel il est enregistré, pendant la durée de validité du consentement qui n’excède en général pas 6 mois.<br /><br />
              Vous pouvez changer vos préférences cookies à tout moment, en cliquant sur le bouton correspondant en bas de notre site.<br /><br />
              Pour en savoir plus sur les catégories de cookies ainsi que sur les émetteurs de cookie sur notre site, consultez ici notre notice dédiée à la gestion des cookies.
              </p>
        <button onClick={handleAcceptAll}>Accepter tout</button>

        <div className="cookie-option">
          <div className="cookie-header">
            <label className="cookie-label">Cookies fonctionnels</label>
            <label className="switch">
              <input
                type="checkbox"
                className="switch-checkbox"
                aria-checked={functionalCookies}
                aria-label="Autoriser les cookies fonctionnels"
                checked={functionalCookies}
                onChange={() => setFunctionalCookies(!functionalCookies)}
              />
              <span className="switch-slider"></span>
            </label>
          </div>
          <p className="cookie-description roboto">
            Ces cookies permettent d’améliorer et de personnaliser les fonctionnalités du site Web. Ils peuvent être activés par nos équipes, ou par des tiers dont les services sont utilisés sur les pages de notre site Web. Si vous n'acceptez pas ces cookies, une partie ou la totalité de ces services risquent de ne pas fonctionner correctement.
          </p>
        </div>

        <div className="cookie-option">
          <div className="cookie-header">
            <label className="cookie-label">Cookies publicitaires ciblés</label>
            <label className="switch">
              <input
                type="checkbox"
                className="switch-checkbox"
                aria-checked={functionalCookies}
                aria-label="Autoriser les cookies publicitaires ciblés"
                checked={targetedAdsCookies}
                onChange={() => setTargetedAdsCookies(!targetedAdsCookies)}
              />
              <span className="switch-slider"></span>
            </label>
          </div>
          <p className="cookie-description roboto">
          Ces cookies peuvent être mis en place au sein de notre site Web par nos partenaires publicitaires. Ils peuvent être utilisés par ces sociétés pour établir un profil de vos intérêts et vous proposer des publicités pertinentes sur d'autres sites Web. Ils ne stockent pas directement des données personnelles, mais sont basés sur l'identification unique de votre navigateur et de votre appareil Internet. Si vous n'autorisez pas ces cookies, votre publicité sera moins ciblée.
          </p>
      </div>
      <div className="cookie-option">
          <div className="cookie-header">
            <label className="cookie-label">Cookies de médias sociaux</label>
            <label className="switch">
              <input
                type="checkbox"
                className="switch-checkbox"
                aria-checked={functionalCookies}
                aria-label="Autoriser les cookies médias sociaux"
                checked={socialMediaCookies}
                onChange={() => setSocialMediaCookies(!socialMediaCookies)}
              />
              <span className="switch-slider"></span>
            </label>
          </div>
          <p className="cookie-description roboto">
          Ces cookies sont activés par les services proposés sur les réseaux sociaux que nous avons ajoutés au site Web afin de vous donner la possibilité de partager notre contenu avec votre réseau et vos connaissances. Ils nous permettent également de suivre votre navigation sur d’autres sites Web et d’établir un profil de vos intérêts. Cela peut avoir un impact sur le contenu et les messages affichés sur les autres sites Web que vous consultez. Si vous n'autorisez pas ces cookies, il se peut que vous ne puissiez pas utiliser ou visualiser ces outils de partage.
          </p>
      </div>
      <div className="cookie-option">
          <div className="cookie-header">
            <label className="cookie-label">Cookies de performance</label>
            <label className="switch">
              <input
                type="checkbox"
                className="switch-checkbox"
                aria-checked={functionalCookies}
                aria-label="Autoriser les cookies performance"
                checked={performanceCookies}
                onChange={() => setPerformanceCookies(!performanceCookies)}
              />
              <span className="switch-slider"></span>
            </label>
          </div>

          <p className="cookie-description roboto">
          Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site Web. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web. Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n'acceptez pas ces cookies, nous ne serons pas informé de votre visite sur notre site.
          </p>
      </div>
      <div className='button-container'>
      <button onClick={handleSavePreferences}>Confirmer la sélection</button>
      <button onClick={handleRejectAll}>Refuser tout</button>
      <button onClick={() => setShowDetails(true)}>Personnaliser mes choix</button>
      {/* <button onClick={() => setShowCustomizeBanner(false)}>Fermer</button> */}
      </div>
    </div>
    </>
  );
};

export default CustomizeBanner;