import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import DeviceNotification from './DeviceNotification';
import { isMobile } from 'react-device-detect';
import { AuthContext } from '../contexts/AuthContext';
import { Circles } from 'react-loader-spinner';
import '../Spinner.css';

function PrivateRoute({ component: Component, ...rest }) {
  const { isLoggedIn, isLoading } = useContext(AuthContext);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setShowNotification(true);
    }
  }, []);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  if (isLoading) {
    return (
      <div className="loadingContainer">
        <Circles
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-loading"
          wrapperStyle={{}}
          wrapperClass=""
          color="#5465ff"
        />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn) {
          return (
            <>
              {showNotification && <DeviceNotification onClose={handleCloseNotification} />}
              <Component {...props} />
            </>
          );
        } else {
          // Définir l'URL de la dernière page visitée avant de rediriger vers la page de connexion
          localStorage.setItem('preLogoutRoute', window.location.pathname);
          return <Redirect to="/login-register" />;
        }
      }}
    />
  );
}

export default PrivateRoute;
