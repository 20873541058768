import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { UploadFile, updateProjectStatus, getCurrentUser, getWorksheet } from '../api/api';
import styles from '../DeposerFichier.module.css';
import Modal from './Modal';

function UploadFileComponent() {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploadFailed, setIsUploadFailed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [worksheets, setWorksheets] = useState([]);
    const [selectedWorksheet, setSelectedWorksheet] = useState('');

    const { projectId } = useParams();
    const history = useHistory();

    // UPLOAD
    const onFileUpload = acceptedFiles => {
        const selectedFile = acceptedFiles[0];
        if (!selectedFile) return;

        const validFileTypes = ["text/csv", "text/plain", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        if (validFileTypes.includes(selectedFile.type)) {
            setFile(selectedFile);
            setError(null);
            if (selectedFile.type.includes("spreadsheetml")) {
                // If Excel file, show modal to select worksheet
                setShowModal(true);
                fetchWorksheets(selectedFile);
            }
        } else {
            setError("Veuillez sélectionner un fichier CSV, TXT ou Excel.");
        }
    };

    const fetchWorksheets = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const data = await getWorksheet({projectId, formData});
            console.log('data', data);
            setWorksheets(data.worksheets);
        } catch (error) {
            console.error('Error fetching worksheets:', error);
        }
    };

    // TESTS FICHIER
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            setError("Vous n'avez sélectionné aucun fichier");
            return;
        }

        if (file.size > 3 * 1024 * 1024) {
            setError("Le fichier est trop grand. La taille maximale est de 3 Mo.");
            return;
        }

        if ( file.type.includes("spreadsheetml") && !selectedWorksheet) {
            setError("Veuillez sélectionner une feuille de calcul.");
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', file.name);
        formData.append('projectId', projectId);
        formData.append('worksheet', selectedWorksheet);
        
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
    
        try {
            const response = await UploadFile({ projectId, formData });

            const userResponse = await getCurrentUser();
            const userId = userResponse.data._id;

            const updateStatus = await updateProjectStatus(userId, projectId, 'guestListStatus', 'En cours');

            if (response.data.success && updateStatus.status === 200) {
                setIsUploaded(true);
                setSuccessMessage("Fichier correctement chargé!");
            } else {
                setIsUploadFailed(true);
                setError(`Erreur lors de l'envoi du fichier.`);
            }

        } catch (error) {
            setIsUploadFailed(true);
            setError(`Erreur lors de l'envoi du fichier. ${error.response && error.response.data ? error.response.data.detail : ''}`);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onFileUpload,
        accept: 'text/csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const closeModal = () => {
        setShowModal(false); // Fermer le modal
      };

    return (
        <div className="flex flex-col items-center justify-center roboto mb-2">
            <section>
                <div {...getRootProps()} className="border-dashed border-2 rounded border-gray-500 p-6 mb-4 mt-4 flex items-center justify-center bg-gray-200 text-gray-500">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Déposez votre fichier ici ...</p>
                    ) : (
                        <p>Faites glisser et déposez un fichier ici, ou cliquez pour sélectionner un fichier</p>
                    )}
                </div>
            </section>
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}
            <div className="flex items-center"></div>
            <button
                onClick={handleSubmit}
                className={styles.buttonUpload}>
                Uploader le fichier
            </button>
            {file && (
                <div className="mt-4 ml-4 file-name-box">
                    <span>{file.name}</span>
                    {isUploaded && <FontAwesomeIcon icon={faCheckCircle} className="ml-2 text-green-500" />}
                    {isUploadFailed && <FontAwesomeIcon icon={faXmark} className="ml-2 text-red-500" />}
                </div>
            )}
            <button
                onClick={() => history.push(`/creer/liste-d-invites/${projectId}`)}
                className={`${styles.buttonUpload} ${!isUploaded ? styles.inactive : ''}`}
                disabled={!isUploaded}
            >
                Suivant
            </button>
            {showModal && (
                <Modal onClose={closeModal}>
                    <div>
                    <h2>Choisissez une feuille de calcul</h2>
                        {worksheets && worksheets.length > 0 ? (
                            <select
                                value={selectedWorksheet}
                                onChange={(e) => setSelectedWorksheet(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded"
                            >
                                <option value="" disabled>Choisissez une feuille</option>
                                {worksheets.map(sheet => (
                                    <option key={sheet} value={sheet}>
                                        {sheet}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <p>Aucune feuille trouvée.</p>
                        )}
                        <button
                            onClick={() => {
                                if (selectedWorksheet) {
                                    setShowModal(false);
                                } else {
                                    setError('Veuillez sélectionner une feuille.');
                                }
                            }}
                            className={styles.buttonUpload}
                        >
                            Confirmer
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default UploadFileComponent;
