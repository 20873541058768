import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import EmailValidation from '../utils/EmailValidation';
import { GrCircleInformation, GrFormDown, GrFormUp } from "react-icons/gr";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../PartnerRegistration.module.css';

const PartnerPlanModal = ({ isOpen, onClose, onConfirm, selectedPlan, activePlan }) => {
    const [form, setForm] = useState({ venueName: '', venueAddress: '', venueZipCode: '', venueCity: '', vatNumber: '' });
    const [cguChecked, setCguChecked] = useState(false);
    const [validEmail, setValidEmail] = useState('');
    const [isCompanyTooltipVisible, setIsCompanyTooltipVisible] = useState(false);
    const [isVATTooltipVisible, setIsVATTooltipVisible] = useState(false);

    const [modalStyle, setModalStyle] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9990,
            minWidth: '25%',
            maxWidth: '45%',
            overflow: 'auto',
            borderRadius: '10px',
            color: '#000000',
            paddingTop: '0px',
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingBottom: '20px'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9998
        }
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalStyle(prevStyle => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '95%'
                    }
                }));
            } else if (window.innerWidth <= 1200) {
                setModalStyle(prevStyle => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '75%',
                        minWidth: '70%'
                    }
                }));
            } else {
                setModalStyle(prevStyle => ({
                    ...prevStyle,
                    content: {
                        ...prevStyle.content,
                        maxWidth: '45%'
                    }
                }));
            }
        };

        handleResize(); // Check the initial window size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const checkValidVAT = async (vatNumber) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/public/vat-validate`, {
                vatNumber,
                withCredentials: false,
            });

            return response.data; // Directly return the reviews array
        } catch (error) {
            console.error('Error fetching reviews:', error);
            throw error;
        }
    };

    const checkValidEmail = async (contactEmail) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/public/email-validate`, {
                contactEmail,
                withCredentials: false,
            });

            return response; // Directly return the reviews array
        } catch (error) {
            console.error('Error fetching reviews:', error);
            throw error;
        }
    };

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log('Submit button clicked!');
        if (!cguChecked) {
            alert('Veuillez accepter les CGU.');
            return;
        }

        if (!selectedPlan) {
            alert('Aucun plan sélectionné.');
            return;
        }

        try {

            const VATResponse = await checkValidVAT(form.vatNumber);
            if (!VATResponse.isValid) {
                alert('Numéro de TVA invalide.');
                return;
            }

            const EmailResponse = await checkValidEmail(validEmail);
            if (EmailResponse.status === 400) {
                alert('Email de contact invalide.');
                return;
            }
            // Proceed with the rest of the form submission logic if VAT is valid
            const updatedPartnerPlan = {
                ...selectedPlan,
                venueName: form.venueName,
                venueAddress: form.venueAddress,
                venueZipCode: form.venueZipCode,
                venueCity: form.venueCity,
                venueCountry: form.venueCountry,
                contactEmail: validEmail, // Use validEmail here
                vatNumber: form.vatNumber,
                subscriptionType: subscriptionType,
                price: price
            };
            console.log('updatedPartnerPlan', updatedPartnerPlan)
            await onConfirm(updatedPartnerPlan);
        } catch (error) {
            console.error('Erreur lors de la validation du TVA:', error);
            alert('Une erreur est survenue lors de la validation du TVA.');
        };
    };

    const VATRate = 0.2;
    // Ensure the price is a valid number before calculating VAT
    const price = selectedPlan && selectedPlan.price ? parseFloat(selectedPlan.price) : 0;
    const VATAmount = price * VATRate;

    const subscriptionType = selectedPlan?.isAnnual ? 'Annuel' : 'Mensuel';

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={modalStyle}
        >
            <div className={styles.modalHeader}>
                <button onClick={onClose} className={styles.closeButton}>
                    <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '24px' }} />
                </button>
                <h2 className={styles.modalTitle}>Confirmer votre sélection</h2>
            </div>
            {selectedPlan && (
                <div className={styles.modalBody}>
                    <p><strong>Plan sélectionné:</strong> {selectedPlan.name} | {subscriptionType}</p>
                    <p><strong>Prix:</strong> {price ? `${price.toFixed(2)} €` : 'Non défini'}</p>
                    <p><strong>TVA:</strong> {VATAmount.toFixed(2)} €</p>

                    <form
                        onSubmit={handleSubmit}
                        className={styles.formContainer}
                    >
                        <div className={styles.formRow}>
                            <div className={styles.labelContainer}>
                                <label>Nom de la société :</label>
                            </div>
                            <div className={styles.inputContainer}>
                                <div className={styles.tooltipContainer}>
                                    <input
                                        name="venueName"
                                        value={form.venueName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsCompanyTooltipVisible(!isCompanyTooltipVisible);
                                        }}
                                        className={styles.infoButton}
                                    >
                                        <GrCircleInformation style={{ color: '#ff0000', marginLeft: '10px' }} />
                                        {isCompanyTooltipVisible ? <GrFormUp style={{ cursor: 'pointer' }} /> : <GrFormDown style={{ cursor: 'pointer' }} />}
                                    </button>
                                </div>
                                {isCompanyTooltipVisible && (
                                    <div className={styles.tooltipText}>
                                        Saisissez la dénomination sociale de votre entreprise et assurez-vous que votre adresse corresponde au pays associé à votre numéro fiscal afin de garantir l'exactitude du calcul des taxes.
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.formRow}>
                            <label>Adresse du lieu : </label>
                            <input
                                name="venueAddress"
                                value={form.venueAddress}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label>Code postal : </label>
                            <input
                                name="venueZipCode"
                                value={form.venueZipCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label>Ville : </label>
                            <input
                                name="venueCity"
                                value={form.venueCity}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label>Pays : </label>
                            <input
                                name="venueCountry"
                                value={form.venueCountry}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <EmailValidation onValidEmail={setValidEmail} />

                        <div className={styles.formRow}>
                            <div className={styles.labelContainer}>
                                <label>Numéro de TVA : </label>
                            </div>
                            <div className={styles.inputContainer}>
                                <div className={styles.tooltipContainer}>
                                    <input
                                        name="vatNumber"
                                        value={form.vatNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setIsVATTooltipVisible(!isVATTooltipVisible)}
                                        className={styles.infoButton}
                                    >
                                        <GrCircleInformation style={{ color: '#ff0000', marginLeft: '10px' }} />
                                        {isVATTooltipVisible ? <GrFormUp style={{ cursor: 'pointer' }} /> : <GrFormDown style={{ cursor: 'pointer' }} />}
                                    </button>
                                </div>

                                {isVATTooltipVisible && (
                                    <div className={styles.tooltipText}>
                                        Renseignez le numéro de TVA de votre entreprise pour permettre le calcul des taxes approprié.
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={styles.formRow}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={cguChecked}
                                    onChange={(e) => setCguChecked(e.target.checked)}
                                    className={styles.checkboxInput}
                                />
                                J'ai lu et j'accepte les <a href="/terms" target="_blank">Conditions Générales d'Utilisation</a>
                            </label>
                        </div>

                        <div className={styles.confirmButtonContainer}>
                            <button
                                type="submit"
                                className={styles.button}
                                disabled={!cguChecked || !form.venueName || !form.venueAddress || !form.venueZipCode || !form.venueCity || !validEmail || !form.vatNumber}
                            >
                                Confirmer et Payer
                            </button>
                        </div>
                    </form>
                </div >
            )}
        </Modal >
    );
};

export default PartnerPlanModal;
