import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';

import styles from '../CustomArrowStyles.module.css'

const CarousselArrows = ({ style, onClick, direction }) => { //className
    const arrowStyle = direction === "next" ? styles.rightArrow : styles.leftArrow;
    
    return(
    <div
        className={arrowStyle}
        style={{ ...style, display: "block"}} /*, background: "transparent", border: "none"*/ 
        onClick={onClick}
    >
        {direction === "next" ? (
            <FontAwesomeIcon icon={faCircleChevronRight} style={{ color: "#ffffff" }} /> 
        ) : (
            <FontAwesomeIcon icon={faCircleChevronLeft} style={{ color: "#ffffff" }} />
        )}
    </div>
);
};

export default CarousselArrows;