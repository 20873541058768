import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';


function FollowUp() {
  return (
    <FontAwesomeIcon icon={faEye} beat size="4x" style={{ color: '#1C35E8' }}/>
  );
}

export default FollowUp;
