import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import BillIcon from './BillIcon';
import Focus from './Focus'
import FollowUp from './Followup';
import '../AboutSection.css'; 

const detectScale = () => {
  const scale = window.devicePixelRatio || 1;
  if (scale > 1) {
    document.body.classList.add('scaled');
  }
};

export default function AboutSection() {

  useEffect(() => {
    detectScale();
  }, []);

    const fadeInFromBottom = useSpring({
        to: { opacity: 1, marginTop: 5, marginBottom: 10 },
        from: { opacity: 0, marginTop: 200, marginBottom: 200 },
        delay: 500
    });

    return (
        <section id="about" className="about-section">
          <div className='mb-20'>
            <h2 className='text-2xl roboto font-light h2-color' >
                Your Guests est une plateforme en ligne qui veut faciliter les démarches déjà nombreuses et parfois exhaustives des futurs mariés.
            </h2>
          </div>
          <div className="grid-about">
            {/* Left column */}
            <animated.div style={fadeInFromBottom} className="flex flex-col items-center justify-start">
              <Focus className="mb-4" />
              <h1 className='font-semibold roboto mt-10 mb-4 text-gray-700'>
                Organisation
              </h1>
              <p className="w-4/5 roboto text-gray-500">Nous vous aidons à vous organiser pour que vous ne vous concentriez que sur l'essentiel : voir votre projet avancer sereinement et profiter</p>
            </animated.div>

            {/* Middle column */}
            <animated.div style={fadeInFromBottom} className="flex flex-col items-center justify-start">
              <FollowUp className="mb-4" />
              <h1 className='font-semibold roboto mt-10 mb-4 text-gray-700'>
                Suivi
              </h1>
              <p className="w-4/5 roboto text-gray-500">Recevez un bilan hebdomadaire, suivez vos réservations en ligne et concentrez vous sur d'autres aspects de votre mariage grâce à notre système de relance intégré</p>
            </animated.div>

            {/* Right column */}
            <animated.div style={fadeInFromBottom} className="flex flex-col items-center justify-start">
              <BillIcon className="mb-4" />
              <h1 className='font-semibold roboto mt-10 mb-4 text-gray-700'>
                Paiement
              </h1>
              <p className="w-4/5 roboto text-gray-500">Grâce au paiement sécurisé, vos invités peuvent participer en toute sécurité. Quand la période de réservation est terminée, la cagnotte vous est reversée</p>
            </animated.div>
          </div>
        </section>
    );
}
