import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../SpiningArrows.css'

const SpinningArrows = () => {
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSpin(true);
      // Désactivez le spin après 0.5 seconde (durée de l'animation)
      setTimeout(() => setSpin(false), 500);
    }, 10000); // Activez le spin toutes les 10 secondes

    return () => clearInterval(interval);
  }, []);

  return (
    <FontAwesomeIcon
      icon={faArrowRightArrowLeft}
      size="xl"
      style={{ color: '#4b1753' }}
      className={spin ? 'fast-spin' : ''}
    />
  );
};

export default SpinningArrows;
