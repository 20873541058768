import React, { useRef } from 'react';
import MyEmailEditor from './EmailEditorYG';
import Modal from 'react-modal'; // ou votre composant modale personnalisé
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../../EmailEditorModal.module.css'

const EmailEditorModal = ({ emailContent, editorType, onClose, setSuccessMessage, setErrorMessage }) => {
  const emailEditorRef = useRef(null);

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
      minWidth: '90%',
      maxHeight: '90%',
      overflow: 'auto'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9998
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      style={modalStyle}
    >
      <button onClick={onClose} className={styles.closeButton}>  
          <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '24px' }} />
      </button>
      <MyEmailEditor
        emailContent={emailContent}
        editorType={editorType}
        ref={emailEditorRef}
        onClose={onClose}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />
    </Modal>
  );
};

export default EmailEditorModal;