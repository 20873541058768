import React, { useState, useEffect } from 'react';
import styles from '../../stripeBalance.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { getStripeBalance } from '../../api/api';

const StripeBalance = ({ accountId }) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const balanceData = await getStripeBalance(accountId);
        setBalance((balanceData.available[0].amount / 100) + (balanceData.pending[0].amount / 100));
       
      } catch (error) {
        console.error("Failed to fetch balance:", error);
      }
    };
    fetchBalance();
  }, [accountId]); 

  return (
    <div className={styles.balance}>
      <div>        
        <h3>
          <FontAwesomeIcon icon={faWallet} size="lg" style={{color: '#182125', marginRight: '10px'}} />
          Votre solde disponible et en attente
        </h3>
        <p>{balance.toFixed(2)} €</p>
      </div>
    </div>
  );
};

export default StripeBalance;
