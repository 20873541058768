//AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { api, loginUser, logoutUser, checkLoginStatus, refreshToken, getUserInfo } from '../api/api';
import { useHistory } from 'react-router-dom';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();
  const [shouldShowReminderModal, setShouldShowReminderModal] = useState(false);

  let isRefreshing = false;
  let refreshSubscribers = [];

  function subscribeRefreshToken(callback) {
    refreshSubscribers.push(callback);
  }

  function onRefreshed(accessToken) {
    refreshSubscribers.forEach(callback => callback(accessToken));
    refreshSubscribers = [];
  }

  const refreshAccessToken = async () => {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const response = await refreshToken();
        isRefreshing = false;
        const newAccessToken = response.newAccessToken;
        onRefreshed(newAccessToken);
        return newAccessToken;
      } catch (error) {
        isRefreshing = false;
        console.error("Échec du rafraîchissement du token d'accès:", error);
        return false;
      }
    } else {
      return new Promise((resolve) => {
        subscribeRefreshToken((accessToken) => {
          resolve(accessToken);
        });
      });
    }
  };

  api.interceptors.response.use(async (response) => {
    return response;
  }, async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } else {
        setIsLoggedIn(false);
        setUser(null);
        history.push('/login');
      }
    }
    return Promise.reject(error);
  });

  const logIn = async (email, password) => {
    try {
      const response = await loginUser({ email, password });
      // Check if response itself is the `data` object
      if (response.user) {
        setIsLoggedIn(true);
        setUser(response.user);
        localStorage.setItem('isProfessional', response.user.isProfessional);
        return response;
      }
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
      alert("Login error.");
      return false;
    }
  };
  

  const logInWithOAuth = useCallback(async (userData) => {
    setIsLoggedIn(true);
    setUser(userData);

    try {
      const fullUserData = await getUserInfo();
      if (fullUserData.isOnBoarded !== undefined && !fullUserData.isOnBoarded) {
        history.push('/stripe-account-connect');
      } else {
        history.push('/mon-compte');
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'utilisateur après OAuth:", error);
      // Gérer l'erreur éventuellement en redirigeant l'utilisateur vers une page d'erreur ou en affichant un message
    }

  }, [setIsLoggedIn, setUser, history]);

  const logOut = async () => {
    try {
      await logoutUser(); // Use the API function
      setIsLoggedIn(false);
      setUser(null);
      localStorage.removeItem('isProfessional');
      
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    const checkUserLogin = async () => {
      try {
        const response = await checkLoginStatus(); // Use the API function
        if (response) {
          setIsLoggedIn(true);
          setUser(response.user);
        }
      } catch (error) {
        setIsLoggedIn(false);
      }
    };

    checkUserLogin();
  }, []);

  const authContextValue = {
    isLoggedIn,
    logIn,
    logInWithOAuth,
    logOut,
    user,
    shouldShowReminderModal,
    setShouldShowReminderModal,
    isProfessional: user?.isProfessional ?? localStorage.getItem('isProfessional') === 'true',
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};