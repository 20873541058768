import React, { useState, useEffect } from "react";
import Dropzone from 'react-dropzone';
import { sendContactEmail } from "../api/api";
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from '../Contact.module.css';

const detectScale = () => {
  const scale = window.devicePixelRatio || 1;
  if (scale > 1) {
    document.body.classList.add('scaled');
  }
};

const ContactPage = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [isSentFailed, setIsSentFailed] = useState(false);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    detectScale();
  }, []);

  const validateEmail = (email) => {
    const re = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    sujet: "",
    texte: "",
    fichier: null,
  });

  const resetForm = () => {
    setFormData({
      nom: "",
      prenom: "",
      email: "",
      sujet: "",
      texte: "",
      fichier: null,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'email') {
      if (!validateEmail(value)) {
        setEmailError('Veuillez saisir un email valide!');
      } else {
        setEmailError(''); // Efface l'erreur si l'email est valide
      }
    }
  };

  const handleFileDrop = (acceptedFiles) => {
    setFormData({ ...formData, fichier: acceptedFiles[0] });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await sendContactEmail(formData);
      if (response.status === 200) {
        // Gère la réponse réussie, par exemple, affiche un message de succès
        setSuccessMessage('Message envoyé avec succès.');
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
        setIsSent(true);
        resetForm();
      }
    } catch (error) {
      // Gère les erreurs de réponse
      setError(`Erreur lors de l'envoi du message.`);
      setTimeout(() => {
        setError(null);
      }, 3000);
      setIsSentFailed(true);
    }
  };


  return (
    <div className={styles.container}>
      <Helmet>
        <title>Contact - Your Guests</title>
        <meta name="description" content="N'hésitez pas à nous contacter" />
        <meta name="keywords" content="contact,your guests,your guests,invités,mariage,réservation,réserver,mariage,dormir,guests,lieu"/>
      </Helmet>
      <h1 className={styles.contactTitle}>Dites nous tout</h1>

      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="text"
          name="nom"
          value={formData.nom}
          onChange={handleInputChange}
          className={styles.input}
          placeholder="Nom"
        />
        <input
          type="text"
          name="prenom"
          value={formData.prenom}
          onChange={handleInputChange}
          className={styles.input}
          placeholder="Prénom"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          rules={[{ required: true, message: "Veuillez saisir votre email!" }]}
          onChange={handleInputChange}
          className={styles.input}
          placeholder="Email"
        />
        {emailError && <p className={styles.error}>{emailError}</p>}
        <input
          type="text"
          name="sujet"
          value={formData.sujet}
          onChange={handleInputChange}
          className={styles.input}
          placeholder="Précisez l'objet de votre demande"
        />
        <textarea
          name="texte"
          value={formData.texte}
          onChange={handleInputChange}
          className={styles.textarea}
          rows="5"
          placeholder="Saisissez votre texte"
        />
        <Dropzone onDrop={handleFileDrop}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className={styles.dropzone}>
                <input {...getInputProps()} />
                <p>Déposez votre fichier ici, ou cliquez pour sélectionner le fichier</p>
              </div>
            </section>
          )}
        </Dropzone>
        {error && <p className={styles.error}>{error}</p>}
        {successMessage && <p className={styles.success}>{successMessage}</p>}
        <div>
          <button onClick={handleSubmit} className={styles.buttonSoumettre}>
            Soumettre
          </button>
          {isSent && <FontAwesomeIcon icon={faCheckCircle} size="xl" className="ml-2 text-green-500" />}
          {isSentFailed && <FontAwesomeIcon icon={faXmark} size="xl" className="ml-2 text-red-500" />}
        </div>
      </form>
    </div>
  );
};

export default ContactPage;