import React from 'react';
import { useHistory } from 'react-router-dom'; // Importez useHistory
import ProjectCard from './ProjectCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-regular-svg-icons';
import PasdeProjet from '../../assets/Images/Pas_de_Projet.jpg';
import styles from '../../Dashboard.module.css';

const Dashboard = ({ projects, resetSidebar, sidebarReduced }) => {
  const history = useHistory(); // Utilisez le hook useHistory
  const sidebarWidth = sidebarReduced ? "50px" : "200px";

  const goToCreateProject = () => {
    history.push('/creer'); // Redirige vers /creer
  };

  return (
    <div>
      {sidebarReduced &&
        <button
          onClick={resetSidebar}
          className={styles.returnButton}
          >
          <FontAwesomeIcon icon={faCircleLeft} size="2x" />
          <title>Retour</title>
        </button>
      }
      {projects.length > 0 ? (
        projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))
      ) : (
        <div className="flex flex-col items-center justify-center">
          <img src={PasdeProjet} alt="Pas de projet" className="h-64 w-auto" />
          <div className="flex flex-col items-center justify-center">
            <p className="text-center">
              Vous n'avez pas encore créé de projet. <br />
              N'hésitez pas à créer le votre en cliquant sur le bouton ci-dessous !
            </p>
          </div>
          <button
            className={styles.buttonCreerProjet}
            onClick={goToCreateProject}>
            Créer un projet
          </button>
        </div>
  )
}
    </div >
  );
};

export default Dashboard;
