import React, { useEffect, useRef } from "react";
import '../ModalGuests.css';

const ModalGuests = ({ isOpen, onClose, children }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-container" ref={modalRef}>
            <div className="modal-content">
                {children}
                <button
                    onClick={onClose}
                    className="modal-button"
                >
                    Fermer
                </button>
            </div>
        </div>
    );


};

export default ModalGuests;
