import React, { useState, useEffect } from 'react';
import { fetchVenueMedia } from './ReservationHelpers';
import { urlFor } from './imageUrlBuilder';
import VirtualTourModal from './VirtualTourModal';
import ImageModal from './ImageModal';
import VirtualTour from '../assets/Images/3D_Tour.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import styles from '../GalleryResa.module.css';

const Gallery = ({ userId, projectId }) => {
    const [virtualTourUrl, setVirtualTourUrl] = useState('');
    const [galleryImages, setGalleryImages] = useState([]);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const [isVirtualTourOpen, setIsVirtualTourOpen] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchVenueMedia(userId, projectId);
            if (data.length > 0) {
                setVirtualTourUrl(data[0].virtualTourUrl);
                const imagesWithVirtualTour = [{ virtualTour: true }, ...data[0].gallerie];
                setGalleryImages(imagesWithVirtualTour);
            }
        };

        fetchData();
    }, [userId, projectId]);

    const scrollImages = (direction) => {
        const container = document.querySelector(`.${styles.galleryImages}`);
        const scrollAmount = 200; // La distance de défilement pour chaque clic

        if (direction === 'left') {
            container.scrollLeft -= scrollAmount;
        } else if (direction === 'right') {
            container.scrollLeft += scrollAmount;
        }
    }

    const handleImageClick = (image) => {
        if (image.virtualTour) {
            setIsVirtualTourOpen(true);
        } else {
            // Ici, vous devez extraire l'URL de l'image et la stocker dans l'état
            const imageUrl = urlFor(image).auto('format').width(800).url();
            setSelectedImageUrl(imageUrl);
            setIsImageModalOpen(true);
        }
    };

    return (
        <div className={styles.galleryContainer}>
            <FontAwesomeIcon icon={faCaretLeft} style={{color:'#5465ff'}} className={styles.scrollArrow} onClick={() => scrollImages('left')} />
            <div className={styles.galleryImages}>
                {galleryImages.map((image, index) => (
                    image.virtualTour ? (
                        <img
                            key="virtual-tour-placeholder"
                            src={VirtualTour} // Chemin vers une image représentative de la visite virtuelle
                            alt="Visite Virtuelle"
                            className={styles.galleryImage}
                            onClick={() => handleImageClick({ virtualTour: true })}
                        />

                    ) : (
                        <img
                            key={index}
                            src={urlFor(image).auto('format').width(800).url()} // Utiliser une largeur plus grande pour de meilleures images
                            alt={`Image ${index + 1}`}
                            className={styles.galleryImage}
                            onClick={() => handleImageClick(image)}
                        />
                    )
                ))}
            </div>
            <FontAwesomeIcon icon={faCaretRight} style={{color:'#5465ff'}} className={styles.scrollArrow} onClick={() => scrollImages('right')} />
            {isVirtualTourOpen && (
                <VirtualTourModal
                    virtualTourUrl={virtualTourUrl}
                    onClose={() => setIsVirtualTourOpen(false)}
                />
            )}
            {isImageModalOpen && (
                <ImageModal
                    imageUrl={selectedImageUrl}
                    onClose={() => setIsImageModalOpen(false)}
                />
            )}
        </div>
    );
};

export default Gallery;