import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Dashboard from '../components/Dashboard/Dashboard';
import Profile from '../components/Profile/Profile';
import Referral from '../components/Referral/Referral';
import { AuthContext } from '../contexts/AuthContext';
import { getUserProjects, getCurrentUser } from '../api/api';
import styles from '../MonCompte.module.css';
import Modal from '../components/Modal';

export default function MonCompte() {
  const { isLoggedIn, user, shouldShowReminderModal, setShouldShowReminderModal } = useContext(AuthContext);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(null);
  const [projects, setProjects] = useState([]);
  
  const handleGoToProjects = () => {
    setActiveTab('dashboard');
    setSidebarVisible(false);  // Ajoutez cette ligne
    setSidebarReduced(true);
    setShouldShowReminderModal(false);
  }

  useEffect(() => {
    const fetchUserAndProjects = async () => {
      try {
        const userResponse = await getCurrentUser();
        const userId = userResponse.data._id;

        const projectResponse = await getUserProjects(userId);

        if (Array.isArray(projectResponse.data)) {
          setProjects(projectResponse.data);
          setActiveTab('dashboard');  // Ouvre la card "Mes Projets"
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données de projets:", error);
      }
    };

    fetchUserAndProjects();
  }, []);


  useEffect(() => {
    // Vérifier la présence du jeton dans les cookies
    if (!isLoggedIn) {
      history.push('/login-register');
    }
  }, [isLoggedIn, history]);

  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [sidebarReduced, setSidebarReduced] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSidebarVisible(false);  // Cache la sidebar lorsqu'une card est cliquée
    setSidebarReduced(true);
  };

  const resetSidebar = () => {
    setSidebarVisible(true); // Réaffiche la sidebar
    setSidebarReduced(false);
    setActiveTab(null);
  };

  return (
    <>
      {shouldShowReminderModal &&
        (<Modal closeModal={() => setShouldShowReminderModal(false)}>
            <p>Vous avez des projets en cours ou non commencés.</p>
            <button
              className={styles.modalButton}
              onClick={handleGoToProjects}
            >
              Y aller
            </button>
        </Modal>)
      }

      <div className={`${styles.monCompte} flex justify-center items-center ${isSidebarVisible ? '' : 'expand'}`}>
        <div className={`${styles.sidebar}  ${isSidebarVisible ? '' : styles.sidebarShrink} ${sidebarReduced ? styles.sidebarReduced : ''}`}>
          <div
            className={`${styles.card} ${styles.dashboardCard} cursor-pointer ${activeTab === 'dashboard' ? 'active' : ''} ${sidebarReduced ? styles.reducedCard : ''}`}
            onClick={() => handleTabClick('dashboard')}
          >
            <div className={styles.cardVeil}></div>
            <div className={styles.cardText}>Mes Projets</div>
          </div>
          <div
            className={`${styles.card} ${styles.profileCard} cursor-pointer ${activeTab === 'profile' ? 'active' : ''} ${sidebarReduced ? styles.reducedCard : ''}`}  // Changé ici
            onClick={() => handleTabClick('profile')}
          >
            <div className={styles.cardVeil}></div>
            <div className={styles.cardText}>Mon Profil</div>
          </div>
          <div
            className={`${styles.card} ${styles.referralCard} cursor-pointer ${activeTab === 'referral' ? 'active' : ''} ${sidebarReduced ? styles.reducedCard : ''}`}  // Changé ici
            onClick={() => handleTabClick('referral')}
          >
            <div className={styles.cardVeil}></div>
            <div className={styles.cardText}>Mes Parrainages</div>
          </div>
        </div>
        {/* Ligne verticale */}
        {!isSidebarVisible && <div className={styles.verticalLine}></div>}

        <div>
          {activeTab === 'dashboard' && isSidebarVisible === false && <Dashboard projects={projects} resetSidebar={resetSidebar} sidebarReduced={sidebarReduced} />}
          {activeTab === 'profile' && isSidebarVisible === false && <Profile user={user} resetSidebar={resetSidebar} sidebarReduced={sidebarReduced} />}
          {activeTab === 'referral' && isSidebarVisible === false && <Referral resetSidebar={resetSidebar} sidebarReduced={sidebarReduced} />}
        </div>
      </div>
    </>
  );
}