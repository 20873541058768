import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import EmailEditorModal from './EmailEditor/EmailEditorModal';
import EmailHTMLDesign from '../assets/Files/ReactEmailEditor/Templates/EmailHTMLDesign.json';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
import { fetchSanityVenueInfo, fetchProjectInfo, fetchDatesInfo } from './CustomMailHelpers';
import { getSleepInVenue, getSleepInBnB, getCurrentUser } from '../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare, faPlus, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import ModalGuests from './ModalGuests';
import styles from '../CustomMail.module.css'
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/blotFormatter', BlotFormatter);

function replaceTemplatePlaceholders(template, replacements) {
  let result = template;
  if (replacements) {
    Object.keys(replacements).forEach(key => {
      const regex = new RegExp(`\\[${key}\\]`, 'g');
      result = result.replace(regex, replacements[key]);
    });
  }
  return result;
};

const CustomMail = () => {
  const [messageContent, setMessageContent] = useState('');
  const [sanityData, setSanityData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [currentEditor, setCurrentEditor] = useState('venue');
  const [showVenueEditor, setShowVenueEditor] = useState(false);
  const [showBnBEditor, setShowBnBEditor] = useState(false);
  const { projectId } = useParams();
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [venueContent, setVenueContent] = useState('');
  const [bnbContent, setBnbContent] = useState('');
  const [venueDesignHtml, setVenueDesignHtml] = useState('');
  const [bnbDesignHtml, setBnbDesignHtml] = useState('');
  const [dataForReplacement, setDataForReplacement] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const quillRef = useRef(null);

  useEffect(() => {
    fetch('/venueDesign.html')
      .then(response => response.text())
      .then(htmlContent => {
        setVenueDesignHtml(htmlContent);
      })
      .catch(error => console.error('Erreur lors du chargement du fichier HTML', error));

    fetch('/bnbDesign.html')
      .then(response => response.text())
      .then(htmlContent => {
        setBnbDesignHtml(htmlContent);
      })
      .catch(error => console.error('Erreur lors du chargement du fichier HTML', error));
  }, []);

  const handleCloseEditor = () => {
    setShowEditorModal(false);
  };
  const toggleEditor = () => {
    setCurrentEditor(currentEditor === 'venue' ? 'bnb' : 'venue');
  };
  const handleContentChange = (messageContent) => {
    setMessageContent(messageContent);
  };

  const toolbarOptions = {
    toolbar: {
      container: [
        [{ 'size': ['small', false, 'large', 'huge'] }],  // taille de la police
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],  // formats de police
        [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }],  // couleurs
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]     // listes
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // indentation
        [{ 'align': [] }],                                // alignement
        ['link', 'image', 'video']                        // liens, images, vidéos
      ]
    }
  };
  const formatsQuill = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'align',
    'link', 'image', 'background', 'color', 'emoji'
  ];

  const modules = {
    toolbar: toolbarOptions.toolbar,
    // handlers: toolbarOptions.handlers,
    blotFormatter: {},
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await getCurrentUser();
        const userId = userResponse.data._id;
        const sanityData = await fetchSanityVenueInfo(userId, projectId);
        const projectData = await fetchProjectInfo(userId, projectId);
        const datesData = await fetchDatesInfo(projectId);

        if (sanityData && projectData) {
          const hotels = projectData.venue?.hotelsAround.map(hotel => ({
            ...hotel,
            address: hotel.address !== "Adresse non disponible" ? hotel.address : null,
            website: hotel.website !== "Site web non disponible" ? hotel.website : null,
            description: hotel.description !== "Aucune description disponible" ? hotel.description : null
          }))

          if (datesData.startDate && datesData.endDate) {
            const adjustedStartDate = moment.utc(datesData.startDate).add(2, 'hours');
            const formattedStartDate = adjustedStartDate.format('DD/MM/YYYY HH:mm');
            const adjustedEndDate = moment.utc(datesData.endDate).add(2, 'hours');
            const formattedEndDate = adjustedEndDate.format('DD/MM/YYYY HH:mm');

            await fetchProjectData(sanityData, projectData, hotels, formattedStartDate, formattedEndDate);
          }

        } else {
          console.log('Sanity ou mongo est incorrect ou vide');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        // Gérer l'erreur ici
      }
    };

    fetchData();
  }, [projectId, currentEditor]);

  const fetchProjectData = async (sanityData, projectData, hotels, formattedStartDate, formattedEndDate) => {
    // Info Projet
    const projectDescription = projectData.description;
    const hotelsListHtml = hotels.map(hotel => {
      let hotelDetails = [hotel.name];
      if (hotel.address) hotelDetails.push(hotel.address);
      if (hotel.website) hotelDetails.push(hotel.website);
      if (hotel.description) hotelDetails.push(hotel.description);

      return `<li>${hotelDetails.join(' - ')}</li>`;
    }).join('');
    const infosHotels = hotelsListHtml;

    // Info Sanity
    const Lieu = sanityData[0]?.name;
    const prepositionVenue = getPrepositionVenue(Lieu);
    const prepositionBnb = getPrepositionBnb(Lieu);
    const adresseLieu = sanityData[0]?.address;
    const website = sanityData[0]?.website;
    const premiereImageUrl = sanityData[0]?.premiereImage?.asset?.url;
    const deuxiemeImageUrl = sanityData[0]?.deuxièmeImage?.asset?.url;
    const troisiemeImageUrl = sanityData[0]?.troisièmeImage?.asset?.url;
    const quatriemeImageUrl = sanityData[0]?.quatrièmeImage?.asset?.url;

    /* Mise en forme description du lieu */
    const container = document.createElement('div');
    const quill = new Quill(container);
    quill.setText(sanityData[0]?.description || '');
    const htmlDescription = quill.root.innerHTML;
    container.remove();

    function convertMixedTextToLists(text) {
      const segments = text.split('•');
      let formattedText = segments[0];
      let listItems = '';

      for (let i = 1; i < segments.length; i++) {
        listItems += `<li>${segments[i].trim()}</li>`;
      }
      if (listItems) {
        formattedText += `<ul>${listItems}</ul>`;
      }
      return formattedText;
    }
    const text = htmlDescription;
    const formattedDescription = convertMixedTextToLists(text);
    /* ********************************** */

    /* Gestion des replaces */
    setDataForReplacement({
      NomProjet: projectDescription,
      PrepositionV: prepositionVenue,
      PrepositionB: prepositionBnb,
      NomLieu: Lieu,
      WebsiteLieu: website,
      AdresseLieu: adresseLieu,
      DescriptionLieu: formattedDescription,
      DateDebut: formattedStartDate,
      DateFin: formattedEndDate,
      Image1: premiereImageUrl,
      Image2: deuxiemeImageUrl,
      Image3: troisiemeImageUrl,
      Image4: quatriemeImageUrl,
      HotelsData: infosHotels
    });
  };

  useEffect(() => {
    // Une fois dataForReplacement mis à jour
    if (dataForReplacement && Object.keys(dataForReplacement).length > 0) {
      const updatedVenueContent = replaceTemplatePlaceholders(venueTemplate, dataForReplacement);
      const updatedBnBContent = replaceTemplatePlaceholders(bnbTemplate, dataForReplacement);
      const updatedVenueDesignHtml = replaceTemplatePlaceholders(venueDesignHtml, dataForReplacement);
      const updatedBnbDesignHtml = replaceTemplatePlaceholders(bnbDesignHtml, dataForReplacement);

      if (currentEditor === 'venue') {
        setVenueContent(updatedVenueDesignHtml);
        setMessageContent(updatedVenueContent)
      } else if (currentEditor === 'bnb') {
        setBnbContent(updatedBnbDesignHtml);
        setMessageContent(updatedBnBContent);
      }
    }
  }, [dataForReplacement]);

  const getPrepositionVenue = (Lieu) => {
    const lowerCaseLieu = Lieu.toLowerCase();

    if (["château", "domaine", "gîte", "manoir", "chantier", "chalet", "pavillon", "moulin", "centre", "ranch", "logis", "parc", "clos", "prieuré", "hameau", "carré"].some(word => lowerCaseLieu.startsWith(word))) {
      return "au";
    } else if (["salle", "villa", "ferme", "dîme", "dime", "bergerie", "vue", "source", "grange", "maison"].some(word => lowerCaseLieu.startsWith(word))) {
      return "à la";
    } else if (["espace", "hôtel", "atelier", "ermitage", "orangerie", "airial", "ayrial", "éyriau", "arcade", "abbaye", "étang", "etang"].some(word => lowerCaseLieu.startsWith(word))) {
      return "à l'";
    } else if (["jardins", "étangs", "etangs"].some(word => lowerCaseLieu.startsWith(word))) {
      return "aux";
    } else {
      return "au";
    }
  };

  const getPrepositionBnb = (Lieu) => {
    const lowerCaseLieu = Lieu.toLowerCase();

    if (["château", "domaine", "gîte", "manoir", "chantier", "chalet", "pavillon", "moulin", "centre"].some(word => lowerCaseLieu.startsWith(word))) {
      return "du";
    } else if (["salle", "villa", "ferme", "dîme", "dime", "bergerie"].some(word => lowerCaseLieu.startsWith(word))) {
      return "de la";
    } else if (["espace", "hôtel", "atelier", "ermitage", "orangerie"].some(word => lowerCaseLieu.startsWith(word))) {
      return "de l'";
    } else {
      return "du";
    }
  };

  const formatHtmlForJson = (html) => {
    return html
      .replace(/\n/g, '\\n')  // Remplace les sauts de ligne par \n
      .replace(/"/g, '\\"');  // Échappe les guillemets
  };


  const handleConfirmMessage = () => {
    const quillHtmlContent = quillRef.current ? quillRef.current.getEditor().root.innerHTML : '';

    // Mise à jour du contenu
    const replacements = {
      ...dataForReplacement,
      Message: quillHtmlContent
    };

    // Effectuer les remplacements dans les templates HTML
    const updatedVenueDesignHtml = replaceTemplatePlaceholders(venueDesignHtml, replacements);
    const formattedQuillHtmlVenueContent = formatHtmlForJson(updatedVenueDesignHtml);
    const updatedBnbDesignHtml = replaceTemplatePlaceholders(bnbDesignHtml, replacements);
    const formattedQuillHtmlBnbContent = formatHtmlForJson(updatedBnbDesignHtml);

    let emailDesignVenueJsonString = JSON.stringify(EmailHTMLDesign);
    emailDesignVenueJsonString = emailDesignVenueJsonString.replace(/\[EmailHTML]/g, formattedQuillHtmlVenueContent);

    let emailDesignBnbJsonString = JSON.stringify(EmailHTMLDesign);
    emailDesignBnbJsonString = emailDesignBnbJsonString.replace(/\[EmailHTML]/g, formattedQuillHtmlBnbContent);

    // Reconvertir la string en JSON
    const emailDesignVenueJson = JSON.parse(emailDesignVenueJsonString);
    const emailDesignBnbJson = JSON.parse(emailDesignBnbJsonString);

    setVenueContent(emailDesignVenueJson);
    setBnbContent(emailDesignBnbJson);
    setShowEditorModal(true);
  };

  const venueTemplate = `
       <p style=\"line-height: 140%;\">Bonjour [Prenom] 👋,
       </p><br /><p style=\"line-height: 140%;\">
       </p><p style=\"line-height: 140%;\">C'est bientôt le grand jour pour vos deux futurs mariés préférés !</p><p style=\"line-height: 140%;\">

       </p><p style=\"line-height: 140%;\">Si vous recevez ce mail, c'est parce que nos amoureux ont sollicité les services de <span style=\"color: #5465ff; line-height: 19.6px;\"><a rel=\"noopener\" href=\"https://www.your-guests.com\" target=\"_blank\" data-u-link-value=\"eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Imh0dHBzOi8vd3d3LnlvdXItZ3Vlc3RzLmNvbSIsInRhcmdldCI6Il9ibGFuayJ9fQ==\"><strong>Your-Guests</strong></a><span style=\"color: #000000; line-height: 19.6px;\"> pour s'occuper de la partie couchage de leur mariage.<br /></span></span></p>\n<p style=\"line-height: 140%;\"></p>
       
       <p style=\"line-height: 140%;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\">Vous êtes donc invités à réserver votre couchage [PrepositionV] <span style=\"color: #5465ff; line-height: 19.6px;\"><strong><span style=\"color: #5465ff; line-height: 19.6px;\">[NomLieu]</span></strong></span> où se tiendra la soirée du [NomProjet] en cliquant sur le bouton ci-dessous.</span></span></p>\n<p style=\"line-height: 140%;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\">Ce service vous assure que la ou les chambres réservées vous sont bien attribuées.</span></span></p>
       
       <p style=\"line-height: 140%;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\"><br />Merci de respecter au mieux et selon vos possibilités les dates d'arrivée et de départ afin de prendre le temps de vous installer, profiter sans stress et être à temps aux évènements qui ont été préparés pour vous :</span></span></p>\n<ul>\n<li style=\"line-height: 19.6px;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\">Arrivée (au plus tôt) : <span style=\"color: #5465ff; line-height: 19.6px;\"><strong>[DateDebut]</strong></span></span></span></li>\n<li style=\"line-height: 19.6px;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\">Départ (au plus tard) : <span style=\"color: #5465ff; line-height: 19.6px;\"><strong>[DateFin]</strong></span></span></span></li>\n</ul>
       
       <p style=\"line-height: 140%;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\"><br />Ne perdez pas une seconde et réservez votre couchage dès à présent ! 👇</span></span></p>\n<p style=\"line-height: 140%;\"></p>
       
       <p style=\"line-height: 140%;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\"><br />L'équipe <span style=\"color: #5465ff; line-height: 19.6px;\"><strong>Y<span style=\"color: #5465ff; line-height: 19.6px;\">our-Guests</span></strong></span></span></span></p>
  `;

  const bnbTemplate = `
        <p style=\"line-height: 140%;\">Bonjour [Prenom] 👋,
        </p><br /><p style=\"line-height: 140%;\">
        </p><p style=\"line-height: 140%;\">C'est bientôt le grand jour pour vos deux futurs mariés préférés !</p>\n<p style=\"line-height: 140%;\">

        </p><p style=\"line-height: 140%;\">Si vous recevez ce mail, c'est parce que nos amoureux ont sollicité les services de <span style=\"color: #5465ff; line-height: 19.6px;\"><a rel=\"noopener\" href=\"https://www.your-guests.com\" target=\"_blank\" data-u-link-value=\"eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Imh0dHBzOi8vd3d3LnlvdXItZ3Vlc3RzLmNvbSIsInRhcmdldCI6Il9ibGFuayJ9fQ==\"><strong>Your-Guests</strong></a><span style=\"color: #000000; line-height: 19.6px;\"> pour s'occuper de la partie couchage de leur mariage.<br />
        
        </span></span></p><p style=\"line-height: 140%;\"></p><p style=\"line-height: 140%;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\">Vous trouverez ci-dessous la liste des hôtels et hébergements autour </span></span>[PrepositionB] <span style=\"color: #5465ff; line-height: 19.6px;\"><strong>[NomLieu]</strong></span> <span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\">où se dérouleront les festivités du </span></span>[NomProjet] :<span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\"></span></span></p>
    
        <div>
          <ul>
            <li style="line-height: 19.6px;">[HotelsData]</li>
          </ul>
        </div>

        <p style=\"line-height: 140%;\"></p><p style=\"line-height: 140%;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\"><br />Ne perdez pas une seconde et réservez votre couchage dès à présent ! 
        
        <br /></span></span></p>\n<p style=\"line-height: 140%;\"></p><br />
        
        <p style=\"line-height: 140%;\"><span style=\"color: #5465ff; line-height: 19.6px;\"><span style=\"color: #000000; line-height: 19.6px;\">L'équipe <span style=\"color: #5465ff; line-height: 19.6px;\"><strong>Y<span style=\"color: #5465ff; line-height: 19.6px;\">our-Guests</span></strong></span></span></span></p>
  `;

  useEffect(() => {
    if (sanityData && projectData && hotels.length > 0) {
      fetchProjectData(sanityData, projectData, hotels);
    }
  }, [currentEditor, sanityData, projectData, hotels]);

  /**************************************************************************
   * ************************************************************************
   * ******************** TROUVE SI LIEU OU HOTEL/BNB ***********************
   * ************************************************************************
   * ***********************************************************************/
  useEffect(() => {
    const checkGuestsLocation = async () => {
      const venueResponse = await getSleepInVenue(projectId);
      const bnbResponse = await getSleepInBnB(projectId);

      if (venueResponse.data.exists) {
        setShowVenueEditor(true);
        if (!bnbResponse.data.exists || currentEditor === 'venue') {
          setMessageContent(venueTemplate);
        }
      }

      if (bnbResponse.data.exists) {
        setShowBnBEditor(true);
        if (!venueResponse.data.exists || currentEditor === 'bnb') {
          setMessageContent(bnbTemplate);
        }
      }
    };
    checkGuestsLocation();
  }, [projectId, currentEditor]);

  return (
    <section id="CustomMail" className={styles.CustomMailSection}>
      <div className={styles.editorContainer}>
        <button className={styles.toggleButton} onClick={toggleEditor}>&lt;</button>
        {currentEditor === 'venue' ? (
          <div className={styles.reactQuillContainer}>
            <div className={styles.titleWithButton}>
              <button onClick={() => setIsModalOpen(true)} className={styles.infoButton}>
                <FontAwesomeIcon icon={faCircleInfo} style={{ color: "#3324bb" }} />
              </button>
              <h3 className={styles.QuillEditorTitre}>
                Lieu du mariage
              </h3>
            </div>
            <p className={styles.QuillEditorDescription}>
              Personnalisez votre message ! <br />
              Nous vous conseillons de laisser les informations quant au lieu, dates & horaires d'arrivée et départ.
              <br />
            </p>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={messageContent}
              onChange={handleContentChange}
              modules={modules}
              formats={formatsQuill}
              placeholder={'Écrivez quelque chose...'}
              className='custom-quill-container'
            />
          </div>
        ) : null}
        {currentEditor === 'bnb' ? (
          <div className={styles.reactQuillContainer}>
            <div className={styles.titleWithButton}>
              <button onClick={() => setIsModalOpen(true)} className={styles.infoButton}>
                <FontAwesomeIcon icon={faCircleInfo} style={{ color: "#3324bb" }} />
              </button>
              <h3 className={styles.QuillEditorTitre}>
                Hôtels et BnB
              </h3>
            </div>
            <p className={styles.QuillEditorDescription}>
              Modifiez la liste des hôtels comme vous le désirez : <br />
              <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: "#5465ff", }} className={styles.flipIcon} /> Supprimez le/les hôtels si trop loin ou ne conviennent pas. <br />
              <FontAwesomeIcon icon={faPenToSquare} size="xs" style={{ color: "#5465ff", }} className={styles.flipIcon} /> Compléter ou corriger si une information erronée s'y est glissée.<br />
              <FontAwesomeIcon icon={faPlus} size="xs" style={{ color: "#5465ff", }} className={styles.flipIcon} /> Ajouter si un ou des hébergements manquent dans la liste.
            </p>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={messageContent}
              onChange={handleContentChange}
              modules={modules}
              formats={formatsQuill}
              placeholder={'Écrivez quelque chose...'}
              className='custom-quill-container'
            />
          </div>

        ) : null}
        <button
          className={styles.toggleButton}
          onClick={toggleEditor}
        >
          &gt;
        </button>
      </div>

      <div>
        <button
          onClick={handleConfirmMessage}
          className={styles.confirmButtonContainer}
        >
          Confirmer le message
        </button>
        {errorMessage && <p className="text-red-500 mt-10">{errorMessage}</p>}
        {successMessage && <p className="text-green-500 mt-10">{successMessage}</p>}
        {showEditorModal &&
          <EmailEditorModal
            emailContent={currentEditor === 'venue' ? venueContent : bnbContent}
            onClose={handleCloseEditor}
            editorType={currentEditor}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />}
      </div>
      <ModalGuests
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <p>
          Dernière étape, et pas des moindres ! <br />
          Personnalisez, si vous le souhaitez, le message de l'email qui sera envoyé à vos invités.<br />
          Si vous avez déplacé certains invités dans la zone Hôtel et BnB, vous pourrez aussi personnaliser leur message.
          <br /><br />
        </p>
      </ModalGuests>

    </section>
  );
};

export default CustomMail;