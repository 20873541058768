import React, { useState, useEffect } from "react";
import { ResponsiveWaffle } from "@nivo/waffle";
import styles from '../../../WaffleChart.module.css'

const InvitedCategoryWaffle = ({ data }) => {
    const [legendConfig, setLegendConfig] = useState({
        anchor: 'left',
        direction: 'column',
        justify: false,
        translateX: -50,
        translateY: -50,
        itemsSpacing: 4,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'right-to-left',
        itemOpacity: 1,
        itemTextColor: '#777',
        symbolSize: 14,
        effects: [
            {
                on: 'hover',
                style: {
                    itemTextColor: '#000',
                    itemBackground: '#f7fafb'
                }
            }
        ]
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1920 ) {
                setLegendConfig({
                    anchor: 'left',
                    direction: 'column',
                    justify: false,
                    translateX: -115,
                    translateY: -50,
                    itemsSpacing: 4,
                    itemWidth: 115,
                    itemHeight: 20,
                    itemDirection: 'right-to-left',
                    itemOpacity: 1,
                    itemTextColor: '#777',
                    symbolSize: 14,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000',
                                itemBackground: '#f7fafb'
                            }
                        }
                    ]
                });
            } else {
                setLegendConfig({
                    anchor: 'left',
                    direction: 'column',
                    justify: false,
                    translateX: -50,
                    translateY: -50,
                    itemsSpacing: 4,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'right-to-left',
                    itemOpacity: 1,
                    itemTextColor: '#777',
                    symbolSize: 14,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000',
                                itemBackground: '#f7fafb'
                            }
                        }
                    ]
                });
            }
        };

        window.addEventListener('resize', handleResize);

        // Appel initial
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <div className={styles.waffleChartContainer}>
            <h2 className={styles.title}>Répartition de vos invités</h2>
            <ResponsiveWaffle
                data={data}
                total={100}
                rows={14}
                columns={12}
                padding={1}
                valueFormat=" >-.0f"
                margin={{ top: 10, right: 100, bottom: 60, left: 80 }}
                colors={{ scheme: 'purple_orange' }} //nivo
                borderRadius={3}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.3
                        ]
                    ]
                }}
                motionStagger={2}
                legends={[legendConfig]}
            />
        </div>
    );
};

export default InvitedCategoryWaffle;