import React, { useState, useEffect } from 'react';
import ScrollToTop from '../ScrollToTop';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import StripeDashboard from '../StripeDashboard/StripeDashboard';
import StripeBalance from '../StripeBalance/StripeBalance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { updateUser, getUserInfo, getLoginLink } from '../../api/api';
import { Circles } from 'react-loader-spinner';
import Modal from '../Modal';
import styles from '../../Profile.module.css'

const Profile = ({ user, resetSidebar, sidebarReduced }) => {
  const [updatedUser, setUpdatedUser] = useState(user || {});
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const sidebarWidth = sidebarReduced ? "50px" : "200px";
  const [birthDate, setBirthDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 18)));
  const [stripeAccountID, setStripeAccountID] = useState(null)
  const [isLoading, setIsLoading] = useState(true);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsModified(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (['street', 'city', 'postalCode', 'country', 'additionalInfo'].includes(name)) {
      // if (name in updatedUser.address) { // Vérifie si le champ est dans l'objet address
      setUpdatedUser({
        ...updatedUser,
        address: {
          ...updatedUser.address,
          [name]: value, // Met à jour uniquement la propriété de l'adresse qui a changé
        },
      });
    } else {
      setUpdatedUser({
        ...updatedUser,
        [name]: value, // Met à jour les autres champs normalement
      });
    }
    setIsModified(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isPasswordFieldsFilled = password || confirmPassword;

    if (isPasswordFieldsFilled && password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }

    const payload = updatedUser ? { ...user, ...updatedUser, birthDate: birthDate } : { ...user };

    if (updatedUser && !updatedUser.password) {
      delete payload.password;
    }

    try {
      await updateUser(payload);
      setShowModal(true);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
    }
    setIsModified(false);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await getUserInfo();
        const userData = userResponse.data;

        setUpdatedUser({
          ...userData,
          password: '',
          address: { ...userData.address }
        });

        if (userData.stripeAccountId) {
          setStripeAccountID(userData.stripeAccountId);
        } else {
          console.log("Stripe Account ID not found.");
        }

        setBirthDate(userData.birthDate ? new Date(userData.birthDate) : new Date(new Date().getFullYear() - 18));
      } catch (error) {
        console.error("Erreur lors de la récupération des données de l'utilisateur:", error);
      }
    };
    fetchData().then(() => setIsLoading(false));
  }, []);

  const closeModal = () => {
    setShowModal(false); // Fermer le modal
  };

  const handleOpenDashboard = async (e) => {
    e.preventDefault();
    if (!stripeAccountID) {
      console.error("Stripe Account ID is missing");
      return;
    }
    try {
      const url = await getLoginLink(stripeAccountID);
      if (url) {
        window.open(url, '_blank');
      } else {
        console.error("No URL received for Stripe dashboard");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'URL de connexion Stripe:", error);
    }
  };


  if (isLoading) {
    return(
      <div className={styles.loadingContainer}>
          <Circles
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-loading"
              wrapperStyle={{}}
              wrapperClass=""
              color="#5465ff"
          />
      </div>
    )
  }

  return (
    <div className={styles.sectionContainer}>
      {sidebarReduced &&
        <button
          className={styles.returnButton}
          onClick={resetSidebar}
        >
          <FontAwesomeIcon icon={faCircleLeft} size="2x" />
          <title>Retour</title>
        </button>
      }
      <div className={styles.profile}>
        <form
          className={styles.profileForm}
          onSubmit={handleSubmit}
        >
          <h3 className={styles.titleH3}>Informations personnelles</h3>
          <div className={`${styles.section} ${styles.personalInfo}`}>
            <div className={styles.radioGroup}>
              <input
                type="radio"
                id="genderMale"
                name="gender"
                value="M"
                onChange={handleInputChange}
                checked={updatedUser.gender === 'M'}
              />
              <label htmlFor="genderMale">M</label>
              <input
                type="radio"
                id="genderFemale"
                name="gender"
                value="Mme"
                onChange={handleInputChange}
                checked={updatedUser.gender === 'Mme'}
              />
              <label htmlFor="genderFemale">Mme</label>
            </div>
            <div className={styles.row}>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Nom :</label>
                <input
                  type="text"
                  name="lastName"
                  value={updatedUser.lastName || ''}
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Prénom :</label>
                <input
                  type="text"
                  name="firstName"
                  value={updatedUser.firstName || ''}
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Email :</label>
                <input
                  type="email"
                  name="email"
                  value={updatedUser.email || ''}
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Téléphone :</label>
                <input
                  type="tel"
                  name="phone"
                  value={updatedUser.phone || ''}
                  pattern="[0-9]{10}"
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Mot de passe :</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className={styles.profileInput}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Confirmer le mot de passe :</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={styles.profileInput}
                />
              </div>
            </div>
            <div>
              <label className={styles.profileLabel}>Date d'anniversaire :</label>
              <DatePicker
                locale="fr"
                selected={birthDate}
                dateFormat="dd/MM/yyyy"
                values={updatedUser.birthDate || ''}
                onChange={(date) => {
                  setBirthDate(date);
                  setIsModified(true);
                }}
                showYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={30}
                scrollableYearDropdown
                minDate={new Date("1960-01-01")}
                maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                className={styles.dateSelectorInput}
              />
            </div>
            <button
              className={styles.profileButton}
              type="submit"
              disabled={!isModified}
            >
              Enregistrer
            </button>
          </div>
          <h3 
            className={styles.titleH3}
          >
            Stripe Dashboard
          </h3>
          <div className={`${styles.section} ${styles.bankInfo}`}>
            <StripeBalance accountId={stripeAccountID} />
            <StripeDashboard onOpenDashboard={handleOpenDashboard} />
          </div>
          <h3 className={styles.titleH3}>Adresse de facturation</h3>
          <div className={`${styles.section} ${styles.personalInfo}`}>
            <div className={styles.row}>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Numéro et rue :</label>
                <input
                  type="text"
                  name="street"
                  value={updatedUser.address?.street || ''}
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Ville :</label>
                <input
                  type="text"
                  name="city"
                  value={updatedUser.address?.city || ''}
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Informations complémentaires :</label>
                <input
                  type="text"
                  name="additionalInfo"
                  value={updatedUser.address?.additionalInfo || ''}
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
              <div className={styles.inputGroup}>
                <label className={styles.profileLabel}>Code postal :</label>
                <input
                  type="text"
                  name="postalCode"
                  value={updatedUser.address?.postalCode || ''}
                  onChange={handleInputChange}
                  className={styles.profileInput}
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.profileLabel}>Pays :</label>
              <input
                type="text"
                name="country"
                value={updatedUser.address?.country || ''}
                onChange={handleInputChange}
                className={styles.profileInput}
              />
            </div>
            <button
              className={styles.profileButton}
              type="submit"
              disabled={!isModified}
            >
              Enregistrer
            </button>
          </div>

          {showModal && (
            <Modal closeModal={closeModal}>
              <p>Informations bien modifées et enregistrées.</p>
            </Modal>
          )}
        </form>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Profile;
