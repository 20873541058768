import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { updateProjectDescription, getCurrentUser, getOverallStatus } from '../../api/api';
import Guests_ProjetCree from '../../assets/Images/ProjetsCrees/Guests_ProjetCree.jpg'
import InvitesPot_ProjetCree from '../../assets/Images/ProjetsCrees/InvitesPot_ProjetCree.jpg';
import LanceBouquet_ProjetCree from '../../assets/Images/ProjetsCrees/LanceBouquet_ProjetCree.jpg';
import MamieEnfant_ProjetCree from '../../assets/Images/ProjetsCrees/MamieEnfant_ProjetCree.jpg';
import styles from '../../ProjectCard.module.css';

const ProjectCard = ({ project }) => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [newDescription, setNewDescription] = useState(project.description);
  const [currentDescription, setCurrentDescription] = useState(project.description);
  const [textColor, setTextColor] = useState('black');
  const [projectStatus, setProjectStatus] = useState('');

  useEffect(() => {
    const fetchProjectStatus = async () => {
      try {
        const userResponse = await getCurrentUser();
        const userId = userResponse.data._id;

        const response = await getOverallStatus(userId, project._id);
        if (response.status === 200 && response.data) {
          setProjectStatus(response.data.overallStatus);
        } else {
          setProjectStatus('');
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du statut global du projet:", error);
        setProjectStatus('');
      }
    };

    fetchProjectStatus();
  }, [project.userId, project._id]);

  useEffect(() => {
    const imageSrc = imageMapping[project.imagePath]; // Obtenez l'image dynamiquement
    getImageBrightness(imageSrc, (brightness) => {
      if (brightness < 128) {
        setTextColor('white'); // Si l'image est sombre, mettez le texte en blanc
      } else {
        setTextColor('black'); // Si l'image est claire, mettez le texte en noir
      }
    });
  }, [project.imagePath]);

  const goToDashboard = (projectId, e) => {
    e.stopPropagation();
    if (projectStatus === 'Terminé') {
      history.push(`/dashboard/${projectId}`);
    }
  };

  const getStatusIcon = (status) => {
    if (status === 'Terminé') return <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />;
    if (status === 'En cours') return <FontAwesomeIcon icon={faSpinner} style={{ color: 'orange' }} spin />;
    return <FontAwesomeIcon icon={faTimes} style={{ color: 'grey' }} />;
  };

  const getStepLabel = (stepKey) => {
    const stepNames = {
      'venueStatus': 'Lieu du mariage',
      'guestListStatus': 'Liste des invités',
      'customizationStatus': 'Personnalisation des réservations',
      'overallStatus': 'Statut global'
    };
    return stepNames[stepKey] || '';
  };

  const getStatusText = (status, step, project) => {

    const textMap = {
      'venueStatus': {
        'Terminé': 'Félicitations, vous avez bien sélectionné votre lieu de mariage',
        'En cours': 'Reprenez où vous en étiez.',
        'Non commencé': 'Commencez à définir votre lieu de mariage.'
      },
      'guestListStatus': {
        'Terminé': 'Félicitations, vous avez terminé de configurer votre liste d\'invités.',
        'En cours': 'Reprenez où vous en étiez.',
        'Non commencé': 'Commencez à définir votre liste d\'invités.'
      },
      'customizationStatus': {
        'Terminé': 'Félicitations, vous avez terminé de personnaliser vos réservations de couchage.',
        'En cours': 'Reprenez où vous en étiez.',
        'Non commencé': ' Commencez à personnaliser vos réservations.'
      },
      'overallStatus': {
        'Terminé': `Félicitations, vous avez terminé de configurer toutes les étapes pour votre projet ${project.description}.`,
        'En cours': 'Reprenez où vous en étiez.'
      },
    };
    return textMap[step] ? textMap[step][status] : '';
  };

  const getStatusButton = (status, step, project) => {
    const buttonMap = {
      'venueStatus': {
        'En cours': 'Poursuivre',
        'Non commencé': 'Y aller'
      },
      'guestListStatus': {
        'En cours': 'Poursuivre',
        'Non commencé': 'Y aller'
      },
      'customizationStatus': {
        'En cours': 'Poursuivre',
        'Non commencé': 'Y aller'
      },
      'overallStatus': {
        'En cours': allPreviousStepsCompleted(project) ? 'Suivre les réservations' : null
      },
    };
    return buttonMap[step] && buttonMap[step][status] ? (
      <button
        className={styles.projectCardButton}
        onClick={(e) => {
          e.stopPropagation();
          handleButtonClick(step, status, project._id);
        }}
      >
        {buttonMap[step][status]}
      </button>) : null;

  };

  const handleButtonClick = (step, status, projectId) => {
    let path;
    if (step === 'venueStatus') {
      path = status === 'Non commencé' ? `/creer/lieu-du-mariage/${projectId}` : `/creer/lieu-du-mariage/${projectId}`;
    }
    if (path) {
      history.push(path);
    }
    if (step === 'guestListStatus') {
      path = status === 'Non commencé' ? `/creer/depot-fichier/${projectId}` : `/creer/liste-d-invites/${projectId}`;
    }
    // Vous pouvez ajouter d'autres conditions pour d'autres étapes ici
    if (path) {
      history.push(path);
    }
    if (step === 'customizationStatus') {
      path = status === 'Non commencé' ? `/creer/personnaliser-reservation/${projectId}` : `/creer/personnaliser-reservation/${projectId}`;
    }
    if (path) {
      history.push(path);
    }
  };

  const handleSave = async () => {
    try {
      const userResponse = await getCurrentUser();
      const userId = userResponse.data._id;

      await updateProjectDescription(userId, project._id, newDescription);
      setCurrentDescription(newDescription);
      setIsEditing(false);
      // Vous pouvez également mettre à jour l'état du projet ici pour refléter les changements
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la description du projet:", error);
    }
  };

  const allPreviousStepsCompleted = (project) => {
    return (
      project.venueStatus === 'Terminé' &&
      project.guestListStatus === 'Terminé' &&
      project.customizationStatus === 'Terminé'
    );
  };

  const imageMapping = {
    'Guests_ProjetCree.jpg': Guests_ProjetCree,
    'InvitesPot_ProjetCree.jpg': InvitesPot_ProjetCree,
    'LanceBouquet_ProjetCree.jpg': LanceBouquet_ProjetCree,
    'MamieEnfant_ProjetCree.jpg': MamieEnfant_ProjetCree,
  };

  const getImageBrightness = (imageSrc, callback) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let r, g, b, avg;

      let colorSum = 0;
      for (let x = 0, len = data.length; x < len; x += 4) {
        r = data[x];
        g = data[x + 1];
        b = data[x + 2];

        avg = Math.floor((r + g + b) / 3);
        colorSum += avg;
      }

      const brightness = Math.floor(colorSum / (img.width * img.height));
      callback(brightness);
    };
  };

  return (
    <div>
      <div
        className={`${styles.projectCard} ${isExpanded ? styles.projectCardExpanded : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <button
          className={`${styles.dashboardButton} ${projectStatus !== 'Terminé' ? styles.inactive : ''}`}
          onClick={(e) => goToDashboard(project._id, e)}
          disabled={projectStatus !== 'Terminé'}
          title={projectStatus !== 'Terminé' ? 'Vous devez compléter totalement votre projet pour accéder à son dashboard' : 'Aller au Dashboard'}
          style={{
            color: textColor, // Utilisez la couleur de texte déterminée
            borderColor: textColor // Assurez-vous que la bordure est également visible
          }}
        >
          Aller au Dashboard
        </button>
        <div
          className={styles.backgroundImage}
          style={{ backgroundImage: `url(${imageMapping[project.imagePath]})` }}
        ></div>
        {isEditing ? (
          <input
            className={styles.projectCardInput}
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSave();  // La fonction qui gère la confirmation
              }
            }}
          />
        ) : (
          <h3
            className={styles.projectCardTitle}
            style={{ color: textColor }}>{currentDescription}
          </h3>
        )}
        <button
          className={styles.projectCardButton}
          onClick={(e) => {
            e.stopPropagation();  // Empêche la propagation de l'événement
            isEditing ? handleSave() : setIsEditing(true);
          }}
          style={{ color: textColor }}>
          {isEditing ? "Enregistrer" : "Modifier le nom du projet"}
        </button>
        <div className={`${styles.statusIcons} ${isExpanded ? styles.statusIconsExpanded : styles.statusIconsCollapsed}`}>
          {[
            { status: project.venueStatus, step: 'venueStatus' },
            { status: project.guestListStatus, step: 'guestListStatus' },
            { status: project.customizationStatus, step: 'customizationStatus' },
            { status: project.overallStatus, step: 'overallStatus' }
          ].map(({ status, step }) => (
            <div
              className={styles.statusItem}
              key={step}
              style={{ color: textColor }}
            >
              {getStatusIcon(status)}
              {isExpanded && (
                <span className={styles.statusItemSpan}>
                  <strong>{getStepLabel(step)}: </strong><br />
                  {getStatusText(status, step, project)}<br />
                  {getStatusButton(status, step, project)}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;