
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToCircle } from '@fortawesome/free-solid-svg-icons';


function Focus() {
  return (
    <FontAwesomeIcon icon={faArrowsToCircle} spin size="4x" style={{ color: '#C62E0D' }}/>
  );
}

export default Focus;
