import React, { useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Carousel from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../RoomCard.module.css';
import CarousselArrows from './CarousselArrows';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GrCircleInformation } from "react-icons/gr";
import '../Slider.css';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 650,
    },
});

const SuiteCard = ({ suite, isSelected, onSelect, isSelectedByAnother, isReservedByAnother, isCanceledByAnother, isConfirmedByAonther, extraBedCost, amount, onOpenModal }) => {
    //handleReserveRoom
    const [isExpanded, setIsExpanded] = useState(false);
    const imagesToShow = suite.roomImgUrl ? suite.roomImgUrl : suite.buildingImgUrl;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CarousselArrows direction="next" />,
        prevArrow: <CarousselArrows direction="prev" />,
    };

    const suiteCardClasses = [
        imagesToShow.length === 1 ? styles.roomCardNoCarousel : styles.roomCardCarousel,
        suite.booked ? styles.booked : '',
        isSelected ? styles.selected : '',
        suite.groomRoom ? (isSelected ? styles.groomRoomSelected : styles.groomRoom) : '',
        isReservedByAnother ? styles.inCart : '',
        isSelectedByAnother ? styles.consulting : '',
        isCanceledByAnother ? styles.roomCard : '',
        isConfirmedByAonther ? styles.booked : '',
    ].join(' ');

    const totalAmountWithExtraBeds = amount + (extraBedCost * suite.extraBedsUsed);
    const shouldShowTooltip = amount < totalAmountWithExtraBeds;

    const tooltipTitle = `Pour cette suite, ${suite.extraBedsUsed} lit${suite.extraBedsUsed > 1 ? 's' : ''} supplémentaire${suite.extraBedsUsed > 1 ? 's' : ''} ${suite.extraBedsUsed > 1 ? 'sont' : 'est'} nécessaire${suite.extraBedsUsed > 1 ? 's' : ''}. Coût: ${amount}€ + (${suite.extraBedsUsed} x ${extraBedCost}€) = ${totalAmountWithExtraBeds}€`;

    const formattedTotalAmount = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
    }).format(totalAmountWithExtraBeds);

    const pmrFlag = suite.flags?.find(flag => flag.title === "PMR");

    return (
        <div
            className={`${styles.roomCard} ${suiteCardClasses}`}
            onClick={() => {
                onSelect(suite._key || suite.roomId);
            }}
            style={{
                cursor: 'pointer',
                // padding: '10px',
                margin: '10px',
                borderRadius: '5px',
                backgroundColor: isSelectedByAnother || isReservedByAnother ? '#f0f0f0' : 'white',
            }}
        >
            {imagesToShow && imagesToShow.length > 0 && (
                <>
                    {imagesToShow.length === 1 ? (
                        <div className={styles.roomCardImageContainer}>
                            <div className={styles.imageContainer}>
                                <img src={imagesToShow[0]} className={`${styles.image} ${styles.roomCardNoCarousel}`} />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.roomCardImageContainerCarousel}>
                            <Carousel {...settings}>
                                {imagesToShow.map((imgUrl, index) => (
                                    <div key={`${imgUrl}-${index}`} className={styles.roomCardImageContainerCarousel}>
                                        <img src={imgUrl} className={`${styles.image} ${styles.roomCardCarouselImg}`} />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    )}
                </>
            )}

            <div className={styles.roomCardContent}>
                <div className={styles.roomCardInfo}>
                    <h2>
                        {suite.buildingName}
                    </h2>
                    <h3 className={styles.roomCardH3}>
                        {suite.name} {pmrFlag && <img src={pmrFlag.iconUrl} alt="Chambre PMR" title="Chambre PMR" style={{ width: '20px', height: '20px', marginLeft: '5px' }} />}
                    </h3>
                    {suite.booked && <p style={{ color: '#5465ff' }}>Cette suite est déjà réservée</p>}
                    {isSelectedByAnother && <p style={{ color: '#5465ff' }}>Cette suite est consultée par d'autres invités</p>}
                    {isReservedByAnother && <p style={{ color: '#ff5465' }}>Un autre invité réserve actuellement cette suite, elle pourrait ne plus être disponible</p>}
                    <p>{suite.bedsCount} personnes</p>
                    <CustomTooltip
                        title={`Afin de pouvoir vous loger, vous aurez besoin de ${suite.extraBedsUsed} lit${suite.extraBedsUsed > 1 ? 's' : ''} supplémentaire${suite.extraBedsUsed > 1 ? 's.' : ''}`}
                        placement="bottom"
                    >
                        {suite.extraBedsUsed > 0 && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p className={styles.roomCardExtraBed}>Besoin en lits d'appoint : {suite.extraBedsUsed}</p>
                                <GrCircleInformation style={{ color: "#5465ff", marginLeft: "10px", fontSize: "16px" }} />
                            </div>

                        )}
                    </CustomTooltip>

                    {isExpanded && (
                        <>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                {suite.flags && suite.flags.map(flag => (
                                    <div key={flag._id}>
                                        <img
                                            src={flag.iconUrl}
                                            alt={flag.title}
                                            style={{ marginRight: '5px', width: '2.5em', height: 'auto' }}
                                        /> {/* Assurez-vous que les images sont accessibles */}
                                    </div>
                                ))}
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                {suite.bedSize && suite.bedSize.map(size => (
                                    <div key={size.sizeType._id} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                        <span style={{ marginRight: '5px' }}>{size.quantity} x</span>
                                        <img src={size.sizeType.iconUrl} alt={size.sizeType.title} title={size.sizeType.title} style={{ width: '2.5em', height: 'auto' }} />
                                    </div>
                                ))}
                            </div>
                            {/* Liste des chambres dans la suite */}
                            <h4>Chambres dans la suite:</h4>
                            <ul>
                                {suite.roomsInSuite.map(room => (
                                    <React.Fragment key={room._key}>
                                        <li className={styles.roomCardH3}>
                                            <span className={styles.roomName}> {room.name} - {room.floor} étage </span>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                                {room.bedSize && room.bedSize.map(size => (
                                                    <div key={size.sizeType._id} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                                        <span>{size.quantity} x</span>
                                                        <img src={size.sizeType.iconUrl} alt={size.sizeType.title} style={{ marginRight: '5px', width: '2.5em', height: 'auto' }} />
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                                {room.equipment && room.equipment.map(equip => (
                                                    <div key={equip._id}>
                                                        <img src={equip.iconUrl} alt={equip.title} style={{ marginRight: '5px', width: '2.5em', height: 'auto' }} />
                                                    </div>
                                                ))}
                                            </div>
                                        </li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
                <div>
                    {shouldShowTooltip ? (
                        <CustomTooltip title={tooltipTitle}>
                            <h3 className={styles.roomCardH3}>{formattedTotalAmount} <GrCircleInformation style={{ color: "#5465ff", marginLeft: "10px", fontSize: "16px" }} /></h3>
                        </CustomTooltip>
                    ) : (
                        <h3 className={styles.roomCardH3} >{formattedTotalAmount}</h3>
                    )}
                </div>
            </div>
            <button onClick={() => setIsExpanded(!isExpanded)} className={styles.expandButton}>
                {isExpanded ? "Moins d'infos" : "Plus d'infos"}
                <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className={styles.chevronIcon} />
            </button>
            {isSelected && (
                <div className={styles.buttonContainer}>
                    <button
                        className={styles.buttonConfirmer}
                        onClick={(e) => {
                            e.stopPropagation();
                            onOpenModal(suite);
                            // handleReserveRoom(suite);
                        }}
                    >
                        Réserver cette chambre
                    </button>
                </div>
            )}
        </div >
    );
};

export default SuiteCard;