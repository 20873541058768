import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Circles } from 'react-loader-spinner';
import styles from '../ThanksSubscription.module.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ThanksSubscription = () => {
    const query = useQuery();
    const [isValidSession, setIsValidSession] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [expired, setExpired] = useState(false);
    const history = useHistory();
    const [sessionExpirationMessage, setSessionExpirationMessage] = useState('');
    const sessionId = query.get('session_id');

    const navigate = (path) => {
        history.push(path);
    };

    useEffect(() => {
        const validateSession = async () => {
            try {
                if (!sessionId) {
                    setExpired(true);
                    setIsLoading(false);
                    return;
                }

                // Call your backend or Stripe to validate the session ID
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/stripe/validate-stripe-session`, { 
                    sessionId,
                    withCredentials: false
                 });

                if (response.data.isValid) {
                    setIsValidSession(true);

                    const sessionCreationTime = new Date(response.data.sessionCreatedAt).getTime();
                    console.log('sessionCreationTime:', sessionCreationTime)
                    const expirationTime = sessionCreationTime + 24 * 60 * 60 * 1000;
                    const expirationDate = new Date(expirationTime).toLocaleString('fr-FR'); // Display in French date format

                    setSessionExpirationMessage(`Cette page expirera le ${expirationDate}.`);
                } else {
                    setExpired(true);
                    setSessionExpirationMessage(response.data.message);
                }
            } catch (error) {
                console.error('Failed to validate session:', error);
                setExpired(true);
                setSessionExpirationMessage('Session invalide ou expirée.');
            } finally {
                setIsLoading(false);
            }
        };

        validateSession();
    }, [sessionId]);

    if (isLoading) {
        return (
            <div className={styles.loadingContainer}>
                <Circles
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    color="#5465ff"
                />
            </div>
        );
    }

    if (expired) {
        return (
            <div className={styles.mainContainer}>
                <div className={styles.expiredSessionContainer}>
                    <h2>Session expirée ou invalide</h2>
                    <p>{sessionExpirationMessage}</p>
                    <p>Il semble que votre session ait expiré ou que le paiement n'ait pas abouti.</p>
                    <p>
                        Si vous avez des questions, contactez-nous à l'adresse{' '}
                        <a href="mailto:contact@your-guests.com">contact@your-guests.com</a> pour obtenir de l'aide.
                    </p>
                    <button onClick={() => navigate('/')} className={styles.button}>
                        Retour à l'accueil
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.mainContainer}>
            {isValidSession ? (
                <div>
                    <h1 className={styles.felicitations}>
                        Merci pour votre paiement !{' '} <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#28b97b", marginLeft: "10px" }} />
                    </h1>
                    <p
                        className={styles.congratsP}
                    >
                        Un email de confirmation vous sera envoyé sous 1h. Vous y retrouverez toutes les informations liées à votre paiement.
                    </p>
                    <p className={styles.congratsP}>
                        {sessionExpirationMessage}
                    </p>
                    <p
                        className={styles.congratsP}
                    >
                        Si vous n'avez reçu aucun email d'ici 24h, contactez-nous :{' '}
                        <a
                            rel="noopener"
                            href={`mailto:contact@your-guests.com?subject=${encodeURIComponent(`Demande d'information pour le paiement ${sessionId}`)}`}
                            target="_blank"
                        >
                            contact@your-guests.com
                        </a>
                    </p>
                </div>
            ) : (
                <div>Invalid Session</div>
            )}
        </div>
    );
};

export default ThanksSubscription;