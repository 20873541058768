import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DashboardTable from '../components/Tabulator/DashboardTable';
import StripeDashboard from '../components/StripeDashboard/StripeDashboard';
import StripeBalance from '../components/StripeBalance/StripeBalance';
import { getUserInfo, getLoginLink, transferStripe, getStripeBalance, getCurrentUser, getOverallStatus, setReminder, getReminder, sendPaymentConfirmationEmail } from '../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faXmark, faMoneyBillTransfer, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from '../DashboardPayment.module.css';
import noProjectIcon from '../assets/icons/empty_folder.png';
import { MdScheduleSend } from "react-icons/md";

const DashboardPayments = () => {
    const [stripeAccountID, setStripeAccountID] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);
    const [isDone, setIsDone] = useState(false);
    const [isDoneFailed, setIsDoneFailed] = useState(false);
    const [projectStatus, setProjectStatus] = useState('');
    const [reminderFrequency, setReminderFrequency] = useState('weekly');
    const [emailSent, setEmailSent] = useState(false);
    const { projectId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await getUserInfo();
                const userData = userResponse.data;

                if (userData.stripeAccountId) {
                    setStripeAccountID(userData.stripeAccountId);
                } else {
                    console.log("Stripe Account ID not found.");
                }

            } catch (error) {
                console.error("Erreur lors de la récupération des données de l'utilisateur:", error);
            }
        };
        fetchData().then(() => setIsLoading(false));
    }, []);

    const handleOpenDashboard = async (e) => {
        e.preventDefault();
        if (!stripeAccountID) {
            console.error("Stripe Account ID is missing");
            return;
        }
        try {
            const url = await getLoginLink(stripeAccountID);
            if (url) {
                window.open(url, '_blank');
            } else {
                console.error("No URL received for Stripe dashboard");
            }
        } catch (error) {
            console.error("Erreur lors de la récupération de l'URL de connexion Stripe:", error);
        }
    };

    useEffect(() => {
        const fetchProjectStatus = async () => {
            try {
                const userResponse = await getCurrentUser();
                const userId = userResponse.data._id;

                const response = await getOverallStatus(userId, projectId);
                if (response.status === 200 && response.data) {
                    setProjectStatus(response.data.overallStatus);
                } else {
                    setProjectStatus('');
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du statut global du projet:", error);
                setProjectStatus('');
            }
        };
        fetchProjectStatus();
    }, [projectId]);

    useEffect(() => {
        const fetchReminderFrequency = async () => {
            try {
                const userResponse = await getCurrentUser();
                const userId = userResponse.data._id;

                console.log('userId & projectId before getReminder', userId || '+' || projectId)
                const response = await getReminder(userId, projectId);
                if (response.status === 200 && response.data) {
                    setReminderFrequency(response.data.reminderFrequency);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de la fréquence de rappel:", error);
            }
        };
        fetchReminderFrequency();
    }, [projectId]);
    

    const handleFrequencyChange = async (event) => {
        const newFrequency = event.target.value;
        setReminderFrequency(newFrequency);
        try {
            const userResponse = await getCurrentUser();
            const userId = userResponse.data._id;

            await setReminder(userId, projectId, newFrequency);
            setSuccessMessage('Fréquence de rappel mise à jour avec succès.');
            setTimeout(() => setSuccessMessage(null), 3000);
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la fréquence de rappel :', error);
            setError('Erreur lors de la mise à jour de la fréquence de rappel.');
            setTimeout(() => setError(null), 3000);
        }
    };

    const handleWithdrawal = async () => {
        try {
            // Récupérer le solde disponible
            const balanceData = await getStripeBalance(stripeAccountID);
            const amount = balanceData.available[0].amount;

            // Vérifier si le solde est suffisant
            if (amount <= 0) {
                setError("Le solde disponible est insuffisant.");
                setIsDoneFailed(true);
                setTimeout(() => {
                    setError(null);
                    setIsDoneFailed(false);
                }, 3000);
                return; // Arrêter l'exécution si le solde n'est pas suffisant
            }

            // Tenter de réaliser le virement
            const response = await transferStripe(amount, stripeAccountID);
            if (response && response.status === 200) {
                setSuccessMessage("Virement correctement réalisé !");
                setIsDone(true);
                setTimeout(() => {
                    setSuccessMessage(null);
                    setIsDone(false);
                }, 3000);
            } else {
                // Gérer les réponses non réussies
                setError("Échec du virement. Veuillez réessayer.");
                setIsDoneFailed(true);
                setTimeout(() => {
                    setError(null);
                    setIsDoneFailed(false);
                }, 3000);
            }

        } catch (error) {
            console.error('Erreur lors du transfert:', error);
            setError("Une erreur est survenue lors du traitement de votre demande.");
            setIsDoneFailed(true);
            setTimeout(() => {
                setError(null);
                setIsDoneFailed(false);
            }, 3000);
        }
    };

    const handleSendPaymentConfirmation = async () => {
        try {
            const userResponse = await getCurrentUser();
            const userId = userResponse.data._id;

            await sendPaymentConfirmationEmail(userId, projectId);
            setSuccessMessage('Email de confirmation de paiement envoyé avec succès.');
            setEmailSent(true);
            setTimeout(() => setSuccessMessage(null), 3000);
        } catch (error) {
            console.error('Erreur lors de l\'envoi de l\'email de confirmation de paiement :', error);
            setError('Erreur lors de l\'envoi de l\'email de confirmation de paiement.');
            setTimeout(() => setError(null), 3000);
        }
    };

    if (projectStatus !== 'Terminé') {
        return (
            <div className={styles.container}>
                <div className={styles.stripeContainer}>
                    <div className={styles.message}>
                        Aucun projet par ici ! Vous devez terminer la configuration de votre projet avant d'accéder au dashboard.
                    </div>
                    <img src={noProjectIcon} alt="No Project" className={styles.noProjectIcon} />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <h2 className={styles.title}>
                    Bienvenue sur votre dashboard de suivi
                </h2>
                <h3 className={styles.description}>
                    Vous pouvez suivre ici l'état de réservation de vos invités, configurer un mail de relance pour les retardataires et gérer votre argent
                </h3>
                <div className={styles.stripeContainer}>
                    <StripeBalance accountId={stripeAccountID} />
                    <StripeDashboard onOpenDashboard={handleOpenDashboard} />
                </div>
                <div className={styles.topTableContainer}>
                    <div className={styles.reminderContainer}>
                        <MdScheduleSend style={{ color: "#5465ff", marginRight: "10px", fontSize: "2rem" }} />
                        <label htmlFor="reminderFrequency">
                            Fréquence de rappel par e-mail :
                        </label>
                        <select
                            id="reminderFrequency"
                            value={reminderFrequency}
                            onChange={handleFrequencyChange}
                            className={styles.reminderSelect}
                        >
                            <option value="weekly">Hebdomadaire</option>
                            <option value="bi-weekly">Toutes les deux semaines</option>
                            <option value="monthly">Mensuel</option>
                        </select>
                    </div>
                    <div className={styles.withdrawalContainer}>
                        <button
                            onClick={handleSendPaymentConfirmation}
                            className={styles.buttonPayout}
                        >
                            Renvoyer les e-mails de confirmation <FontAwesomeIcon icon={faEnvelope} size='xl' style={{ marginLeft: '15px' }}/>
                        </button>
                        {emailSent && <FontAwesomeIcon icon={faCheckCircle} size="xl" className="ml-2 text-green-500" />}
                    </div>
                    <div className={styles.withdrawalContainer}>
                        <button
                            onClick={handleWithdrawal}
                            className={styles.buttonPayout}
                        >
                            Demander Retrait <FontAwesomeIcon icon={faMoneyBillTransfer} size='xl' style={{ marginLeft: '15px' }} />
                        </button>
                        {isDone && <FontAwesomeIcon icon={faCheckCircle} size="xl" className="ml-2 text-green-500" /> && successMessage && <p className="text-green-500 mt-10">{successMessage}</p>}
                        {isDoneFailed && <FontAwesomeIcon icon={faXmark} size="xl" className="ml-2 text-red-500" /> && error && <p className="text-red-500 mt-10">{error}</p>}
                    </div>                    
                </div>
                <DashboardTable />
            </div>
        </div>
    );

};
export default DashboardPayments;