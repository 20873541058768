import React from "react";
import styles from '../Privacy.module.css';
import { useHistory } from 'react-router-dom';

const Terms = () => {

    const history = useHistory();

    const navigate = (path) => {
        history.push(path);
    };

    return (

        <div className={styles.container}>
            <div className={styles.subContainer}>
                <h1 className={styles.title}>
                    Conditions d'Utilisation de Your Guests
                </h1>

                Si votre pays de résidence ou d'établissement se trouve dans l'Espace économique européen (« EEE »), en Suisse ou au Royaume-Uni, les <a className={styles.hrefColor} onClick={() => navigate("/terms#EUTOS")}> Conditions de service pour les utilisateurs européens</a> s'appliquent à vous.

                Si votre pays de résidence ou d'établissement se trouve en dehors de l'EEE, de la Suisse, de l'Australie et du Royaume-Uni, les <a className={styles.hrefColor} onClick={() => navigate("/terms#TOS")}>Conditions de service pour les utilisateurs situés en dehors de l'EEE, du Royaume-Uni et de l'Australie</a> s'appliquent à vous.

                <div id="EUTOS">
                    <h2 className={styles.subTitle}>
                        Conditions de service pour les utilisateurs européens
                    </h2>

                    En tant que consommateur ou consommatrice résidant dans l'EEE, vous pouvez accéder à la plateforme en ligne de résolution des litiges de la Commission européenne ici : <a className={styles.hrefColor} href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>. Veuillez noter qu'Your Guests ne s'engage pas et n’est pas tenue de recourir à une entité de règlement extrajudiciaire des litiges au sens de la directive 2013/11/UE afin de résoudre des litiges avec les consommateurs. La plateforme de résolution des litiges en ligne de la Commission européenne n'est pas disponible pour les résidents de la Suisse ou du Royaume-Uni.
                    L'article 25 des présentes Conditions contient une convention d'arbitrage et de renonciation à recours collectif qui s'appliquent à toute demande d'indemnisation engagée contre Your Guests aux États-Unis. Veuillez les lire attentivement.
                    Bien que Your Guest n'est pas tenue de participer à une médiation, nous demeurons ouverts à résoudre les litiges de manière informelle ou directe avec nos consommateurs.
                    <br/>
                    <p className={styles.dateUpdate}>
                    Dernière mise à jour : 03/05/2024
                    </p>
                    <br/>

                    Les présentes Conditions de service pour les utilisateurs européens (les « Conditions ») sont un contrat juridique contraignant entre vous et Your Guests qui régit votre droit d'utilisation des sites web, applications et autres offres de Your Guests (appelés collectivement, la « Plateforme Your Guests »). Lorsqu'ils sont utilisés dans ces conditions, les termes « Your Guests », « nous », « nos » ou « notre » désignent l'entité Your Guests avec laquelle vous concluez un contrat.
                    <br/>
                    La Plate-forme Your Guests offre un outil en ligne permettant aux utilisateurs (« Membres ») de publier, de proposer, de rechercher et de réserver des services. Les Membres qui publient et proposent des services sont des « Hôtes » et les Membres qui recherchent, réservent ou utilisent des services sont des « Invités ».
                    Les Hôtes créent un espace de réservations de chambres pour leurs Invités. Pour ce faire, les Hôtes sélectionnent leur lieu de mariage, fixe un tarifs ou des tarifs personnalisés et les modalités d'application du ou des tarifs. Les Invités accèdent à la page de réservation qui est la synthèse des choix des Hôtes et réservent une chambre.
                    En tant que fournisseur de la Plate-forme Your Guests, Your Guests ne possède, ne contrôle, ne propose et ne gère ni les annonces, ni les Services d'hôte ni aucun service touristique. Your Guests ne propose aucun prix conseillé ou tarifs applicable pour la réservation de chambres, et Your Guests n'est pas agent immobilier, agence de voyage, planificateur de mariage, ni assureur, pas non plus organisateur ou détaillant de voyages organisés en vertu de la directive (UE) 2015/2302. Your Guests n'agit pas en qualité de mandataire d'un quelconque Membre, sauf comme le prévoient les Conditions de service relatives aux paiements (« Conditions de paiement »). Pour en savoir plus sur le rôle d'Your Guests, consultez l'article 6.
                    Enfin, Your Guests ne réalise aucune réservation de lieu de mariage. Il convient aux Hôtes d'effectuer les démarches de réservation du lieu au préalable.
                    <br/>
                    Nous appliquons d'autres Conditions et Politiques complémentaires à ces Conditions, comme notre Politique de confidentialité, qui décrit notre collecte et utilisation des données personnelles, et nos Conditions de paiement, qui régissent tous les services de paiement fournis aux membres par le service de paiement Your Guests.

                    <h2 className={styles.subTitle}>
                        1. Acceptation des Conditions
                    </h2>

                    En accédant et en utilisant Your Guests, vous acceptez d'être lié par ces conditions d'utilisation, toutes les lois et réglementations applicables, et acceptez que vous êtes responsable du respect des lois locales. Si vous n'êtes pas d'accord avec l'une de ces conditions, vous êtes interdit d'utiliser ou d'accéder à ce site.

                    <h2 className={styles.subTitle}>
                        2. Utilisation du Service
                    </h2>

                    Your Guests fournit une plateforme de réservation en ligne permettant aux hôtes de gérer les réservations de chambres pour leurs invités lors d'événements spéciaux, notamment les mariages. Les utilisateurs sont responsables de fournir des informations exactes et à jour lors de la création et la mise à jour de réservations.

                    <p className={styles.boldtext}>
                        2.1 Recherche
                    </p>

                    Les Hôtes peuvent rechercher un lieu de mariage par mots clés sur la page dédiée à la recherche du lieu. Si le lieu est trouvé il est automatiquement proposé et les Hôtes peuvent le sélectionner. Si le lieu n'est pas trouvé les Hôtes ont la possibilité depuis la recherche de faire une demande d'ajout à Your Guests. Your Guests s'engage à y répondre et traiter la demande dans des délais raisonnables.

                    <p className={styles.boldtext}>
                        2.2 Ajout de la liste d'invités
                    </p>
                    La liste d'invités prend la forme d'un fichier .txt ou .csv et est peut être chargé sur la page dédiée à son chargement. Le fichier doit respecter un ordre spécifique de "colonnes" (NOM, PRENOM, EMAIL, TELEPHONE). Your Guests rejette un fichier mal formatté, avec la mauvaise terminaison ou avec des données non conformes (email et téléphone).
                    Il relève de la responsabilité des Hôtes de s'assurer de l'exactitude des données transmises à Your  Guests. Nous ne sommes pas responsable d'une erreur d'écriture ou coquille qui engendrerait le non-envoi et la non-réception de l'email d'invitation aux invités concernés. Nous conseillons aux Hôtes de bien vérifier plusieurs fois l'exactitude des informations de leurs invités avant de les valider.
                    Chez Your Guests, nous respectons la vie privée de nos membre. Nous stockons les informations des Invités et Hôtes et nous engageons à n'en faire aucun usage commercial, à but publicitaire ou encore de newsletter. Aucune des données sensibles de nos membres ne sont stockées dans les cookies ou stockage du navigateur.

                    Il revient à chaque Hôte de charger la liste la plus aboutie, ou finale, de leurs invités cela afin d'éviter de devoir remanier cette liste après l'envoi des e-mails d'invitations et ne pas avoir à gérer trop d'annulations. Nous estimons qu'il est dans l'intérêt des Hôtes qui souhaitent se libérer d'une charge mentale d'effectuer ce travail, aussi laborieux soit-il avant d'utiliser notre plateforme. Cela, afin de leur éviter à gérer des situations évitables via notre plate-forme.
                    Your Guests considère que la liste chargée sera la plus aboutie. Les annulations qui pourront survenir n'amèneront pas à la modification de la liste d'invités et les remboursements seront limités à certaines conditions (voir article 7).

                    Enfin, les Hôtes devront être sûr de valider une liste d'Invités définitive avant de passer aux étapes suivantes.

                    <p className={styles.boldtext}>
                        2.3 Personnalisation
                    </p>
                    Les hôtes disposent d'un panel d'options de personnalisation afin que l'expérience soit la plus fluide et la plus proche de leurs attentes. Pour personnaliser les hôtes seront amenés à indiquer :
                    <ul className={styles.textUl}>
                        <li className={styles.textLiP}>Les Invités qui pourront loger sur le lieu du mariage et les Invités qui seront logés dans des hôtels, gîtes ou autres. Your Guests propose un heuristique qui permet de ne pas dépasser les capacités d'accueil maximum du lieu de mariage. Il est toutefois tenu aux Hôtes de bien contrôler la cohérence des attributions de couchages avant de valider.</li>
                        <li className={styles.textLiP}>Les dates de leur mariage depuis le jour et l'heure à partir desquels leurs Invités peuvent arriver jusqu'au jour et l'heure avant lesquels leurs Invités sont tenus de quitter les lieux. Ces informations sont à titre indicatives et se veulent utiles pour offrir une expérience plus riche aux Invités. Your Guests n'est pas responsable des avances/retards aux arrivées et départs.</li>
                        <li className={styles.textLiP}>Le prix. Les Hôtes peuvent vouloir appliquer le prix qu'ils désirent. Your Guests n'encourage aucune politique de tarification. Nous appliquons une commission de 10,45% aux Invités au moment du paiement.</li>
                        <li className={styles.textLiP}>L'application du prix selon le nombre de nuits. Si le nombre de nuit est supérieur à 1 (un), alors les Hôtes peuvent choisir d'appliquer le tarif par nuit ou pour l'ensemble des nuits.</li>
                        <li className={styles.textLiP}>Des tarifs spéciaux pour les familles et les enfants. Les Hôtes peuvent également choisir d'appliquer leur tarif par tête de couple (tarif appliqué aux deux Invités formant un couple ou qui sont parents) ou par couple (tarif appliqué une fois pour les deux invités formants un couple ou qui sont parents). </li>
                        <li className={styles.textLiP}>Les Invités qui partageront le même bâtiment que les Hôtes occuperont pour leur(s) nuit(s). Ces Invités auront accès à une page de réservation avec le bâtiment concerné pré-réservé. Les autres Invités ne peuvent pas voir les chambres de ce bâtiment.</li>
                        <li className={styles.textLiP}>Le contenu de l'e-mail ou des e-mails d'invitation. Selon que les Invités dorment sur le lieu du mariage ou dans des hôtels, gîtes ou autres, l'e-mail à personnaliser est différent. Les Hôtes peuvent personnaliser leur e-mail afin qu'il leur ressemble vraiment. Your Guests conseille fortement de laisser les informations relatives à la date et au lieu. Your Guests n'est pas tenu de fournir une liste exhaustive de toutes les solutions de logements alternatifs pour le cas de l'e-mail aux Invités ne pouvant loger sur le lieu du mariage. Les Hôtes doivent s'assurer que la liste fournie par Your Guests est toujours d'actualité et peuve la compléter.</li>
                    </ul>

                    <p className={styles.boldtext}>
                        2.4 Dashaboard de suivi
                    </p>
                    Le Dashboard de suivi des réservations offrent aux Hôtes une vision globale des réservations passées, en cours et non effectuées. Ils leur ai offert de programmer un e-mail de relance aux Invités n'ayant pas effectuer leur réservation avant une date fixée par eux-même. Le Dashboard leur offre également la possibiltié d'accéder à leur page de suivi Stripe, aussi appelé Dashboard Stripe. Stripe étant le partenaire de paiement de Your Guests (Voir l'article 6).
                    Enfin le Dashboard permet aux Hôte de visualiser le montant disponible sur leur portefeuille en ligne et de demander un virement sur le compte bancaire renseigné à Stripe au moment de l'inscription.

                    <h2 className={styles.subTitle}>
                        3. Comptes Utilisateurs
                    </h2>

                    Chaque utilisateur doit créer un compte pour accéder à certaines fonctionnalités du service. Vous êtes responsable de la sécurité de votre mot de passe et de toutes les activités qui se produisent sous votre compte. En cas de suspicion de fraude ou de sécurité compromise, vous devez immédiatement nous notifier.
                    Au moment de l'inscription l'Hôte devra poursuivre avec notre partenaire de paiement afin de compléter des informations personnelles et bancaires. Your Guests n'a aucunement accès à ces données qui vous relient exclusivement avec Stripe.

                    <h2 className={styles.subTitle}>
                        4. Propriété Intellectuelle
                    </h2>

                    Le contenu fourni par Your Guests, incluant mais sans se limiter à des textes, des graphiques, des logos, des images, ainsi que leur compilation, sont la propriété de Your Guests et sont protégés par les lois sur la propriété intellectuelle.

                    <h2 className={styles.subTitle}>
                        5. Contenu des Utilisateurs
                    </h2>

                    <p className={styles.boldtext}>5.1 Contenu Généré par les Utilisateurs :</p>
                    Your Guests permet aux hôtes d'enrichir leurs communications par courriel avec des images, vidéos, et liens via une interface d'édition de courriel intégrée (Quill avec Unlayer). Toutefois, nous tenons à préciser que ces contenus ne sont pas stockés sur nos serveurs une fois les courriels envoyés. Notre plateforme traite ces éléments de contenu uniquement comme des parties du processus d'envoi du courriel, sans enregistrer ni réutiliser ces données par la suite.

                    <p className={styles.boldtext}>5.2 Propriété Intellectuelle des Contenus :</p>
                    Lorsque vous utilisez notre éditeur de courriel pour ajouter des contenus tels que des images ou vidéos, vous garantissez que vous possédez les droits nécessaires sur ces contenus ou que vous avez obtenu les autorisations requises pour leur utilisation. Your Guests n'assume aucune responsabilité pour le contenu intégré dans les courriels envoyés via notre plateforme.

                    <p className={styles.boldtext}>5.3 Responsabilité du Contenu :</p>
                    Les hôtes sont entièrement responsables des contenus intégrés et envoyés via les courriels. Ils doivent s'assurer que ces contenus respectent les droits de propriété intellectuelle et ne violent aucune loi applicable. Your Guests décline toute responsabilité liée à l'utilisation inappropriée de ces contenus.


                    <h2 className={styles.subTitle}>
                        6. Tarification et Paiements
                    </h2>

                    Concernant les Hôtes, le service est actuellement gratuit mais évoluera sur une offre plus complète notamment à destination de parents de mariés qui pourraient vouloir utiliser nos services pour le mariage de chacun de leurs enfants par exemple.
                    Concernant les Invités, les détails concernant les tarifs et les modalités de paiement sont disponibles lors de la réservation.
                    Les paiements sont traités par un prestataire tiers sécurisé, Stripe, et Your Guests ne stocke pas d'informations de carte de crédit, de compte tiers (PayPal, Klarna), IBAN ou toute autre information bancaire ou de paiement.
                    Nous mettons tout en oeuvre pour proposer à nos utilisateurs des tarifs les plus adaptés, au "juste prix" des services proposés et qui permettent de faire vivre cette plate-forme.

                    <h2 className={styles.subTitle}>
                        7. Annulations et Remboursements
                    </h2>

                    Concernant les Invités, si le paiement n'a pas encore eu lieu, l'annulation de la réservation en cours est sans incidence sur leur parcours. Ils peuvent reprendre où ils en étaient.
                    Si le paiement a déjà eu lieu, deux options s'offrent à eux :

                    <ul className={styles.textUl}>
                        <li className={styles.textLiP}>L'argent n'est pas encore versé sur le compte des mariés : <a className={styles.hrefColor} onClick={() => navigate("/contact")}>contacter immédiatement Your Guest</a> qui pourra procéder au remboursement intégral (commission incluse)</li>
                        <li className={styles.textLiP}>L'argent est déjà versé sur le compte des Hôtes et dans un délais de 14 jours à compter du paiement : prenez contact immédiatement avec eux afin de trouver un arrangement. Your Guests s'engage à rembourser la commission perçue si ce délai est respecté. Passé le délai de 14 jours Your Guests ne rembourse pas la commission prise sur la transaction.</li>
                    </ul>

                    La politique d'annulation et de remboursement sera clairement communiquée lors de la réservation. Les utilisateurs sont encouragés à la lire attentivement avant de finaliser une réservation.

                    Concernant les Hôtes, ils sont tenus de fournir une liste d'invités la plus aboutie possible. Le service que nous offrons n'est pas adapté à la modification répétée de la liste d'invités. Si nous remarquons un mauvais usage de notre plate-forme, nous serons amenés à intervenir dans un premier temps par un rappel des conditions d'utilisation puis, si cela s'avère nécessaire par la suspension du compte de l'Hôte après le préavis réglementaire.
                    Si les Hôtes souhaitent annuler leur projet de réservation, ils sont priés de bien vouloir nous contacter afin que nous procédions à l'annulation et au remboursement. L'annulation devra être de force majeure et inévitable. Your Guests pourra avoir besoin de certains éléments afin de valider l'annulation.

                    <h2 className={styles.subTitle}>
                        8. Modifications du Service et des Conditions
                    </h2>

                    Your Guests se réserve le droit de modifier ou d'interrompre le service à tout moment, avec ou sans préavis. Nous nous réservons également le droit de modifier ces conditions d'utilisation à tout moment. Votre utilisation continue du service après de telles modifications constitue votre consentement à ces modifications.

                    <h2 className={styles.subTitle}>
                        9. Contact
                    </h2>

                    Pour toutes questions ou préoccupations concernant ces conditions d'utilisation, nous serons heureux de vous aider en nou contactant  via <a className={styles.hrefColor} onClick={() => navigate("/contact")}>la page de contact dédiée</a>.

                    <h2 className={styles.subTitle}>
                        10. Règles de la Plateforme Your Guests
                    </h2>

                    <p className={styles.boldtext}>10.1 Règlement :</p> Vous devez respecter ces règles et ne devez pas aider ou inciter d'autres personnes à les enfreindre ou à les contourner.

                    <ul className={styles.textUl}>
                        <li className={styles.textLiP}><p className={styles.boldtext}>Agir avec intégrité et traiter les autres avec respect :</p></li>
                        <ul className={styles.subList}>
                            <li className={styles.textLiP}>Ne pas mentir, représenter faussement quelque chose ou quelqu'un, ou prétendre être quelqu'un d'autre.</li>
                            <li className={styles.textLiP}>Faire preuve de politesse et de respect lorsque vous communiquez ou interagissez avec les autres utilisateurs.</li>
                            <li className={styles.textLiP}>Respecter notre politique de non-discrimination et ne pas discriminer ou harceler les autres.</li>
                        </ul>

                        <li className={styles.textLiP}><p className={styles.boldtext}>Protection de la Plateforme :</p></li>
                        <ul className={styles.subList}>
                            <li className={styles.textLiP}>Ne pas endommager, pirater, faire de l'ingénierie inverse, compromettre ou altérer la plateforme Your Guests de quelque manière que ce soit.</li>
                            <li className={styles.textLiP}>Ne pas utiliser des robots, crawlers, scrapers ou autres moyens automatisés pour accéder à des données ou autre contenu ou les récupérer sur la plateforme Your Guests ou pour interagir avec cette dernière.</li>
                        </ul>

                        <li className={styles.textLiP}><p className={styles.boldtext}>Utilisation appropriée de la Plateforme :</p></li>
                        <ul className={styles.subList}>
                            <li className={styles.textLiP}>Ne pas utiliser les informations personnelles d'un autre utilisateur que dans la mesure du nécessaire pour faciliter une transaction sur la plateforme Your Guests, comme autorisé par ces conditions.</li>
                            <li className={styles.textLiP}>Utiliser le contenu mis à disposition par la plateforme Your Guests uniquement si cela est nécessaire pour vous permettre d'utiliser la plateforme en tant qu'Hôte ou Invité.</li>
                            <li className={styles.textLiP}>Ne pas demander, effectuer ou accepter une réservation ou un paiement en dehors de la plateforme Your Guests pour éviter de payer des frais, des taxes ou pour toute autre raison.</li>
                        </ul>

                        <li className={styles.textLiP}><p className={styles.boldtext}>Respecter vos obligations légales :</p></li>
                        <ul className={styles.subList}>
                            <li className={styles.textLiP}>Comprendre et respecter les lois qui s'appliquent à vous, y compris les lois sur la vie privée, la protection des données et l'exportation.</li>
                            <li className={styles.textLiP}>Si vous fournissez des informations personnelles relatives à une autre personne, vous devez le faire dans le respect du droit applicable et vous devez être autorisé à le faire.</li>
                            <li className={styles.textLiP}>Lire et respecter les présentes Conditions.</li>
                        </ul>
                    </ul>
                </div>
                <div id="TOS">                
                    <h2 className={styles.subTitle}>
                        Terms of Service Users outside of the EEA, UK, and Australia
                    </h2>

                    As a consumer residing in the EEA, you can access the European Commission's online dispute resolution platform here: <a className={styles.hrefColor} href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>. Please note that Your Guests is not committed and is not obligated to use an alternative dispute resolution entity as defined by Directive 2013/11/EU to resolve disputes with consumers. The European Commission's online dispute resolution platform is not available to residents of Switzerland or the United Kingdom.
                    Article 25 of these Conditions contains an arbitration agreement and a class action waiver that apply to any claims for damages filed against Your Guests in the United States. Please read them carefully.
                    While Your Guests is not obligated to participate in mediation, we remain open to resolving disputes in an informal or direct manner with our consumers.

                    Last updated: May 3, 2024

                    These European User Service Conditions ("Conditions") are a legally binding contract between you and Your Guests governing your right to use the websites, applications, and other offerings of Your Guests (collectively, the "Your Guests Platform"). When used in these Conditions, the terms "Your Guests," "we," "our," or "us" refer to the Your Guests entity with which you are contracting.

                    The Your Guests Platform provides an online tool that allows users ("Members") to post, offer, search for, and book services. Members who post and offer services are "Hosts" and members who search, book, or use services are "Guests".
                    Hosts create a room reservation space for their Guests. To do this, Hosts select their wedding venue, set custom rates and terms for applying these rates. Guests access the reservation page which is a synthesis of the Hosts' choices and book a room.
                    As the provider of the Your Guests Platform, Your Guests does not own, control, offer, or manage any listings, Host Services, or any travel services. Your Guests does not suggest any recommended prices or rates applicable for booking rooms, and Your Guests is not a real estate broker, travel agency, wedding planner, insurer, nor is it a tour organizer or travel retailer under Directive (EU) 2015/2302. Your Guests does not act as an agent for any Member, except as provided in the Payment Service Conditions ("Payment Conditions"). For more information about the role of Your Guests, see Article 6.
                    Finally, Your Guests does not make any wedding venue reservations. It is up to the Hosts to carry out the reservation processes beforehand.

                    We apply other Conditions and Policies supplementary to these Conditions, such as our Privacy Policy, which describes our collection and use of personal data, and our Payment Conditions, which govern all payment services provided to members by Your Guests' payment service.

                    <h2 className={styles.subTitle}>
                        1. Acceptance of Conditions
                    </h2>

                    By accessing and using Your Guests, you agree to be bound by these terms of use, all applicable laws and regulations, and accept that you are responsible for compliance with local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site.

                    <h2 className={styles.subTitle}>
                        2. Use of the Service
                    </h2>

                    Your Guests provides an online reservation platform that allows hosts to manage room reservations for their guests at special events, including weddings. Users are responsible for providing accurate and up-to-date information when creating and updating reservations.

                    <p className={styles.boldtext}>
                        2.1 Search
                    </p>

                    Hosts can search for a wedding venue by keywords on the dedicated search page. If the venue is found it is automatically proposed and the Hosts can select it. If the venue is not found, Hosts have the option from the search to make a request for addition to Your Guests. Your Guests commits to responding to and processing the request within reasonable timeframes.

                    <p className={styles.boldtext}>
                        2.2 Adding the Guest List
                    </p>
                    The guest list takes the form of a .txt or .csv file and can be uploaded on the dedicated page for its upload. The file must adhere to a specific order of "columns" (NAME, FIRST NAME, EMAIL, PHONE). Your Guests rejects a poorly formatted file, with the wrong termination or with non-compliant data (email and phone).
                    It is the responsibility of the Hosts to ensure the accuracy of the data transmitted to Your Guests. We are not responsible for a typo or mistake that would result in the non-sending and non-reception of the invitation email to the concerned guests. We advise Hosts to verify the accuracy of their guests' information multiple times before validating it.
                    At Your Guests, we respect the privacy of our members. We store the information of Guests and Hosts and commit to making no commercial use of it, for advertising purposes, or newsletters. None of the sensitive data of our members is stored in cookies or browser storage.

                    It is up to each Host to upload the most comprehensive, or final, list of their guests in order to avoid having to rework this list after sending the invitation emails and not having to manage too many cancellations. We believe it is in the interest of Hosts who wish to free themselves from a mental burden to perform this work, as laborious as it may be, before using our platform. This, to avoid managing avoidable situations via our platform.
                    Your Guests considers the uploaded list to be the most comprehensive. Cancellations that may occur will not lead to the modification of the guest list, and refunds will be limited to certain conditions (see Article 7).

                    <p className={styles.boldtext}>
                        2.3 Customization
                    </p>
                    Hosts have a panel of customization options so that the experience is as smooth and as close to their expectations as possible. To customize, hosts will be prompted to indicate:
                    <ul className={styles.textUl}>
                        <li className={styles.textLiP}>Guests who can stay at the wedding venue and Guests who will be accommodated in hotels, guesthouses, or other accommodations. Your Guests offers a heuristic that prevents exceeding the maximum accommodation capacities of the wedding venue. However, it is incumbent upon the Hosts to properly control the consistency of the sleeping arrangements before validating.</li>
                        <li className={styles.textLiP}>The dates of their wedding from the day and time at which their Guests can arrive to the day and time by which their Guests are required to leave the premises. This information is indicative and is intended to offer a richer experience to the Guests. Your Guests is not responsible for advances/delays in arrivals and departures.</li>
                        <li className={styles.textLiP}>The price. Hosts may want to apply the price they desire. Your Guests does not encourage any pricing policy. We apply a commission of 10.45% to Guests at the time of payment.</li>
                        <li className={styles.textLiP}>The application of the price according to the number of nights. If the number of nights is greater than 1 (one), then the Hosts may choose to apply the rate per night or for the entire nights.</li>
                        <li className={styles.textLiP}>Special rates for families and children. Hosts can also choose to apply their rate per couple head (rate applied to the two Guests forming a couple or who are parents) or per couple (rate applied once for the two guests forming a couple or who are parents).</li>
                        <li className={styles.textLiP}>Guests who will share the same building that the Hosts occupy for their night(s). These Guests will have access to a reservation page with the concerned building pre-booked. Other Guests cannot see the rooms in this building.</li>
                        <li className={styles.textLiP}>The content of the email or emails of invitation. Depending on whether the Guests are staying at the wedding venue or in hotels, guesthouses, or other accommodations, the email to be customized is different. Hosts can personalize their email so that it truly resembles them. Your Guests strongly advises leaving the information relating to the date and place. Your Guests is not required to provide a comprehensive list of all alternative lodging solutions for the case of the email to Guests who cannot stay at the wedding venue. Hosts must ensure that the list provided by Your Guests is up-to-date and may supplement it.</li>
                    </ul>

                    <p className={styles.boldtext}>
                        2.4 Reservation Tracking Dashboard
                    </p>
                    The reservation tracking dashboard offers Hosts a comprehensive view of past, ongoing, and unmade reservations. They are offered the opportunity to schedule a reminder email to Guests who have not made their reservation before a date set by them. The dashboard also offers them the possibility to access their Stripe tracking page, also called Stripe Dashboard. Stripe being the payment partner of Your Guests (See Article 6).
                    Finally, the dashboard allows Hosts to view the amount available in their online wallet and to request a transfer to the bank account registered with Stripe at the time of registration.

                    <h2 className={styles.subTitle}>
                        3. User Accounts
                    </h2>

                    Each user must create an account to access certain features of the service. You are responsible for the security of your password and all activities that occur under your account. In case of suspicion of fraud or security compromise, you must immediately notify us.
                    At the time of registration, the Host must proceed with our payment partner to complete personal and banking information. Your Guests does not have access to this data which exclusively links you with Stripe.

                    <h2 className={styles.subTitle}>
                        4. Intellectual Property
                    </h2>

                    The content provided by Your Guests, including but not limited to texts, graphics, logos, images, and their compilation, is the property of Your Guests and is protected by intellectual property laws.

                    <h2 className={styles.subTitle}>
                        5. User Content
                    </h2>

                    <p className={styles.boldtext}>5.1 User-Generated Content:</p>
                    Your Guests allows hosts to enrich their email communications with images, videos, and links via an integrated email editing interface (Quill with Unlayer). However, we would like to clarify that this content is not stored on our servers once the emails are sent. Our platform processes these content elements only as part of the email sending process, without recording or reusing this data subsequently.

                    <p className={styles.boldtext}>5.2 Intellectual Property of Content:</p>
                    When you use our email editor to add content such as images or videos, you warrant that you have the necessary rights over these contents or that you have obtained the required permissions for their use. Your Guests assumes no responsibility for content embedded in emails sent via our platform.

                    <p className={styles.boldtext}>5.3 Content Liability:</p>
                    Hosts are fully responsible for content embedded and sent via emails. They must ensure that this content respects intellectual property rights and does not violate any applicable law. Your Guests disclaims all liability related to the inappropriate use of these contents.


                    <h2 className={styles.subTitle}>
                        6. Pricing and Payments
                    </h2>

                    Regarding Hosts, the service is currently free but will evolve to a more comprehensive offer especially for parents of brides and grooms who might want to use our services for each of their children's weddings.
                    Regarding Guests, details regarding rates and payment terms are available at the time of booking.
                    Payments are processed by a secure third party, Stripe, and Your Guests does not store credit card information, third-party account (PayPal, Klarna), IBAN, or any other banking or payment information.
                    We strive to offer our users the most suitable rates, at the "fair price" of the services offered and that allow to sustain this platform.

                    <h2 className={styles.subTitle}>
                        7. Cancellations and Refunds
                    </h2>

                    Regarding Guests, if payment has not yet occurred, the cancellation of the ongoing reservation has no impact on their journey. They can resume where they left off.
                    If payment has already taken place, two options are available to them:

                    <ul className={styles.textUl}>
                        <li className={styles.textLiP}>The money has not yet been transferred to the married couple's account: <a className={styles.hrefColor} onClick={() => navigate("/contact")}>contact Your Guest immediately</a> who will be able to proceed with a full refund (commission included)</li>
                        <li className={styles.textLiP}>The money is already transferred to the Hosts' account and within a period of 14 days from the payment: contact them immediately to find an arrangement. Your Guests commits to refunding the commission collected if this deadline is respected. Beyond the 14-day period, Your Guests does not refund the commission taken on the transaction.</li>
                    </ul>

                    The cancellation and refund policy will be clearly communicated at the time of booking. Users are encouraged to read it carefully before finalizing a reservation.

                    Regarding Hosts, they are required to provide the most comprehensive guest list possible. The service we offer is not suitable for repeated modification of the guest list. If we notice misuse of our platform, we will intervene initially by a reminder of the terms of use and, if necessary, by suspension of the Host's account after the regulatory notice.
                    If Hosts wish to cancel their reservation project, they are asked to please contact us so that we can proceed with the cancellation and refund. The cancellation must be due to force majeure and unavoidable. Your Guests may require certain elements in order to validate the cancellation.

                    <h2 className={styles.subTitle}>
                        8. Modifications to the Service and Conditions
                    </h2>

                    Your Guests reserves the right to modify or discontinue the service at any time, with or without notice. We also reserve the right to modify these terms of use at any time. Your continued use of the service after such modifications constitutes your consent to these modifications.

                    <h2 className={styles.subTitle}>
                        9. Contact
                    </h2>

                    For any questions or concerns regarding these terms of use, we will be happy to assist you by contacting us via <a className={styles.hrefColor} onClick={() => navigate("/contact")}>the dedicated contact page</a>.

                    <h2 className={styles.subTitle}>
                        10. Rules of the Your Guests Platform
                    </h2>

                    <p className={styles.boldtext}>10.1 Regulation:</p> You must comply with these rules and must not assist or encourage others to breach or circumvent them.

                    <ul className={styles.textUl}>
                        <li className={styles.textLiP}><p className={styles.boldtext}>Act with integrity and treat others with respect:</p></li>
                        <ul className={styles.subList}>
                            <li className={styles.textLiP}>Do not lie, falsely represent something or someone, or pretend to be someone else.</li>
                            <li className={styles.textLiP}>Be polite and respectful when communicating or interacting with other users.</li>
                            <li className={styles.textLiP}>Respect our non-discrimination policy and do not discriminate or harass others.</li>
                        </ul>

                        <li className={styles.textLiP}><p className={styles.boldtext}>Protection of the Platform:</p></li>
                        <ul className={styles.subList}>
                            <li className={styles.textLiP}>Do not damage, hack, reverse engineer, compromise or alter the Your Guests platform in any way.</li>
                            <li className={styles.textLiP}>Do not use robots, crawlers, scrapers, or other automated means to access data or other content or retrieve them on the Your Guests platform or to interact with it.</li>
                        </ul>

                        <li className={styles.textLiP}><p className={styles.boldtext}>Appropriate Use of the Platform:</p></li>
                        <ul className={styles.subList}>
                            <li className={styles.textLiP}>Do not use the personal information of another user except as necessary to facilitate a transaction on the Your Guests platform, as authorized by these conditions.</li>
                            <li className={styles.textLiP}>Use the content made available by the Your Guests platform only as necessary to enable you to use the platform as a Host or Guest.</li>
                            <li className={styles.textLiP}>Do not request, perform, or accept a reservation or payment outside the Your Guests platform to avoid paying fees, taxes, or for any other reason.</li>
                        </ul>

                        <li className={styles.textLiP}><p className={styles.boldtext}>Respect your legal obligations:</p></li>
                        <ul className={styles.subList}>
                            <li className={styles.textLiP}>Understand and comply with the laws that apply to you, including laws on privacy, data protection, and export.</li>
                            <li className={styles.textLiP}>If you provide personal information relating to another person, you must do so in compliance with applicable law and you must be authorized to do so.</li>
                            <li className={styles.textLiP}>Read and comply with these Conditions.</li>
                        </ul>
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default Terms;