// api.js
import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // si vous avez besoin de gérer les cookies
  // autres configurations...
});

api.interceptors.request.use((config) => {
  // Define public paths that should not include credentials
  const publicPaths = ['/public/'];

  // Check if the requested URL matches any of the public paths
  const isPublicPath = publicPaths.some((path) => config.url.includes(path));

  // Set withCredentials to false for public paths, otherwise keep it true
  config.withCredentials = !isPublicPath;

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;

export const refreshToken = async () => {
  const response = await api.post('/auth/refresh-token');
  return response.data;
};

// Register User
export const registerUser = async (data) => {
  try {
    const response = await api.post('/public/auth/register', data);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

// Login User
export const loginUser = async (data) => {
  try {
    const response = await api.post('/auth/login', data);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

// Logout User
export const logoutUser = async () => {
  try {
    const response = await api.post('/auth/logout');
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

// Check Login Status
export const checkLoginStatus = async () => {
  try {
    const response = await api.post('/auth/check-login');
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const sendForgotPasswordEmail = async (email, isProfessional) => {
  return api.post('/public/auth/forgot-password', { email, isProfessional });
};

export const  validateResetCode = async (email, resetCode) => {
  return api.post('/public/auth/validate-reset-code', { email, resetCode });
};

export const resetPassword = async (email, code, newPassword) => {
  return api.post('/public/auth/reset-password', { email, code, newPassword });
};

export const fetchData = async (projectId) => {
  return api.get(`/projet/my-guests/${projectId}`);
};

export const SubmitData = async ({ projectId, fileData }) => {
  return api.post(`/projet/saveTable/${projectId}`, {
    guests: fileData.map(row => ({
      NOM: row.NOM,
      PRENOM: row.PRENOM,
      EMAIL: row.EMAIL,
      TELEPHONE: row.TELEPHONE,
      relationship: row.relationship,
      subGroupID: row.subGroupID,
      offert: row.offert
    }))
  });
};

export const UploadFile = async ({ projectId, formData }) => {
  return api.post(`/projet/upload/${projectId}`, formData);
};

export const getWorksheet = async ({ projectId, formData }) => {
  const response = await api.post(`/projet/worksheets/${projectId}`, formData);
  return response.data;
};

export const createNewProject = async ({ userId, description }) => {
  return api.post(
    `/projet/createNewProject`,
    { userId, description }
  );
};

export const getUserProjects = async (userId) => {
  return api.get(`/projet/getUserProjects/${userId}`);
};

export const getProjectIdByUserId = async (userId) => {
  const response = await api.get(`/projet/get-projet-id/${userId}`);
  return response.data;
};

export const getCurrentUser = async () => {
  return api.get(`/auth/current-user`);
};

export const updateUser = async (updatedUser) => {
  const reponse = api.put(`/user/update-user`, updatedUser);
  return reponse;
};

export const updateBasicInfo = async (updatedUser) => {
  const response = api.put('/user/update-basic-info', updatedUser);
  return response;
}

export const getUserInfo = async () => {
  return api.get(`/user/get-user-info`);
};

export const getPublicUserInfo = async (userId) => {
  return api.get(`/public/get-user-info`, { params: { userId } });
};

export const updateProjectDescription = async (userId, projectId, newDescription) => {
  return api.put(`/projet/updateProjectDescription/${userId}/${projectId}/description`,
    { description: newDescription }
  );
};

export const updateProjectStatus = async (userId, projectId, field, status) => {
  try {
    const response = api.put(`/projet/updateProjectStatus/${userId}/${projectId}`, {
      field, status
    });
    return response;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du statut du projet:', error);
    return null;
  }
};

export const getOverallStatus = async (userId, projectId) => {
  try {
    const response = api.get(`/projet/get-overallStatus/${userId}/${projectId}`);
    return response;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du statut du projet:', error);
    return null;
  }
};

export const getOpenProjectsCount = async () => {
  const response = api.get(`/projet/getOpenProjectsCount`);
  return response.data;
};

export const updateVenue = async (userId, projectId, venue) => {
  return api.put(`/projet/updateVenue/${userId}/${projectId}`,
    { venue: venue });
};

export const updateSleepLocation = async (projectId, guestUpdates, allowExtraBeds) => {
  try {
    const response = await api.put(`/projet/updateSleepLocation/${projectId}`, {
      guestUpdates,
      allowExtraBeds
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour des emplacements de sommeil des invités:', error);
    return null;
  }
};

export const setDate = async (projectId, data) => {
  try {
    const response = await api.post(`/projet/pricing/set-dates/${projectId}`
      , data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour des dates de début et fin:', error);
    throw error; 
  }
};

export const setPrice = async (projectId, rate, ruleType) => {
  try {
    const response = await api.post(`/projet/pricing/set-pricing/${projectId}`, {
      rate: rate,
      rateType: ruleType
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du prix:', error);
    throw error; 
  }
};

export const submitConfig = async (dataToSend) => {
  try {
    const response = await api.post(`/projet/pricing/set-config/${dataToSend.projectId}`, dataToSend);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la configuration:', error);
    throw error; 
  }
};

export const setRuleType = async (projectId, data) => {
  try {
    const response = await api.post(`/projet/pricing/set-rule-type/${projectId}`
      , data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'application du prix:", error);
    throw error; 
  }
};

export const getFamilyCount = async (projectId) => {
  return api.get(`/projet/count-families/${projectId}`);
};

export const getChildrenCount = async (projectId) => {
  return api.get(`/projet/count-children/${projectId}`);
};

export const getOtherGroupCount = async (projectId) => {
  return api.get(`/projet/count-other-groups/${projectId}`);
}

export const getNumberOfNights = async (projectId) => {
  try {
    const response = await api.get(`/projet/pricing/number-of-nights/${projectId}`);
    return response.data.numberOfNights;
  } catch (error) {
    console.error('Erreur lors de la récupération du nombre de nuits:', error);
    throw error;
  }
};

export const getSartEndDates = async (projectId) => {
  try {
    const response = await api.get(`/projet/pricing/start-end-dates/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération du nombre de nuits:', error);
    throw error;
  }
};

export const getSleepInVenue = async (projectId) => {
  return api.get(`projet/venue/sleepInVenue/${projectId}`)
};

export const getSleepInBnB = async (projectId) => {
  return api.get(`projet/venue/sleepInBnB/${projectId}`)
};

export const getLatLngProjectVenue = async (userId, projectId) => {
  return api.get(`projet/getVenueLatLng/${userId}/${projectId}`)
};

export const getPublicLatLngProjectVenue = async (userId, projectId) => {
  return api.get(`/public/getVenueLatLng/${userId}/${projectId}`)
};

export const getVenueID = async (userId, projectId) => {
  return api.get(`projet/getVenueID/${userId}/${projectId}`)
};

export const getVenueName = async (userId, projectId) => {
  return api.get(`projet/getVenueName/${userId}/${projectId}`)
};

export const getPublicVenueID = async (userId, projectId) => {
  return api.get(`/public/getVenueID/${userId}/${projectId}`)
};

export const getDescAndHotels = async (userId, projectId) => {
  return api.get(`projet/getProjectDescAndHotelsAround/${userId}/${projectId}`)
};

export const getGuestCount = async (projectId, groupId) => {
  try {
    const response = await api.get(`projet/count-guests/${projectId}/${groupId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du nombre d'invités:", error);
    throw error;
  }
};

export const getGuestsSleepingInVenue = async (projectId) => {
  try {
    const response = await api.get(`/projet/guests/sleeping-in-venue/${projectId}`);
    return response.data; // Assurez-vous que cela renvoie le nombre ou la liste des invités
  } catch (error) {
    console.error('Erreur lors de la récupération des invités dormant au venue:', error);
    throw error;
  }
};

export const getGuestsInfoResa = async (userId, projectId, guestId) => {
  try {
    const response = await api.get(`/public/guests/get-guests-info/${userId}/${projectId}`, {
      params: { guestId } // Utilisation de params pour passer guestId
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des informations des invités:', error);
    throw error;
  }
};

export const getPublicSartEndDates = async (projectId) => {
  try {
    const response = await api.get(`/public/pricing/start-end-dates/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération du nombre de nuits:', error);
    throw error;
  }
};

export const getPublicDescAndHotels = async (userId, projectId) => {
  return api.get(`/public/getProjectDescAndHotelsAround/${userId}/${projectId}`)
};

export const getPublicConfigs = async (projectId) => {
  try {
    const response = await api.get(`/public/pricing/get-config/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des configurations:', error);
  }
};


export const getConfigs = async (projectId) => {
  try {
    const response = await api.get(`projet/pricing/get-config/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des configurations:', error);
  }
};

export const sendContactEmail = async (formData) => {
  const data = new FormData();
  data.append('nom', formData.nom);
  data.append('prenom', formData.prenom);
  data.append('email', formData.email);
  data.append('sujet', formData.sujet);
  data.append('texte', formData.texte);

  // Ajoute le fichier s'il existe
  if (formData.fichier) {
    data.append('fichier', formData.fichier);
  }

  return api.post('/email/contact', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const saveEmailDesign = async (userId, projectId, htmlContent, editorType) => {
  return api.post(`projet/saveEmailDesign/${userId}/${projectId}`, {
    htmlContent,
    editorType // Ajout de editorType pour distinguer le type d'e-mail
  });
};

export const saveReservation = async (userId, projectId, reservationConfig) => {
  try {
    const response = await api.post(`/booking/book-a-room/${userId}/${projectId}`, {
      reservationConfig: reservationConfig,
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la réservation:', error);
    throw error; 
  }
}

export const savePublicReservation = async (userId, projectId, reservationConfig) => {
  try {
    const response = await api.post(`/public/book-a-room/${userId}/${projectId}`, {
      reservationConfig: reservationConfig,
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la réservation:', error);
    throw error; 
  }
}

export const getPublicReservationId = async (userId, projectId, guestId) => {
  try {
    const response = await api.get(`/public/reservation-id/${userId}/${projectId}`, {
      params: { guestId }
    });
    return response.data.reservationId;
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'ID de la réservation:', error);
    throw error;
  }
};

export const sendPublicPaymentConfirmationEmail = async (userId, projectId) => {
  try {
    const response = await api.post(`/public/send-payment-confirmation/${userId}/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la réservation:', error);
    throw error; 
  }
}

export const sendPaymentConfirmationEmail = async (userId, projectId) => {
  try {
    const response = await api.post(`/projet/send-payment-confirmation/${userId}/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la réservation:', error);
    throw error; 
  }
}

export const checkExistingReservation = async (userId, projectId, guestId, roomId = null) => {
  try {
    // Construire l'URL avec les paramètres de requête
    let url = `/booking/check-exist-booking/${userId}/${projectId}?guestId=${guestId}`;
    if (roomId) url += `&roomId=${roomId}`;

    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la vérification de la réservation existante:', error);
    throw error; 
  }
}

export const getPublicReservedRoomId = async (projectId) => {
  try {
    // Construire l'URL avec les paramètres de requête
    const response = await api.get(`/public/reservations/get-booked-roomId/${projectId}`)
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la vérification de la réservation existante:', error);
    throw error; 
  }
}

export const checkRoomAvailability  = async (projectId, roomId) => {
  try {
    // Construire l'URL avec les paramètres de requête
    const response = await api.get(`/public/reservations/is-room-available/${projectId}/${roomId}`)
    return response.data.available;
  } catch (error) {
    console.error('Erreur lors de la vérification de la réservation existante:', error);
    throw error; 
  }
}

export const getPublicOfferBool = async (userId, projectId, guestId, offert) => {
  try {
    const response = await api.get(`/public/validate-offert/${userId}/${projectId}/${guestId}/${offert}`)

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la vérification de la réservation existante:', error);
    throw error; 
  }
}


export const getPreBooked = async (userId, projectId, buildingId) => {
  try {
    let url = `/booking/get-prebooked/${userId}/${projectId}?buildingId=${buildingId}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la réservation:', error);
    throw error; 
  }
}

export const getResaInfo = async (projectId) => {
  try {
    const response = await api.get(`/booking/get-reservation-info/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la réservation:', error);
    throw error; 
  }
}

export const deleteExistingReservation = async (userId, projectId, guestId, roomId = null) => {
  try {
    // Construire l'URL avec les paramètres de requête
    let url = `/booking/delete-exist-booking/${userId}/${projectId}?guestId=${guestId}`;
    if (roomId) url += `&roomId=${roomId}`;

    const response = await api.post(url);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression de la réservation existante:', error);
    throw error; 
  }
}

export const deletePublicExistingReservation = async (userId, projectId, guestId, roomId = null) => {
  try {
    // Construire l'URL avec les paramètres de requête
    let url = `/public/delete-exist-booking/${userId}/${projectId}?guestId=${guestId}`;
    if (roomId) url += `&roomId=${roomId}`;

    const response = await api.post(url);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression de la réservation existante:', error);
    throw error; 
  }
}

export const resetPublicCancelledReservation = async (reservationId) => {
  try {
    const response = await api.post(`/public/reset-reservation`, {
      reservationId // Send reservationId in the body
    });

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la remise à zéro de la réservation', error);
    throw error; 
  }
};

export const deletePreBookedReservation = async (userId, projectId) => {
  try {
    // Construire l'URL avec les paramètres de requête
    const response = await api.post(`/booking/delete-exist-prebooked/${userId}/${projectId}`);

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression de la réservation existante:', error);
    throw error; 
  }
}

export const getScenariosByRooms = async (sanityRoomIds) => {
  try {
    const queryString = sanityRoomIds.map(id => `sanityRoomIds[]=${encodeURIComponent(id)}`).join('&');
    const response = await api.get(`/public/reservations/get-scenario-rooms?${queryString}`);

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la suppression de la réservation existante:', error);
    throw error; 
  }
};

export const getVenueMetrics = async (projectId, venueId) => {
  try {
    const response = await api.get(`/projet/get-venue-metrics/${projectId}?venueId=${venueId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des métriques du lieu:', error);
    throw error;
  }
};

export const getExtraBedsPrice = async (projectId, venueId) => {
  try {
    const response = await api.get(`/public/get-venue-extraBed-price/${projectId}?venueId=${venueId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des métriques du lieu:', error);
    throw error;
  }
};

export const getTotalBeds = async (projectId, venueId) => {
  try {
    const response = await api.get(`/public/get-venue-total-venue-beds/${projectId}?venueId=${venueId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des métriques du lieu:', error);
    throw error;
  }
};

export const deleteAssignedGroup = async (projectId, guestGroupId) => {
  try {
    await api.delete(`/projet/removeGroup/${projectId}/${guestGroupId}`)
  } catch (error) {
    console.error('Erreur lors de la suppression d\'un groupe assigné à une chambre:', error);
    throw error;
  }
}

export const fetchRoomAssignments = async ({ guestId, userId, projectId, buildingId, offert }) => {
  try {
    const response = await api.get(`/public/room-assignments`, { params: { guestId, userId, projectId, buildingId, offert } });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des affectations de chambres :", error);
    throw error;
  }
};

export const stripeAccount = async ({ email, firstName, lastName, birthDate, address }) => {
  try {
    const body = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      birthDate: birthDate,
      address: address
    };

    const response = await api.post('/stripe/account', body);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création d'un compte stripe :", error);
    throw error;
  }
};

export const getStripeBalance = async (accountId) => {
  try {
    const response = await api.get(`/stripe/balance/${accountId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du solde Stripe :", error);
    throw error;
  }
};

export const getStripeSecureBalance = async (accountId) => {
  try {
    const response = await api.get(`/stripe/balance-secure/${accountId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du solde Stripe :", error);
    throw error;
  }
};

export const getLoginLink = async (accountId) => {
  try {
    const response = await api.post('/stripe/create-login-link', { accountId });
    if (response.status === 200) {
      return response.data.url;
    } else {
      throw new Error(response.data.error || 'Failed to get login link');
    }
  } catch (error) {
    console.error('Error fetching login link:', error);
    return null;
  }
};

export const createCheckoutSession = async (roomDetails, stripeAmount, extraBedCost, userId, projectId, guestId, offert, buildingId, stripeAccountId) => {
  try {
    const response = await api.post('/stripe/create-checkout-session', {
      roomDetails,
      stripeAmount,
      extraBedCost, 
      userId,
      projectId,
      guestId,
      offert,
      buildingId,
      stripeAccountId
    });
    return response.data; // Retourne directement les données de la réponse
  } catch (error) {
    console.error('Erreur lors de la création d\'une session de checkout:', error);
    throw error;
  }
};

export const createPartnerCheckoutSession = async (venueName, venueAdress, contactEmail, tier, vatNumber, stripeAmount, vatRate) => {
  try {
    const response = await api.post('/stripe/partners/create-checkout-session', {
      venueName,
      venueAdress,
      contactEmail, 
      tier,
      vatNumber,
      stripeAmount,
      vatRate
    });
    return response.data; // Retourne directement les données de la réponse
  } catch (error) {
    console.error('Erreur lors de la création d\'une session de checkout:', error);
    throw error;
  }
};

export const cancelReservation = async (reservationId, option, additionalAmount, comment) => {
  try {
    const response = await api.post('/stripe/cancel-reservation', {
      reservationId,
      option,
      additionalAmount,
      comment
    });
    console.log('cancelReservation return:', response.data)
    return response.data;
  

  } catch (error) {
    console.error('Erreur lors de l\'annulation de la réservation:', error);
    throw error;
  }
}

export const confirmPublicReservation = async(userId, projectId, guestId, roomId) => {
  try {
    const response = await api.post('/public/confirm-reservation/', {
      userId,
      projectId,
      guestId,
      roomId
    });
    return response;
  } catch (error) {
    console.error('Erreur lors de la réservation:', error);
    throw error; 
  }
}

export const transferStripe = async(amount, accountId) => {
  try {
    const response = await api.post('/stripe/withdraw-funds', {
      amount,
      accountId
    });
    return response;
  } catch (error) {
    console.error('Erreur lors du transfert:', error);
    throw error;
  }
};

export const setAgreement = async(userId, projectId) => {
  try {
    const response = await api.post(`/projet/guests/set-data-and-consent-agreement/${userId}/${projectId}`);
    return response;
  } catch (error) {
    console.error('Erreur lors de la confirmation de l\'agreement et consentement :', error);
    throw error;
  }
};

export const setReminder = async(userId, projectId, frequency) => {
  try {
    const response = await api.post(`/projet/update-reminder-frequency`, {
      userId,
      projectId,
      frequency
    });
    return response;
  } catch (error) {
    console.error('Erreur lors de l\application du nouveau reminder :', error);
    throw error;
  }
};

export const getReminder = async(userId, projectId) => {
  try {
    // console.log("dans getReminder",  userId || '+'|| projectId)
    const response = await api.get(`/projet/get-reminder-frequency/${userId}/${projectId}`);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération du Reminder :', error);
    throw error;
  }
};

export const getReservationId = async (userId, projectId, guestId) => {
  try {
    const response = await api.get(`/public/reservation-id/${userId}/${projectId}`, {
      params: { guestId }
    });
    return response.data; // Return only the data
  } catch (error) {
    throw error;
  }
};

export const getAmountPaid = async (reservationId) => {
  try {
    const response = await api.get(`/public/reservation-amount-paid`, {
      params: { reservationId }
    });
    return response.data; // Return only the data
  } catch (error) {
    throw error;
  }
};

// export const checkVATNumberValidity = async (vatNumber) => {
//   try {
//     const response = await api.post(`/public/vat-validate`, {
//       vatNumber, // Pass vatNumber in the request body, not params
//     });
//     return response.data; // Return only the data
//   } catch (error) {
//     console.error('Error validating VAT:', error);
//     throw error;
//   }
// };

// export const checkEmailValidity = async (email) => {
//   try {
//     const response = await api.post(`/public/email-validate`, {
//       email, // Pass vatNumber in the request body, not params
//     });
//     return response; // Return only the data
//   } catch (error) {
//     console.error('Error validating email:', error);
//     throw error;
//   }
// };