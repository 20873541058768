import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-regular-svg-icons';
import styles from '../../Referral.module.css';


const Referral = ({ resetSidebar, sidebarReduced }) => {

  const sidebarWidth = sidebarReduced ? "50px" : "200px";

  const [emails, setEmails] = useState([]);

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const handleSendEmail = (index) => {
    const email = emails[index];
    // Envoyer l'email ici
  };

  return (
    <div className="referral">
      {sidebarReduced && 
        <button className={styles.returnButton} onClick={resetSidebar} style={{ marginLeft: `calc(${sidebarWidth} + 10px)` }}>
          <FontAwesomeIcon icon={faCircleLeft} size="2x" />
          {/* className="return-icon" */}
          <title>Retour</title>
        </button>
      }
      <h3>Parrainez vos amis !</h3>
      <p>Envoyez-leur une invitation pour bénéficier de réductions.</p>
      <div className="emails">
        {emails.map((email, index) => (
          <div key={index}>
            <input
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
            />
            <button onClick={() => handleSendEmail(index)}>Envoyer</button>
          </div>
        ))}
      </div>
      <button onClick={handleAddEmail}>Ajouter un email</button>
    </div>
  );
};

export default Referral;
