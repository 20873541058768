import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Input, Button, Tag } from 'antd';
import { utils, writeFile } from 'xlsx';
import { SearchOutlined } from '@ant-design/icons';
import { getGuestsSleepingInVenue, getResaInfo } from '../../api/api';

const DashboardTable = () => {
  const [guestInVenue, setGuestInVenue] = useState([]);
  const { projectId } = useParams();
  // console.log('projectId dans DashboardTable', projectId)


  useEffect(() => {
    const fetchGuestsSleepingInVenue = async () => {
      try {
        const response = await getGuestsSleepingInVenue(projectId);
        // console.log('response fetchGuestsSleepingInVenue', response)
        if (response && response.length > 0 && response[0].data) {
          const groupGuests = response[0].data; // Accéder à la liste des invités
          const guestsGroupedBySubGroupId = {};
          groupGuests.forEach(guest => {
            const groupId = guest.subGroupID.id;
            if (!guestsGroupedBySubGroupId[groupId]) {
              guestsGroupedBySubGroupId[groupId] = {
                groupGuests: [],
                name: guest.subGroupID.custGroupName,
                email: guest.EMAIL, // Assume the first guest's email is representative for the group
                phone: guest.TELEPHONE, // Same for the phone
                status: 'Non' // Default status
              };
            }
            guestsGroupedBySubGroupId[groupId].groupGuests.push(guest);
          });

          const reservations = await getResaInfo(projectId);
          // console.log('reservations', reservations)
          reservations.forEach(reservation => {
            if (guestsGroupedBySubGroupId[reservation.guestId]) {
              guestsGroupedBySubGroupId[reservation.guestId].status = reservation.status === 'confirmed' ? 'Oui' : 'En cours';
              guestsGroupedBySubGroupId[reservation.guestId].roomName = reservation.roomName;
              guestsGroupedBySubGroupId[reservation.guestId].buildingName = reservation.buildingName;
            }
          });

          setGuestInVenue(Object.values(guestsGroupedBySubGroupId));
        } else {
          console.log('No data received or data is empty');
        }
      } catch (error) {
        console.error('Error fetching guests sleeping in venue:', error);
      }
    };
    fetchGuestsSleepingInVenue();
  }, [projectId]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 90, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 50, marginRight: 8 }}
        >
          Rechercher
        </Button>
        <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
          Réinitialiser
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });


  const columns = [
    {
      title: 'Invités',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Chambre',
      dataIndex: 'roomName',
      key: 'roomName',
    },
    {
      title: 'Bâtiment',
      dataIndex: 'buildingName',
      key: 'buildingName',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: status => {
        let color = status === 'En cours' ? 'geekblue' : (status === 'Oui' ? 'green' : 'volcano');
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
      filters: [
        { text: 'Oui', value: 'Oui' },
        { text: 'En cours', value: 'En cours' },
        { text: 'Non', value: 'Non' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    }
  ];

  const DashboardTableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };


  const tableStyle = {
    width: '40%', // Contrôle la largeur de la table
    maxHeight: '70vh', // Contrôle la hauteur maximale de la table
  }

  //   const exportToCSV = () => {
  //     const json2csvParser = new JSON2CSVParser();
  //     const csv = json2csvParser.parse(guestInVenue);
  //     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //     saveAs(blob, 'table-data.csv');
  // };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(guestInVenue);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFile(workbook, 'table-data.xlsx');
  };


  return (
      <div style={DashboardTableStyle}>
            <Table 
              columns={columns} 
              dataSource={guestInVenue} 
              style={tableStyle} 
              size="small"
            />
            <div style={{ marginLeft: 20, textAlign: 'center' }}>
                <Button onClick={exportToExcel}>
                    Exporter en Excel
                </Button>
            </div>
        </div>
  );
};

export default DashboardTable;