import React, { useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import SpinningArrows from './SpinningArrows';
import { saveReservation, deleteExistingReservation, getPreBooked } from '../api/api';
import { usePreBookedGuests } from '../contexts/GuestsPreBookedContext';
import styles from '../DragDropGroomVenueInterface.module.css';

const DragDropVenueInterface = () => {
    const [movedGroups, setMovedGroups] = useState(new Set());
    const [errorMessage, setErrorMessage] = useState('');
    const [buildingCapacity, setBuildingCapacity] = useState(null);
    const { remainingCapacity, selectedGroups, setRemainingCapacity, setSelectedGroups, allGroups } = usePreBookedGuests();
    const [buildingDetails, setBuildingDetails] = useState(() => {
        const savedDetails = localStorage.getItem("buildingDetails");
        return savedDetails ? JSON.parse(savedDetails) : {}; // Initialisez avec une valeur par défaut si rien n'est trouvé
    });
    const { userId, projectId, buildingName, buildingId, venueId, totalCapacity } = buildingDetails;

    const ITEM_TYPE = 'guestGroup';

    useEffect(() => {
        const initializeCapacity = async () => {
            // Si remainingCapacity est déjà défini, ne faites rien
            if (remainingCapacity !== null) return;
            const initialCapacity = totalCapacity - 2;
            setRemainingCapacity(initialCapacity);
        };

        initializeCapacity();
    }, [buildingId, remainingCapacity, setRemainingCapacity]);

    useEffect(() => {
        const fetchPreBookedGuests = async () => {
            try {
                const preBookedGuests = await getPreBooked(userId, projectId, buildingId);
                if (preBookedGuests.length > 0) {
                    const updatedSelectedGroups = {};
                    preBookedGuests.forEach((preBooked) => {
                        const { guestId } = preBooked; // 'F1', 'F2', etc.
                        const groupData = allGroups[guestId]; // Récupérer le groupe correspondant dans allGroups
                        if (groupData) {
                            updatedSelectedGroups[guestId] = groupData; // Ajouter ce groupe à updatedSelectedGroups
                        }
                    });
                    setSelectedGroups(updatedSelectedGroups); // Mettre à jour selectedGroups avec les groupes pré-réservés
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des invités pré-réservés :", error);
            }
        };

        fetchPreBookedGuests();
    }, [userId, projectId, buildingId]);

    const GuestGroup = ({ group }) => {
        const groupData = allGroups[group];
        const [, drag] = useDrag(() => ({
            type: ITEM_TYPE,
            item: { group },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }));
        const isGroupMoved = movedGroups.has(group);

        return (
            <div ref={drag} className={`${styles.guestGroup} ${isGroupMoved ? styles.movedGroup : ''}`}
                style={{ backgroundColor: groupData?.groupGuests[0]?.subGroupID.color, color: groupData?.groupGuests[0]?.subGroupID.textColor }}>
                <h3 className={styles.groupInfoH4}>{groupData?.custGroupName}</h3>
            </div>
        );
    };

    const handleAddReservation = async (groupId) => {
        if (allGroups[groupId] && allGroups[groupId].groupGuests) {
            const groupSize = allGroups[groupId].groupGuests.length;
            const newMovedGroups = new Set(movedGroups);

            const reservationData = {
                userId: userId,
                projectId: projectId,
                venueId: venueId,
                buildingId: buildingId,
                buildingName: buildingName,
                roomId: '',
                roomName: '',
                guestId: groupId, // Utilisez groupId pour les invités
                status: 'prebooked',
                numberOfGuests: groupSize,
                roomCapacity: remainingCapacity,
                totalCapacity: totalCapacity
            };

            if (remainingCapacity - groupSize >= 0) {
                // Logique pour sauvegarder la réservation ici
                setRemainingCapacity(prev => prev - groupSize);
                await saveReservation(userId, projectId, reservationData);
                newMovedGroups.add(groupId);
                setMovedGroups(newMovedGroups);
            } else {
                console.error(`Capacité du bâtiment dépassée pour le bâtiment d'ID ${buildingId}.`);
                setErrorMessage('Il ne reste plus aucune place dans le bâtiment.');
            }
        } else {
            console.error(`Le groupe d'ID ${groupId} n'existe pas ou ne contient pas de propriété 'guests'.`);
        }
    };

    const handleRemoveReservation = async (groupId) => {

        await deleteExistingReservation(userId, projectId, groupId);
        setSelectedGroups(prevGroups => {
            const newGroups = { ...prevGroups };
            delete newGroups[groupId];
            return newGroups;
        });
        const groupSize = allGroups[groupId].groupGuests.length;
        setRemainingCapacity(prev => prev + groupSize);
        setMovedGroups(prevMovedGroups => new Set([...prevMovedGroups].filter(id => id !== groupId)));

    };

    const DropArea = ({ selectedGroups, setSelectedGroups, remainingCapacity, setRemainingCapacity }) => {
        if (!selectedGroups) {
            selectedGroups = {};
        }
        const [{ isOver }, drop] = useDrop(() => ({
            accept: ITEM_TYPE,
            drop: async (item) => {

                const groupSize = allGroups[item.group].groupGuests.length;

                if (remainingCapacity - groupSize >= 0) {
                    // Ajout du groupe déplacé à selectedGroups
                    setSelectedGroups(prevGroups => ({ ...prevGroups, [item.group]: allGroups[item.group] }));
                    setMovedGroups(prevMovedGroups => new Set([...prevMovedGroups, item.group]));
                    await handleAddReservation(item.group);
                } else {
                    console.error("Capacité insuffisante pour ajouter ce groupe d'invités.");
                    setErrorMessage('Il ne reste plus aucune place dans le bâtiment.');
                }
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }));

        return (
            <div ref={drop} className={`${styles.dropArea} ${isOver ? styles.dropAreaOver : ''}`}>
                <h3 className={styles.dropAreaTitle}>{buildingName} | Capacité restante : {remainingCapacity}</h3>
                {Object.entries(selectedGroups).map(([groupId, groupData]) => (
                    <div key={groupId} className={styles.dropAreaGroup}>
                        <div>
                            <h4 className={styles.groupInfoH4}>
                                {groupData.custGroupName || 'Célibataires / Sans Groupe'}
                            </h4>
                            {groupData.groupGuests.map((guest, guestIndex) => (
                                <p key={guestIndex}>{guest.PRENOM} {guest.NOM}</p>
                            ))}
                        </div>
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{ color: "#ff0000", position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
                            onClick={() => handleRemoveReservation(groupId)}
                        />
                        {errorMessage && (
                            <div style={{ color: 'red', marginTop: '10px' }}>
                                {errorMessage}
                            </div>
                        )}
                    </div>

                ))}
            </div>
        );
    };

    return (
        <div className={styles.dragDropSection}>
            <h1>Et vos invités de coeur ?</h1>
            <h2>
                Il y reste encore {totalCapacity - 2} places disponibles après votre réservation dans ce bâtiment. Vous pouvez dès à présent sélectionner vos plus proches invités que vous souhaiteriez placer dans le même bâtiment que vous.
                <br /> Il peut s'agir de vos parents, grand-parents, témoins, amis ou tout autre personne que vous souhaitez.
            </h2>
            <p><FontAwesomeIcon icon={faCircleInfo} size="sm" style={{ color: "#5465ff", }} className={styles.flipIcon} />Cliquez et maintenez sur un groupe d'invité à gauche puis déplacez et relâchez dans la zone de droite.</p>

            <div className={styles.dragDropInterface}>     
                <div className={styles.guestGroupArea}>
                    {Object.keys(allGroups).map(group => (
                        <GuestGroup key={group} group={group} allGroups={allGroups} />
                    ))}
                </div>
                <div className={styles.iconContainer}>
                    <SpinningArrows />
                    {/* <FontAwesomeIcon icon={faArrowRightArrowLeft} size="2xl" style={{color: "#4b1753",}} /> */}
                </div>
                <div className={styles.dropAreaContainer}>
                    <div className={styles.singleDropArea}>
                        <DropArea
                            selectedGroups={selectedGroups} // Pass selectedGroups comme prop
                            setSelectedGroups={setSelectedGroups}
                            remainingCapacity={remainingCapacity}
                            setRemainingCapacity={setRemainingCapacity}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DragDropVenueInterface;