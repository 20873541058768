import React from 'react';
import styles from '../ModalCheckBooking.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export default function ModalCheckBooking({ children, closeModal, onConfirm }) {
  const handleContainerClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <div className={styles.modal} onClick={handleContainerClick}>
      <div className={styles.modalContent}>
        <h2>
            <FontAwesomeIcon icon={faCircleInfo} size="2xl" style={{ color: "#3324bb", }} className={styles.flipIcon} />
            Attention !
        </h2>
        {children}
        <div className={styles.modalButtons}>
          <button onClick={closeModal} className={styles.buttonClose}>
            Annuler
          </button>
          <button onClick={onConfirm} className={styles.buttonConfirm}>
            Confirmer
          </button>
        </div>
      </div>
    </div>
  );
}
