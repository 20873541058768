import React from 'react';
import DragDropVenueInterface from './DragDropGroomVenueInterface';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Modal from 'react-modal'; // ou votre composant modale personnalisé
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../GroomGuestSelectionRoomsModal.module.css';

const SelectionRoomsModal = ({ onClose }) => {

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999, // Assurez-vous que ce z-index est supérieur à celui de tout autre élément de la page
            minWidth: '90%',
            minHeight: '90%',
            overflow: 'auto',
            borderRadius: '10px'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fond semi-transparent
            zIndex: 9998
        }
    };

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            style={modalStyle}
        >
            <button onClick={onClose} className={styles.closeButton}>
                <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '24px' }} />
            </button>
            <DndProvider backend={HTML5Backend}>
                <DragDropVenueInterface />
            </DndProvider>
            <div className={styles.buttonContainer}>
                <button onClick={onClose} className={styles.buttonTerminer}>
                    Terminer
                </button>
            </div>
        </Modal>
    );
};

export default SelectionRoomsModal;