import React, { useState } from 'react';
// import VenueList from './VenueList'; // Assume this is your list component
// import VenueMap from './VenueMap'; // Assume this is your map component
import { Link } from 'react-router-dom';
 import styles from '../PartnerPage.module.css'; // Using CSS modules for styling

const PartnerPage = () => {
  const [view, setView] = useState('list'); // Default view is the list

  return (
    <div className={styles.partnerPageContainer}>
      <div className={styles.viewSwitch}>
        <button onClick={() => setView('list')}>Liste</button>
        <button onClick={() => setView('map')}>Carte</button>
      </div>
      <div className={styles.partnerButton}>
        <Link to="/devenez-partenaire">Devenez partenaire</Link>
      </div>

      {/* Conditionally render the list or map based on the user's choice */}
      {/* {view === 'list' ? <VenueList /> : <VenueMap />} */}
    </div>
  );
};

export default PartnerPage;
