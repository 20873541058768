import { createClient } from '@sanity/client'

const sanClient = createClient({
  projectId: 'cswj8235', // Vous trouverez ceci dans manage.sanity.io ou dans votre fichier sanity.json
  dataset: 'production', // ou le nom de votre dataset
  apiVersion: '2024-05-09', // Utilisez une date actuelle pour obtenir les dernières fonctionnalités
  token: '', // Laissez vide pour un accès en lecture seule, ou utilisez un token pour les requêtes en écriture
  useCdn: true, // `false` si vous voulez assurer une fraîcheur des données à 100%
})

export default sanClient;