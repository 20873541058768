import React, { useState, useEffect } from 'react';
import { useRateConfig } from '../../contexts/RateConfigContext';
import { useParams } from 'react-router-dom';
import WaffleChart from './Waffle/WaffleChart';
import BarChart from './Bar/BarChart';
import { getGuestCount, getNumberOfNights, getConfigs, getFamilyCount, getOtherGroupCount } from '../../api/api';
import styles from '../../ChartsLogic.module.css';

const ChartsLogic = () => {
    const { projectId } = useParams();
    const [guestCounts, setGuestCounts] = useState({ parents: 0, enfants: 0, couples: 0, celibataires: 0, groupes: 0 });
    const [familyCount, setFamilyCount] = useState(null);
    const [groupCount, setGroupCount] = useState(null);
    const [totalOfGuests, setTotalOfGuests] = useState(0)
    const [numberOfNights, setNumberOfNights] = useState(0);
    const [guestsConfigs, setGuestsConfigs] = useState([]);
    const { rate,
        ruleType,
        specialFamilyRateAmount,
        specialGroupRateAmount,
        specialChildRateAmount,
        familyRatesValue,
        groupRatesValue,
        childrenRatesValue,
        headCoupleValue,
        refreshConfigs,
        resetContext
    } = useRateConfig();

    useEffect(() => {
        resetContext();
    }, []);

    const initialData = [
        { category: "Enfants", price: 0 },
        { category: "Célibataires", price: 0 },
        { category: "Parents", price: 0 },
        { category: "Couples", price: 0 },
        { category: "Famille", price: 0 },
        { category: "Total", price: 0 }
    ];

    const [chartData, setChartData] = useState(initialData);

    /* ************************************************************** */
    /* ************************* GLOBALE LOGIC *********************** */
    /* ************************************************************** */

    useEffect(() => {
        const fetchCalculationData = async () => {
            try {
                const [parentsResponse, enfantsResponse, couplesResponse, celibatairesResponse, otherGroupResponse, familyResponse, nightsResponse, configsResponse] = await Promise.all([
                    getGuestCount(projectId, 1),
                    getGuestCount(projectId, 4),
                    getGuestCount(projectId, 2),
                    getGuestCount(projectId, 3),
                    getGuestCount(projectId, 5),
                    getFamilyCount(projectId),
                    getNumberOfNights(projectId),
                    getConfigs(projectId)
                ]);

                const otherGroupCount = await getOtherGroupCount(projectId);

                setGuestCounts({
                    parents: parentsResponse.count,
                    enfants: enfantsResponse.count,
                    couples: couplesResponse.count / 2,
                    celibataires: celibatairesResponse.count,
                    groupes: otherGroupCount.data.count
                });

                setTotalOfGuests(parentsResponse.count + enfantsResponse.count + (couplesResponse.count / 2) + celibatairesResponse.count + otherGroupCount.data.count);
                setFamilyCount(familyResponse.data.count);
                setGroupCount(otherGroupResponse.count);
                setGuestsConfigs(configsResponse);
                setNumberOfNights(nightsResponse);

            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };

        fetchCalculationData();
    }, [projectId, refreshConfigs]);

    /* ************************************************************** */
    /* ************************* WAFFLE LOGIC *********************** */
    /* ************************************************************** */
    const waffleData = [
        {
            id: "parents",
            label: "Parents",
            value: guestCounts.parents
        },
        {
            id: "enfants",
            label: "Enfants",
            value: guestCounts.enfants
        },
        {
            id: "couples",
            label: "Couples",
            value: guestCounts.couples
        },
        {
            id: "célibataires",
            label: "Célibataires",
            value: guestCounts.celibataires
        },
        {
            id: "groupes",
            label: "Groupes",
            value: guestCounts.groupes
        },
        // ... Ajoutez d'autres catégories si nécessaire
    ];

    /* ************************************************************** */
    /* ************************** BAR LOGIC ************************* */
    /* ************************************************************** */
    function calculateCosts(guestCounts, familyCount, groupCount, guestsConfigs, numberOfNights) {

        let paymentRules = guestsConfigs.paymentRules || [];

        let specialFamilyRateConfig = paymentRules.find(config => config.appliesTo === 'adultsAndChildren') ||
            { amount: specialFamilyRateAmount, ruleType: ruleType, appliesTo: familyRatesValue, appliesBy: headCoupleValue };

        let specialChildrenRateConfig = paymentRules.find(config => config.appliesTo === 'childrenOfferStay' || config.appliesTo === 'childrenOnly') ||
            { amount: specialChildRateAmount, ruleType: ruleType, appliesTo: childrenRatesValue, appliesBy: headCoupleValue };

        let specialGroupRateConfig = paymentRules.find(config => config.appliesTo === 'otherGroups') ||
            { amount: specialGroupRateAmount, ruleType: ruleType, appliesTo: groupRatesValue, appliesBy: headCoupleValue };

        let generalRateConfig = paymentRules.find(config => config.appliesTo === 'everyone') ||
            { amount: rate, ruleType: ruleType, appliesTo: "everyone", appliesBy: headCoupleValue };

        let includeParentsChildrenInGeneralRate = true;
        let includeChildrenInGeneralRate = true;
        let totalCostsByCategory = {
            parents: 0,
            enfants: 0,
            couples: 0,
            celibataires: 0,
            familles: 0,
            groupes: 0,
            total: 0
        };

        if (specialFamilyRateConfig && specialFamilyRateConfig.appliesTo !== 'noOffer' && familyCount != null) {
            // Tarif spécial famille
            let guestCountsForFamilies = { familles: familyCount };

            let familyCost = calculateCostForGuests(
                guestCountsForFamilies,
                0,
                specialFamilyRateConfig,
                numberOfNights,
                false, // includeChildren - true car nous calculons pour les enfants
                false // includeParentsChildren - true car nous calculons pour les parents
                // familyCount,
                // specialFamilyRateConfig,
                // null
            );
   
            totalCostsByCategory.familles = familyCost.total;

            if (generalRateConfig) {
                let additionalCost = calculateCostForGuests(guestCounts, groupCount, generalRateConfig, numberOfNights, includeChildrenInGeneralRate, includeParentsChildrenInGeneralRate);
                if (typeof additionalCost.total === "string") {
                    additionalCost.total = Number(additionalCost.total);
                }
                totalCostsByCategory.couples += additionalCost.couples;
                totalCostsByCategory.celibataires += additionalCost.celibataires;
            } else {
                console.log("generalRateConfig est undefined");
            }

        } else if (specialChildrenRateConfig && specialChildrenRateConfig.appliesTo !== 'noOffer') {

            // Tarif spécial enfant ou gratuit pour enfant
            if (specialChildrenRateConfig.appliesTo === 'childrenOnly') {

                let guestCountsForChildren = { enfants: guestCounts.enfants };
                let childrenCost = calculateCostForGuests(
                    guestCountsForChildren,
                    specialChildrenRateConfig,
                    numberOfNights,
                    true, // includeChildren - true car nous calculons pour les enfants
                    false // includeParentsChildren - false car il ne s'agit pas des parents
                );
                totalCostsByCategory.enfants = childrenCost.total;

            }

            if (specialChildrenRateConfig.appliesTo === 'childrenOfferStay') {
                includeChildrenInGeneralRate = false;
            }

            if (generalRateConfig) {
                includeChildrenInGeneralRate = false;

                let additionalCost = calculateCostForGuests(guestCounts, groupCount, generalRateConfig, numberOfNights, includeChildrenInGeneralRate, includeParentsChildrenInGeneralRate);

                if (typeof additionalCost.total === "string") {
                    additionalCost.total = Number(additionalCost.total);
                }
                totalCostsByCategory.parents += additionalCost.parents;
                totalCostsByCategory.couples += additionalCost.couples;
                totalCostsByCategory.celibataires += additionalCost.celibataires;
            } else {
                console.log("generalRateConfig est undefined");
            }
        } else {
            // Pas de tarifs spéciaux famille ou enfants
            includeParentsChildrenInGeneralRate = true;
            includeChildrenInGeneralRate = true;
            if (generalRateConfig) {
                let additionalCost = calculateCostForGuests(guestCounts, groupCount, generalRateConfig, numberOfNights, includeChildrenInGeneralRate, includeParentsChildrenInGeneralRate);

                totalCostsByCategory.parents += additionalCost.parents;
                totalCostsByCategory.enfants += additionalCost.enfants;
                totalCostsByCategory.couples += additionalCost.couples;
                totalCostsByCategory.celibataires += additionalCost.celibataires;
                totalCostsByCategory.groupes += additionalCost.groupes;
            } else {
                console.log("generalRateConfig est undefined", paymentRules);
            }
        }

        if (specialGroupRateConfig) {
            let guestCountsForGroups = { groupes: guestCounts.groupes };

            let groupCost = calculateCostForGuests(
                guestCountsForGroups,
                guestCountsForGroups.groupes,
                specialGroupRateConfig,
                numberOfNights,
                false, // includeChildren - true car nous calculons pour les enfants
                false // includeParentsChildren - true car nous calculons pour les parents
                // familyCount,
                // specialFamilyRateConfig,
                // null
            );
            totalCostsByCategory.groupes = groupCost.total;

        }

        // totalCostsByCategory.total = totalCostsByCategory.parents + totalCostsByCategory.enfants + totalCostsByCategory.couples + totalCostsByCategory.celibataires + totalCostsByCategory.familles;
        // // Calculate the rest of the categories as before
        // let otherCategoriesCost = calculateCostForOtherCategories(guestCounts, groupCount, numberOfNights, paymentRules, includeParentsChildrenInGeneralRate, includeChildrenInGeneralRate);
        // Object.keys(otherCategoriesCost).forEach(key => {
        //     totalCostsByCategory[key] += otherCategoriesCost[key];
        // });

        totalCostsByCategory.total = Object.values(totalCostsByCategory).reduce((sum, val) => sum + val, 0);

        return totalCostsByCategory;
    }


function calculateCostForGuests(guestCounts, groupCount, rateConfig, numberOfNights, includeChildren, includeParentsChildren) {
    let multiplier = rateConfig.ruleType === 'perNight' ? numberOfNights : 1;

    let costByCategory = {};

    // Calculer le coût pour chaque catégorie d'invités, en tenant compte des exclusions
    for (let category in guestCounts) {
        let amount = rateConfig.amount * multiplier;

        if (category === 'parents' && !includeParentsChildren) {
            amount = 0;
        }
        if (category === 'enfants') {
            if (!includeChildren) {
                amount = 0;
            } else if (rateConfig.appliesTo === 'childrenOnly') {
                // Utiliser le tarif spécial pour les enfants
                amount = rateConfig.amount * multiplier;
            } else {
                // Si aucun tarif spécial n'est appliqué, utiliser le tarif général
                amount = rateConfig.amount * multiplier;
            }
        }

        if (category === 'couples') {
            // Doubler le coût pour les couples si le tarif s'applique par tête
            amount *= (rateConfig.appliesBy === 'head' ? 2 : 1) * multiplier;
        }

        if (category === 'parents' && includeParentsChildren) {
            // Si les parents sont traités comme des couples et le tarif s'applique par couple,
            // diviser le montant par 2.
            amount *= (rateConfig.appliesBy === 'couple' ? 0.5 : 1) * multiplier;
        }

        
        if (category === 'groupes') {
            if (rateConfig.appliesBy === 'couple') {
                let groupCouples = Math.floor(groupCount / 2);
                let isOdd = groupCount % 2 !== 0;
                amount = (groupCouples + (isOdd ? 1 : 0)) * rateConfig.amount * multiplier;
            } else { // Assumes "head" by default
                amount = groupCount * rateConfig.amount * multiplier;
            }
        }

        costByCategory[category] = guestCounts[category] * amount;
    }

    // Calcul du coût total
    costByCategory.total = Object.values(costByCategory).reduce((sum, val) => sum + val, 0);

    // costByCategory.total = costByCategory.parents + costByCategory.enfants + costByCategory.couples + costByCategory.celibataires + costByCategory.familles;
    return costByCategory;

};


useEffect(() => {
    // console.log("Dépendances dans useEffect:", { guestCounts, familyCount, guestsConfigs, numberOfNights, rate, ruleType, specialFamilyRateAmount, specialChildRateAmount, familyRatesValue, childrenRatesValue, headCoupleValue });
    if (guestCounts && familyCount != null && guestsConfigs && numberOfNights != null) {
        const costs = calculateCosts(guestCounts, familyCount, groupCount, guestsConfigs, numberOfNights);
        // console.log('costs du useEffect', costs)

        setChartData([
            { category: "Enfants", price: costs.enfants },
            { category: "Célibataires", price: costs.celibataires },
            { category: "Parents", price: costs.parents },
            { category: "Couples", price: costs.couples },
            { category: "Famille", price: costs.familles },
            { category: "Groupes", price: costs.groupes },
            { category: "Total", price: costs.total }
        ]);
    }
}, [guestCounts, familyCount, guestsConfigs, groupCount, numberOfNights, rate, ruleType, specialFamilyRateAmount, specialChildRateAmount, familyRatesValue, childrenRatesValue, headCoupleValue]);

return (
    <div className={styles.chartsLogicContainer}>
        <WaffleChart data={waffleData} />
        <BarChart key={JSON.stringify(chartData)} data={chartData} />
    </div>
);
};

export default ChartsLogic;