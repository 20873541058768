import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faCircleExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import SpinningArrows from './SpinningArrows';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import styles from '../DragDropInterface.module.css';
import { updateSleepLocation, deleteAssignedGroup } from '../api/api';
import ModalGuests from './ModalGuests';

const DragDropInterface = ({ domainGuests, hotelGuests, allGroups, setDomainGuests, setHotelGuests, setAllGroups, setAggregationResults }) => {
    const [movedGroups, setMovedGroups] = useState(new Set());
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isMaxCapaciteAtteinte, setIsMaxCapaciteAtteinte] = useState(false);
    const [allowExtraBeds, setAllowExtraBeds] = useState(false);
    const [guestNonLogesTotal, setGuestNonLogesTotal] = useState(0);
    const { projectId } = useParams();

    const updateGuestSleepLocation = async (updates, allowExtraBeds) => {
        const response = await updateSleepLocation(projectId, updates, allowExtraBeds);
        setAggregationResults(response.aggregationResults);
        setIsMaxCapaciteAtteinte(response.capaciteAtteinte);
        setGuestNonLogesTotal(response.guestNonLogesTotal);
    };

    const handleExtraBedsSwitchChange = async (event) => {
        setAllowExtraBeds(event.target.checked);
    };

    const deleteGuestsAssigned = async (projectId, groupId) => {
        try {
            await deleteAssignedGroup(projectId, groupId);
        } catch (error) {
            console.error('Erreur lors de la suppression du groupe d\'invités:', error);
        }
    };

    const handleUpdateAllGuests = async () => {
        const groupIds = Object.keys(allGroups);
        const updatedDomainGuests = [];
        const newMovedGroups = new Set(movedGroups);
        const updates = [];

        for (const groupId of groupIds) {
            const groupGuests = allGroups[groupId].guests;

            // Ajouter chaque invité du groupe à la liste des mises à jour
            groupGuests.forEach(guest => {
                updates.push({ subGroupId: guest.subGroupID.id, sleepInVenue: true, sleepInBnB: false });
                updatedDomainGuests.push({ ...guest, subGroupID: { ...guest.subGroupID, sleepInVenue: true, sleepInBnB: false } });
            });

            newMovedGroups.add(groupId);
        }

        try {
            await updateGuestSleepLocation(updates);
            setDomainGuests(updatedDomainGuests);
            setMovedGroups(newMovedGroups);
            setModalIsOpen(false);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de tous les invités:", error);
        }
    };

    const ITEM_TYPE = 'guest';

    // Composant représentant un invité individuel
    const Guest = ({ guest, startDrag }) => {
        const [, drag] = useDrag(() => ({
            type: ITEM_TYPE,
            item: { guest },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }));

        return (
            <div ref={drag} className="guest" onClick={() => startDrag(guest)}>
                <p>{guest.PRENOM} {guest.NOM}</p>
            </div>
        );

    };

    // Composant représentant un groupe d'invités
    const GuestGroup = ({ group }) => {
        const groupData = allGroups[group];
        const isSingleGroup = groupData.guests.some(guest => guest.subGroupID.id === "-1");

        const [, drag] = useDrag(() => ({
            type: ITEM_TYPE,
            item: { group },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }));
        const isGroupMoved = movedGroups.has(group);

        return (
            <div ref={drag}
                className={`${styles.guestGroup} ${isGroupMoved ? styles.movedGroup : ''}`}
                style={{
                    backgroundColor: groupData?.guests[0]?.subGroupID.color,
                    color: groupData?.guests[0]?.subGroupID.textColor
                }}
            >
                {isSingleGroup ? (
                    // Pour les célibataires, afficher 'Groupe non défini' et lister chaque invité
                    <>
                        <h3 className={styles.groupInfoH4}>Célibataires / Sans Groupe</h3>
                        {groupData?.guests.map((guest, index) => (
                            <Guest key={index} guest={guest} />
                        ))}
                    </>
                ) : (
                    // Pour les groupes (familles ou couples), afficher uniquement le nom du groupe
                    <h3 className={styles.groupInfoH4}>{groupData?.guests[0]?.subGroupID.custGroupName}</h3>
                )}
            </div>
        );
    };

    // Composant pour les zones de dépôt (Domaine et Hôtels/BnB)
    const DropArea = ({ area, setGuests, guests, isMaxCapaciteAtteinte }) => {

        const [{ isOver }, drop] = useDrop(() => ({
            accept: ITEM_TYPE,
            drop: (item, monitor) => {
                if (isMaxCapaciteAtteinte && area === "Lieu du mariage") {
                    return;
                }

                const didDrop = monitor.didDrop();
                if (didDrop) {
                    return;
                }

                const sleepInVenue = area === "Lieu du mariage";
                const sleepInBnB = area === "Hôtels/BnB";

                if (item.guest) {
                    // Traitement pour un invité célibataire
                    handleDrop(item, sleepInVenue, sleepInBnB); // Assurez-vous que handleDrop gère correctement les invités célibataires
                } else if (item.group) {
                    // Traitement pour un groupe
                    handleDrop(item, sleepInVenue, sleepInBnB); // handleDrop devrait également gérer les groupes

                    // Ajoutez le groupe aux groupes déplacés
                    setMovedGroups(prevMovedGroups => new Set([...prevMovedGroups, item.group]));
                }
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
            canDrop: () => !(isMaxCapaciteAtteinte && area === "Lieu du mariage"),
        }));

        const groupedGuestsInArea = guests.reduce((acc, guest) => {
            const groupId = guest.subGroupID.id;
            if (!acc[groupId]) {
                acc[groupId] = [];
            }
            acc[groupId].push(guest);
            return acc;
        }, {});

        const handleDrop = (item, sleepInVenue, sleepInBnB) => {
            const updates = [];
            if (item.guest) { // Mise à jour pour un invité célibataire                
                const updatedGuests = [...guests, { ...item.guest, sleepInVenue, sleepInBnB }];
                setGuests(updatedGuests);

                updates.push({ subGroupId: item.guest.subGroupID.id, sleepInVenue, sleepInBnB });
            } else if (item.group) { // Mise à jour pour un groupe
                const groupGuests = allGroups[item.group].guests;
                const updatedGuests = [...guests, ...groupGuests.map(guest => ({ ...guest, sleepInVenue, sleepInBnB }))];
                setGuests(updatedGuests);

                groupGuests.forEach(guest => {
                    updates.push({ subGroupId: guest.subGroupID.id, sleepInVenue, sleepInBnB });
                });
            }
            updateGuestSleepLocation(updates, allowExtraBeds);
        };

        const handleRemoveGroup = (groupId) => {
            const updates = [];
            updates.push({ subGroupId: groupId, sleepInVenue: false, sleepInBnB: false })
            updateGuestSleepLocation(updates);

            deleteGuestsAssigned(projectId, groupId);

            setDomainGuests(prevGuests => prevGuests.filter(guest => guest.subGroupID.id !== groupId));
            setHotelGuests(prevGuests => prevGuests.filter(guest => guest.subGroupID.id !== groupId));

            // Mise à jour de movedGroups pour retirer le groupId
            setMovedGroups(prevMovedGroups => new Set([...prevMovedGroups].filter(id => id !== groupId)));

            // Mise à jour de allGroups pour refléter le changement, sans supprimer le groupe de l'état global
            setAllGroups(previous => {
                const newAllGroups = { ...previous };
                const groupGuestIds = new Set(newAllGroups[groupId].guests.map(guest => guest.id)); // Utilisation d'un identifiant unique pour chaque invité

                return newAllGroups;
            });
        };

        return (
            <div ref={drop} className={`${styles.dropArea} ${isOver ? styles.dropAreaOver : ''} ${isMaxCapaciteAtteinte && area === "Lieu du mariage" ? styles.nonDroppable : ''}`}>
                <h3 className={styles.dropAreaTitle}>
                    {area}
                    {isMaxCapaciteAtteinte && area === "Lieu du mariage" ? " | Toutes les chambres sont affectées" : ""}
                </h3>
                {Object.entries(groupedGuestsInArea).map(([groupId, groupGuests]) => (
                    <div key={groupId} className={styles.dropAreaGroup}>
                        <div>
                            <h4 className={styles.groupInfoH4}>
                                {groupGuests[0]?.subGroupID.custGroupName || 'Célibataires / Sans Groupe'}
                            </h4>
                            {groupGuests.map((guest, index) => (
                                <p key={index}>{guest.PRENOM} {guest.NOM}</p>
                            ))}
                        </div>
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{ color: "#ff0000", position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
                            onClick={() => handleRemoveGroup(groupId)}
                        />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <section id="dragDropInterface" className={styles.dragDropSection}>
            <div className={styles.dragDropInterface}>
                <div className={styles.guestGroupArea}>
                    <div>
                        <label>
                            <Tooltip title="Attention, activer les lits d'appoints peut générer des coûts supplémentaires pour vos invités" arrow>
                                <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#3324bb" }} className="mr-3" />
                            </Tooltip>
                            Lit d'appoints ?
                            <Switch
                                checked={allowExtraBeds}
                                onChange={handleExtraBedsSwitchChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </label>
                    </div>
                    {Object.keys(allGroups).map(group => (
                        <GuestGroup key={group} group={group} allGroups={allGroups} />
                    ))}
                </div>
                <div className={styles.iconContainer}>
                    <SpinningArrows />
                    {/* <FontAwesomeIcon icon={faArrowRightArrowLeft} size="2xl" style={{color: "#4b1753",}} /> */}
                </div>
                <div className={styles.dropAreaContainer}>
                    <div className={styles.singleDropArea}>
                        <DropArea
                            area="Lieu du mariage"
                            setGuests={setDomainGuests}
                            guests={domainGuests}
                            isMaxCapaciteAtteinte={isMaxCapaciteAtteinte}
                        />
                    </div>
                    <div className={styles.singleDropArea}>
                        <DropArea
                            area="Hôtels/BnB"
                            setGuests={setHotelGuests}
                            guests={hotelGuests}
                        />
                    </div>
                </div>
            </div>
            {guestNonLogesTotal > 0 && (
                
                <div className={styles.warningMessage}>
                    <Tooltip title="Pour éviter qu'au moins une personne d'un groupe ne soit pas logé, envisagez d'activer les lits d'appoint si ce n'est pas déjà fait" arrow>
                        <FontAwesomeIcon icon={faTriangleExclamation} size="lg" style={{color: "#ff5706"}} className="mr-5" />  
                    </Tooltip>
                    Attention : Il y a {guestNonLogesTotal} personne{guestNonLogesTotal > 1 ? 's' : ''} d'un groupe qui ne pourra pas être logée{guestNonLogesTotal > 1 ? 's' : ''} dans la chambre attribuée par manque d'au moins 1 couchage.
                </div>
            )}

            <div className={styles.centerContainer}>
                <p>Si vous prévoyez que tous vos invités dorment sur le lieu de votre mariage, cliquez simplement sur le bouton suivant :</p>
                <button className={styles.modalButton} onClick={() => setModalIsOpen(true)}>
                    Tout le monde dort sur place
                </button>
                <ModalGuests
                    isOpen={modalIsOpen}
                    onClose={() => setModalIsOpen(false)}
                >
                    <h2>Confirmation</h2>
                    <p>Êtes-vous bien certain que le lieu de votre mariage prévoit suffisamment de couchages pour tous vos invités ?</p>
                    <button
                        className={styles.modalButton}
                        onClick={handleUpdateAllGuests}
                    >
                        Confirmer
                    </button>
                </ModalGuests>
            </div>
        </section>
    );
};

export default DragDropInterface;