import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import styles from '../ModalDates.module.css';

const ModalDates = ({ isOpen, onClose, onLeftButtonClick, onRightButtonClick }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.modalContainer} ref={modalRef}>
            <div className={styles.modalContent}>
                <h2>
                    <FontAwesomeIcon icon={faCircleInfo} size="2xl" style={{ color: "#3324bb", }} className={styles.flipIcon} />
                    Heures d'arrivée et de départ non définies
                </h2>
                <p>
                    Pour une journée sans stress : <span className={styles.highlight}>indiquez les heures auxquelles vos invités peuvent arriver et repartir !</span>
                    <br />
                    <br />
                    C'est <span className={styles.highlight}>LA</span> petite astuce qui facilite l'organisation de votre grand jour et permet à chacun de s'installer tranquillement.
                    <br /> Ainsi, tout le monde sera prêt à profiter pleinement des festivités, <span className={styles.highlight}>sans précipitation ni attente inutile.</span>
                </p>
                <div className={styles.buttonContainer}>
                    <button
                        onClick={onLeftButtonClick}
                        className={styles.buttonConfirmer}
                    // ... styles ou classes
                    >
                        Définir les horaires
                    </button>
                    <button
                        onClick={onRightButtonClick}
                        className={styles.buttonRetour}
                    // ... styles ou classes
                    >
                        Je ne définis pas d'horaires
                    </button>
                </div>
            </div>
        </div>
    );


};

export default ModalDates;
