// import React, { useState } from 'react';
// import { MapContainer, TileLayer } from 'react-leaflet';
// import SearchField from './LeafLetSearch';

// function WeddingMapComp({ handleVenueSelect, handleVenueNotFound, openModal  }) {
//   const [position, setPosition] = useState([48.866667, 2.333333]);
//   const [showSearch, setShowSearch] = useState(true);
//   const apiKey = process.env.REACT_APP_MAPBOX_API_KEY;


//   return (
//     <MapContainer
//       center={position}
//       zoom={15}
//       style={{ height: "600px", width: "80%", margin: "0 auto" }}
//     >
//       {showSearch && <SearchField onLocationSelect={handleVenueSelect} onLocationNotFound={handleVenueNotFound} openModal ={openModal} />}
//       <TileLayer
//         url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
//         attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a>'
//         id="mapbox/streets-v11"
//         accessToken={apiKey}
//       />
//     </MapContainer>
//   );
// }

// export default WeddingMapComp;

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, StandaloneSearchBox } from '@react-google-maps/api';
import useDebounce from './Debouncing/DebouncedSearchInput';
import sanClient from '../sanityClient';
import { urlFor } from './imageUrlBuilder';
import customMapStyles from './CustomMapStyles';
import weddingIcon from '../assets/icons/Marker_Events.png';
import NearbyPlaces from './GoogleMapsAPI/NearbyPlaces';
import RenderPlaces from './GoogleMapsAPI/RenderPlaces';
import styles from '../MapContainer.module.css'

const mapOptions = {
  styles: customMapStyles,
  mapTypeId: 'roadmap',
  disableDefaultUI: false,
  tilt: 0,
  gestureHandling: 'greedy'
};

const containerStyle = {
  width: '80%',
  height: '600px',
  margin: '0 auto',
};

const center = {
  lat: 48.866667,
  lng: 2.333333,
};

const libraries = ['places'];

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function WeddingMapComp({ handleVenueSelect, handleVenueNotFound, openModal }) {
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [places, setPlaces] = useState(null);
  const [venues, setVenues] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const searchBoxRef = useRef(null);
  const mapRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: libraries,
  });

  const handleSelect = async (venue) => {
    // Fetch nearby places if they have been loaded
    const hotelsAround = places ? places.map((place) => ({
      name: place.name,
      address: place.vicinity,
      rating: place.rating,
      latitude: place.geometry.location.lat,
      longitude: place.geometry.location.lng,
      website: place.cwebsite || '',  // Add website from place details if available
      phone: place.details?.formatted_phone_number,
      description: place.keyword,  // Use keyword as description
    })) : [];
  
    // Combine venue info from Sanity with nearby places
    const venueWithHotels = {
      ...venue,  // Sanity venue data
      hotelsAround,  // Add nearby places (hotels, BnBs, etc.)
    };
  
    setSelectedVenue(venueWithHotels);  // Update selected venue with hotels
    handleVenueSelect(venueWithHotels); // Pass the venue with nearby hotels to the parent
    openModal();
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onSearchBoxLoad = useCallback((ref) => {
    searchBoxRef.current = ref;
  }, []);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const location = place.geometry.location;

      mapRef.current.panTo({
        lat: location.lat(),
        lng: location.lng(),
      });

      handleShowLocation(location);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);  // Update search input on each keystroke
  };

  // Trigger search when the debounced search term changes
  useEffect(() => {
    if (debouncedSearchTerm) {
      // Perform search when debounced value updates
      onPlacesChanged();
    }
  }, [debouncedSearchTerm]);

  const fetchVenuesFromSanity = async (lat, lng) => {
    try {
      const query = `*[_type == "weddingVenue" && latitude == $lat && longitude == $lng]`;
      const sanityResponse = await sanClient.fetch(query, { lat, lng });

      if (sanityResponse.length > 0) {
        setVenues(sanityResponse);
        return sanityResponse[0];
      } else {
        handleVenueNotFound();
        return null;
      }
    } catch (error) {
      console.error('Error fetching data from Sanity:', error);
    }
  };

  const handleShowLocation = async (location) => {
    const lat = location.lat();
    const lng = location.lng();

    const venue = await fetchVenuesFromSanity(lat, lng);
    if (venue) {
      setSelectedVenue(venue); // Update selectedVenue if a venue is found
    }
  };

  const handleFetchPlaces = (fetchedPlaces) => {
    console.log('fetchedPlaces in MapContainer', fetchedPlaces);
    setPlaces(fetchedPlaces);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <>
      <StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={onPlacesChanged}>
        <input
          type="text"
          placeholder="Rechercher un lieu de mariage..."
          value={searchInput}
          onChange={handleSearchInputChange}
          style={{
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '300px',
            height: '40px',
            marginTop: '10px',
            padding: '0 12px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '5',
          }}
        />
      </StandaloneSearchBox>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={onMapLoad}
        options={mapOptions}
      >
        {venues.map((venue, index) => (
          <Marker
            key={index}
            position={{ lat: venue.latitude, lng: venue.longitude }}
            icon={{
              url: weddingIcon,
              scaledSize: new window.google.maps.Size(50, 50),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(25, 50),
            }}
            onClick={() => handleSelect(venue)}
          >
            {selectedVenue && selectedVenue._id === venue._id && (
              <InfoWindow
                position={{ lat: venue.latitude, lng: venue.longitude }}
                onCloseClick={() => setSelectedVenue(null)}
              >
                <div style={{ maxWidth: '500px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                    <h3 style={{ margin: 0, fontWeight: 'bold', fontSize: '16px' }}>{venue.name}</h3>
                  </div>
                  <img src={urlFor(venue.logo).auto('format').width(150).url()} alt={venue.name} style={{ width: '100%', height: 'auto', marginBottom: '8px' }} />
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                    <button
                      onClick={() => handleSelect(venue)}
                      className={styles.buttonS}
                    >
                      Sélectionner
                    </button>
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
        {selectedVenue && (
          <NearbyPlaces
            location={{ lat: selectedVenue.latitude, lng: selectedVenue.longitude }}
            keywords={['hotel', 'guest house', 'bed and breakfast', 'chalet', 'bnb', 'hostel']}
            apiKey={apiKey}
            onFetchPlaces={handleFetchPlaces}
          />
        )}
        <RenderPlaces places={places} />
      </GoogleMap>
    </>
  );
}

export default WeddingMapComp;



// Fetch hotels around a location
// const fetchHotelsAround = async (lat, lng) => {
//   const overpassQuery = `
//     [out:json];
//     (
//       node[tourism=hotel](around:30000,${lat},${lng});
//       node[tourism=guest_house](around:30000,${lat},${lng});
//       node[tourism=bed_and_breakfast](around:30000,${lat},${lng});
//       node[tourism=hostel](around:30000,${lat},${lng});
//       node[tourism=chalet](around:30000,${lat},${lng});
//     );
//     out;
//   `;
//   const overpassUrl = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(overpassQuery)}`;

//   try {
//     const overpassResponse = await fetch(overpassUrl);
//     const overpassData = await overpassResponse.json();
//     console.log('Overpass API Data:', overpassData); // Log the data
//     return overpassData.elements;  // Return the elements for processing
//   } catch (error) {
//     console.error('Error fetching hotels from Overpass API:', error);
//     return []; // Return an empty array in case of error
//   }
// };