import React, { useState } from "react";
import styles from '../Privacy.module.css'
import CookieConsentBanner from "../components/CookieBanner";

const Cookies = () => {
    const [showCustomizeBanner, setShowCustomizeBanner] = useState(false);

    const handleCloseBanner = () => {
        setShowCustomizeBanner(false); // Réinitialiser l'état pour permettre de rouvrir la bannière
    };

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <h1 className={styles.title}>
                    Politiques des cookies de Your Guests
                </h1>
                <button
                    className={styles.buttonCookie}
                    onClick={() => setShowCustomizeBanner(true)}
                >
                    Modifier mes préférences de cookies
                </button>
                <br />
                <p className={styles.dateUpdate}>
                    Dernière mise à jour : 03/05/2024
                </p>
                <br />

                <h2 className={styles.subTitle}>
                    Qu'est-ce qu'un cookie ?
                </h2>

                Les cookies sont de petits fichiers texte envoyés à votre navigateur par les sites web que vous visitez. Sur notre plateforme, les cookies sont utilisés exclusivement pour maintenir vos préférences de navigation, améliorant ainsi votre expérience utilisateur sans stocker d'informations personnelles ou de suivi.

                <h2 className={styles.subTitle}>
                    Quels types de cookies utilisent Your Guests ?
                </h2>

                Nous utilisons des cookies fonctionnels destinés à :
                <ul className={styles.textUl}>
                    <li className={styles.textLiP}>Sauvegarder votre session afin de suivre l'état de votre connexion et vous offrir une expérience la plus fluide possible.</li>
                </ul>
                <br />
                Bien que dans notre bannière de cookies nous indiquons des cookies de type publicitaire, média sociaux et performance, cette version de l'application web ne les exploite pas encore.

                <h2 className={styles.subTitle}>
                    Gestion et Suppression des Cookies
                </h2>
                Vous pouvez gérer ou supprimer les cookies à votre discrétion. Pour plus d'informations, consultez la fonction d'aide de votre navigateur. Voici des liens pour gérer les cookies sur les navigateurs les plus utilisés :
                <ul className={styles.textUl}>
                    <li className={styles.textLiP}><a className={styles.hrefColor} href="https://support.google.com/chrome/answer/95647">Google Chrome</a></li>
                    <li className={styles.textLiP}><a className={styles.hrefColor} href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></li>
                    <li className={styles.textLiP}><a className={styles.hrefColor} href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac">Safari</a></li>
                    <li className={styles.textLiP}><a className={styles.hrefColor} href="https://support.microsoft.com/fr-fr/topic/description-des-cookies-ad01aa7e-66c9-8ab2-7898-6652c100999d">Microsoft Edge</a></li>
                </ul>

                <h2 className={styles.subTitle}>
                    Disclaimer
                </h2>
                Nous pouvons mettre à jour la présente déclaration relative aux cookies de temps à autre afin de refléter, par exemple, les changements apportés aux cookies que nous utilisons ou pour d'autres raisons opérationnelles, juridiques ou réglementaires. Nous vous invitons donc à consulter régulièrement la présente déclaration sur les cookies afin de rester informé de notre utilisation des cookies et des technologies connexes. Pour plus d'informations sur les cookies, vous pouvez contacter via notre <a className={styles.hrefColor} href="/contact">page de contact</a>.

            </div>
            {showCustomizeBanner && (
                <CookieConsentBanner
                    setShowCustomizeBanner={setShowCustomizeBanner}
                    handleCloseBanner={handleCloseBanner}
                />
            )}
        </div>
    );
};

export default Cookies;