import React,  { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPen, faPenFancy } from '@fortawesome/free-solid-svg-icons';
import styles from '../ModalDisclaimer.module.css';

const ModalDisclaimer = ({ isOpen, onAgree, onCancel }) => {
    const modalRef = useRef();

    if (!isOpen) {
        return null;
    }
    return (

        <div className={styles.modalContainer} ref={modalRef}>
            <div className={styles.modalContent}>
                <h2 className={styles.titleModal}>Disclaimer</h2>
                <div>
                    Avant de continuer, merci de bien vouloir confirmer que les informations relatives à vos invités sont exactes. Si des e-mails sont mal orthographiés, certains de vos invités pourraient ne pas recevoir leur invitation.
                    <br/>
                    Assurez-vous d'avoir recuillis le consentement de vos invités qui ne sont ni en famille ni en couple pour les regrouper ensemble et leur permettre de partager une chambre.                   
                </div>
                <button 
                    onClick={onAgree}
                    className={styles.buttonValidate}
                >
                    <FontAwesomeIcon icon={faCircleCheck} style={{color:'#ffffff', marginRight:'4px'}} /> 
                    Je confirme l'exactitude des informations et avoir recueillis le consentement de mes invités
                </button>
                <button 
                    onClick={onCancel} 
                    style={{ marginRight: '10px' }}
                    className={styles.buttonCancel}
                >
                    <FontAwesomeIcon icon={faPenFancy} style={{color:'#ffffff', marginRight:'4px'}} />Revenir au tableau
                </button>
            </div>
        </div>

    );
};

export default ModalDisclaimer;
