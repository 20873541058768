import React from 'react';
import { Link, useParams } from 'react-router-dom';
import UploadFileComponent from '../components/UploadFileComponent';
import styles from '../DeposerFichier.module.css';
import excelImage from '../assets/Images/excel_column_order.png';
import notepadImage from '../assets/Images/notepad_column_order.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const sampleFileContent = `NOM,PRENOM,EMAIL,TELEPHONE\n`;

function DeposerFichierPage() {
    const { projectId } = useParams();

    const downloadSampleFile = () => {
        const element = document.createElement("a");
        const file = new Blob([sampleFileContent], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = "sample_invites.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return (
        <div className={styles.deposerContainer}>
            <div>
                <h1 className={styles.deposerContainerH1}>Veuillez déposer votre liste d'invités</h1>
                <h2 >Veillez a bien respecter le format du fichier (csv ou excel) ainsi que l'ordre de colonnes : NOM, PRENOM, EMAIL, TELEPHONE</h2>
            </div>
            <div className={styles.sampleSection}>
                <h2 className={styles.deposerContainerH2}>Exemple de format de fichier</h2>
                <div className={styles.imagesContainer}>
                    <div>
                        <p className={styles.deposerContainerP}>Ordre des colonnes dans Excel (.xlsx)</p>
                        <img src={excelImage} alt="Column order in Excel" className={styles.exampleImage} />
                    </div>
                    <div>
                        <p className={styles.deposerContainerP}>Ordre des colonnes au format texte (.csv ou .txt)</p>
                        <img src={notepadImage} alt="Column order in Notepad++" className={styles.exampleImage} />                        
                    </div>
                </div>  
            </div>
            <UploadFileComponent />
            <div className={styles.noFile}>
                <Link to={`/creer/liste-d-invites/${projectId}`}>
                    <button className={styles.createListButton}>
                        Vous n'avez pas de fichier ? Créer votre propre liste en ligne.
                    </button>
                </Link>
                Ou
                    <button onClick={downloadSampleFile} className={styles.downloadButton}>
                        Télécharger un fichier .csv d'exemple <FontAwesomeIcon icon={faDownload} className={styles.downloadIcon} />
                    </button>
            </div>
        </div>
    );
}

export default DeposerFichierPage;
