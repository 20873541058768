import React, { useRef } from 'react';
import EmailEditor from 'react-email-editor';
import styles from '../EmailEditor.module.css';

import venueDesign from '../assets/Files/ReactEmailEditor/Templates/venueDesign.json';
import bnbDesign from '../assets/Files/ReactEmailEditor/Templates/venueDesign.json';

const EmailEditorPage = ({ designType }) => {
    const emailEditorRef = useRef(null);

    const onReady = () => {
        console.log('onReady');
    };

    const onLoad = () => {
        // Charger le design en fonction du type
        if (designType === 'venue') {
            emailEditorRef.current.editor.loadDesign(venueDesign);
        } else if (designType === 'bnb') {
            emailEditorRef.current.editor.loadDesign(bnbDesign);
        }
    };

    const saveDesign = () => {
        emailEditorRef.current.editor.saveDesign((design) => {
            const jsonStr = JSON.stringify(design, null, 2);
            downloadFile(jsonStr, 'design.json');
        });
        emailEditorRef.current.editor.exportHtml((data) => {
            const { html } = data;
            downloadFile(html, 'design.html');
        });
        alert('Design JSON and HTML have been saved in files.');
    };

    const downloadFile = (content, fileName) => {
        const element = document.createElement('a');
        const file = new Blob([content], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
    };

    return (
        <div className={styles.emailEditorContainer}>
            <div className={styles.customTabContainer}>
                <button onClick={saveDesign} className={styles.customSaveTab}>Sauvegarder</button>
            </div>
            <EmailEditor
                ref={emailEditorRef}
                onReady={onReady}
                onLoad={onLoad}
                minHeight='1300px'
                options={{
                    projectId: 12345, // Remplacez par votre ID de projet Unlayer
                    appearance: {
                        theme: "dark",
                    },  
                    fonts: {
                        showDefaultFonts: false,
                        customFonts: [
                            {
                                label: 'Roboto',
                                value: 'Roboto, sans-serif',
                                url: 'https://fonts.googleapis.com/css?family=Roboto'
                            },
                            {
                                label: "Andale Mono",
                                value: "andale mono,times"
                            },
                            {
                                label: "Arial",
                                value: "arial,helvetica,sans-serif"
                            },
                            {
                                label: "Arial Black",
                                value: "arial black,avant garde,arial"
                            },
                            {
                                label: "Book Antiqua",
                                value: "book antiqua,palatino"
                            },
                            {
                                label: "Comic Sans MS",
                                value: "comic sans ms,sans-serif"
                            },
                            {
                                label: "Courier New",
                                value: "courier new,courier"
                            },
                            {
                                label: "Georgia",
                                value: "georgia,palatino"
                            },
                            {
                                label: "Helvetica",
                                value: "helvetica,sans-serif"
                            },
                            {
                                label: "Impact",
                                value: "impact,chicago"
                            },
                            {
                                label: "Symbol",
                                value: "symbol"
                            },
                            {
                                label: "Tahoma",
                                value: "tahoma,arial,helvetica,sans-serif"
                            },
                            {
                                label: "Terminal",
                                value: "terminal,monaco"
                            },
                            {
                                label: "Times New Roman",
                                value: "times new roman,times"
                            },
                            {
                                label: "Trebuchet MS",
                                value: "trebuchet ms,geneva"
                            },
                            {
                                label: "Verdana",
                                value: "verdana,geneva"
                            },
                            {
                                label: "Lobster Two",
                                value: "'Lobster Two',cursive",
                                url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700"
                            },
                            {
                                label: "Playfair Display",
                                value: "'Playfair Display',serif",
                                url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700"
                            },
                            {
                                label: "Rubik",
                                value: "'Rubik',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Rubik:400,700"
                            },
                            {
                                label: "Source Sans Pro",
                                value: "'Source Sans Pro',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700"
                            },
                            {
                                label: "Open Sans",
                                value: "'Open Sans',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700"
                            },
                            {
                                label: "Crimson Text",
                                value: "'Crimson Text',serif",
                                url: "https://fonts.googleapis.com/css?family=Crimson+Text:400,700"
                            },
                            {
                                label: "Montserrat",
                                value: "'Montserrat',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Montserrat:400,700"
                            },
                            {
                                label: "Old Standard TT",
                                value: "'Old Standard TT',serif",
                                url: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700"
                            },
                            {
                                label: "Lato",
                                value: "'Lato',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Lato:400,700"
                            },
                            {
                                label: "Raleway",
                                value: "'Raleway',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Raleway:400,700"
                            },
                            {
                                label: "Cabin",
                                value: "'Cabin',sans-serif",
                                url: "https://fonts.googleapis.com/css?family=Cabin:400,700"
                            },
                            {
                                label: "Pacifico",
                                value: "'Pacifico',cursive",
                                url: "https://fonts.googleapis.com/css?family=Pacifico"
                            }
                        ]
                    },
                }}
            />
        </div>
    );

};

export default EmailEditorPage;
