import React, { useState } from 'react';
import styles from '../CommentCancellation.module.css'

const CommentInputCancel = ({ onCommentChange, cols }) => {
  const [comment, setComment] = useState('');

  const handleChange = (e) => {
    const newComment = e.target.value;
    setComment(newComment);
    onCommentChange(newComment); // Notify parent component of the change
  };

  return (
    <div>
      <textarea
        id="comment"
        value={comment}
        onChange={handleChange}
        placeholder="Laissez un commentaire ou une explication à vos hôtes..."
        rows="4"
        cols={cols}
        className={styles.textArea}
      />
    </div>
  );
};

export default CommentInputCancel;