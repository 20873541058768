import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBuildingsRooms, fetchGroomRoom, fetchBuildingCapacity } from './GroomRoomSelectionHelpers';
import { getCurrentUser, getVenueID, saveReservation, checkExistingReservation, deleteExistingReservation, deletePreBookedReservation, getGuestsSleepingInVenue } from '../api/api';
import RoomCardCust from './RoomCardCustomPricing';
import ModalCheckBooking from './ModalCheckBooking';
import SelectionRoomsModal from './GroomGuestSelectionRoomsModal';
import styles from '../GroomRoomSelection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { usePreBookedGuests } from '../contexts/GuestsPreBookedContext';

const GroomRoomSelection = () => {
  const [groomRooms, setGroomRooms] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [existingReservation, setExistingReservation] = useState(null);
  const { projectId } = useParams();
  const { isBooked, setIsBooked, allGroups, setAllGroups, buildingDetails, setBuildingDetails } = usePreBookedGuests();

  useEffect(() => {
    const fetchGuestsSleepingInVenue = async () => {
      try {
        const response = await getGuestsSleepingInVenue(projectId);
        if (response && response.length > 0 && response[0].data) {
          const groupGuests = response[0].data; // Accéder à la liste des invités
          const guestsGroupedBySubGroupId = {};
          // Grouper les invités par subGroupId
          groupGuests.forEach(guest => {
            const groupId = guest.subGroupID.id;
            if (!guestsGroupedBySubGroupId[groupId]) {
              guestsGroupedBySubGroupId[groupId] = { groupGuests: [], custGroupName: guest.subGroupID.custGroupName };
            }
            guestsGroupedBySubGroupId[groupId].groupGuests.push(guest);
          });
          // Mettez à jour l'état avec les données groupées
          setAllGroups(guestsGroupedBySubGroupId);
        } else {
          console.log('No data received or data is empty');
        }
      } catch (error) {
        console.error('Error fetching guests sleeping in venue:', error);
      }
    };

    fetchGuestsSleepingInVenue();
  }, [projectId]);

  useEffect(() => {
    const loadRoomsAndBuildings = async () => {
      const userResponse = await getCurrentUser();
      const userId = userResponse.data._id;
      const buildingsData = await fetchBuildingsRooms(userId, projectId);
      const groomRoomsData = await fetchGroomRoom(userId, projectId);

      setBuildings(buildingsData);
      setGroomRooms(groomRoomsData);
    };
    loadRoomsAndBuildings();
  }, [projectId]);

  const handleRoomSelect = (roomKey) => {
    setSelectedRoom(roomKey);
  };

  const handleConfirmChange = async () => {
    const userResponse = await getCurrentUser();
    const userId = userResponse.data._id;

    await deleteExistingReservation(userId, projectId, userId, existingReservation.id);
    await deletePreBookedReservation(userId, projectId);

    setShowModalInfo(false);
    handleConfirmSelection();
  };

  const handleCancelChange = () => {
    setShowModalInfo(false);
    setErrorMessage('');
    // Autres actions en cas d'annulation
  };

  const openGuestSelectionModalWithDetails = (reservationDetails) => {
    setBuildingDetails(reservationDetails); // Utilisez setBuildingDetails pour stocker les détails de la réservation dans l'état
    setIsSelectModalOpen(true); // Ouvre le modal
  };

  const handleConfirmSelection = async () => {
    try {
      const userResponse = await getCurrentUser();
      const userId = userResponse.data._id;
      const venueData = await getVenueID(userId, projectId);
      const venueId = venueData.data.venueId;

      let selectedRoomDetails;
      let buildingId;
      let buildingName;

      groomRooms.forEach(building => {
        const room = building.groomRooms.find(room => room._key === selectedRoom);
        if (room) {
          selectedRoomDetails = room;
          buildingName = building.buildingName;
          buildingId = building._id;
        }
      });

      // Si non trouvé dans groomRooms, rechercher dans buildings
      if (!selectedRoomDetails) {
        buildings.forEach(building => {
          const room = building.rooms.find(room => room._key === selectedRoom);
          if (room) {
            selectedRoomDetails = room;
            buildingName = building.buildingName;
            buildingId = building._id;
          }
        });
      }

      const reservation = await checkExistingReservation(userId, projectId, userId);

      if (reservation) {
        if (reservation && reservation.roomId === selectedRoomDetails._key) {
          // Cas où l'utilisateur a déjà réservé la même chambre
          setErrorMessage('Vous avez déjà réservé cette chambre.');
          setTimeout(() => {
            setErrorMessage(null);
          }, 3000);
          return;
        } else if (reservation && reservation.roomId !== selectedRoomDetails._key) {
          // Cas où l'utilisateur souhaite changer sa réservation existante pour une nouvelle chambre
          setExistingReservation(reservation);
          setModalMessage(`Vous avez déjà réservé la chambre ${reservation.roomName}. Souhaitez-vous changer votre réservation pour la chambre ${selectedRoomDetails.name} ?`);
          setShowModalInfo(true);
        }
      } else {
        const capacityArray = await fetchBuildingCapacity(buildingId);
        let totalBedsValue = 0;
        if (capacityArray && capacityArray.length > 0) {
          totalBedsValue = capacityArray[0].totalBeds;
        }
        const reservationData = {
          userId: userId, // ID des futurs mariés
          projectId: projectId, // ID du projet en cours
          venueId: venueId, // ID du lieu de mariage
          buildingId: buildingId, // ID du bâtiment sélectionné
          buildingName: buildingName,
          roomId: selectedRoomDetails._key, // ID de la chambre sélectionnée
          roomName: selectedRoomDetails.name, // Nom de la chambre
          guestId: userId, // ID des futurs mariés (puisqu'ils sont les hôtes)
          status: 'confirmed', // Statut de la réservation
          numberOfGuests: 2,
          roomCapacity: selectedRoomDetails.beds,
          totalCapacity: totalBedsValue
        };

        await saveReservation(userId, projectId, reservationData);

        try {
          const reservation = await checkExistingReservation(userId, projectId, userId); // Assurez-vous que cette fonction appelle correctement votre API
          if (reservation) {
            openGuestSelectionModalWithDetails(reservation);
            setIsBooked(true);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération de la réservation : ", error);
        }
      };
      // openGuestSelectionModal();

    } catch (error) {
      console.error("Erreur lors de la confirmation de la sélection : ", error);
      // Gérez l'erreur comme vous le souhaitez (affichez un message à l'utilisateur, par exemple)
    }
  };

  return (
    <section id='groomRoomselection' className={styles.groomRoomsection}>
      <h2 className={styles.columnh2}>
        En tant qu'hôtes ne vous oubliez pas ! Choisissez votre cocon d'amour pour votre première nuit en tant que mariés.
      </h2>

      <div className={styles.columnsContainer}>
        <div className={styles.column}>
          <p className={styles.columnp}>Ces chambres sont recommandées par le lieu de votre mariage et d'autres mariés pour la qualité du meublement, literie, la vue, etc.</p>
          {groomRooms.map((building) =>
            building.groomRooms.map((groomRoom) => (
              <RoomCardCust
                key={groomRoom._key} // Utilisation de _key depuis groomRoom directement
                room={{ ...groomRoom, buildingName: building.buildingName }} // Passer l'objet de la chambre entière à RoomCard
                isSelected={groomRoom._key === selectedRoom}
                onSelect={() => handleRoomSelect(groomRoom._key)} // Utiliser _key pour identifier la chambre sélectionnée
              />
            ))
          )}
        </div>

        <div className={styles.divider}></div>

        <div className={styles.column}>
          <p className={styles.columnp}>Vous pouvez choisir ici une autre chambre, non recommandée, si cela est votre souhait.</p>
          <select onChange={(e) => setSelectedBuilding(e.target.value)} className={styles.selectStyle}>
            <option value="">Sélectionnez un bâtiment</option>
            {buildings.map((building) => (
              <option key={building._id} value={building._id}>{building.buildingName}</option>
            ))}
          </select>
          {selectedBuilding && buildings.find(building => building._id === selectedBuilding).rooms.map((room) => (
            <RoomCardCust
              key={room._key}
              room={room}
              isSelected={room._key === selectedRoom}
              onSelect={() => handleRoomSelect(room._key)}
            />
          ))}
        </div>
      </div>
      {isBooked || selectedRoom ? (
        <div className={styles.roomConfirmationContainer}>
          {selectedRoom && (
            <div className={styles.confirmationWithError}>
              <button
                className={styles.buttonConfirmer}
                onClick={handleConfirmSelection}
              >
                Confirmer la sélection
              </button>
              {errorMessage && (
                <div className={styles.errorMessage}>
                  {errorMessage}
                </div>
              )}
            </div>
          )}
          {isBooked && (
            <button
              className={styles.buttonConfirmer}
              onClick={() => openGuestSelectionModalWithDetails(buildingDetails)}
            >
              Ajouter mes invités <FontAwesomeIcon icon={faExternalLinkAlt} style={{ color: "#ffffff" }} size="sm" />
            </button>
          )}
        </div>
      ) : null}
      {showModalInfo && (
        <ModalCheckBooking closeModal={handleCancelChange} onConfirm={handleConfirmChange}>
          <p>{modalMessage}</p>
        </ModalCheckBooking>
      )}
      {isSelectModalOpen && (
        <SelectionRoomsModal
          onClose={() => setIsSelectModalOpen(false)}
        />
      )}
    </section>
  );
};

export default GroomRoomSelection;