import React from 'react';
import styles from '../Modal.module.css';

export default function Modal({ children, closeModal }) {
  const handleContainerClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <div 
      className={styles.modal}  
      onClick={handleContainerClick}
    >
      <div className={styles.modalContent}>
        {children}
        <button 
            onClick={closeModal}
            className="hover:underline text-black font-bold"    
        >
          Fermer  
        </button>
      </div>
    </div>
  );
}
