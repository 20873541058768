import React, { useState } from 'react';
import Modal from 'react-modal'; // Assurez-vous d'avoir un composant modal
import styles from '../VenueEquip.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCarRear, faTree } from '@fortawesome/free-solid-svg-icons';

const VenueDetails = ({ venueEquip }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999, // Assurez-vous que ce z-index est supérieur à celui de tout autre élément de la page
      minWidth: '40%',
      minHeight: '70%',
      maxHeight: '90%',
      overflow: 'auto',
      borderRadius: '10px',
      color: '#000000',
      paddingTop: '0px',
      paddingRight: '20px',
      paddingLeft: '20px', 
      paddingBottom: '20px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fond semi-transparent
      zIndex: 9998
    }
  };

   const iconMap = {
    "car-rear": faCarRear,
    "tree": faTree
  };

  const formatDetails = (venueEquip) => {
    const formatted = [];

    const data = venueEquip[0];

    // Traitement accès handicapés
    if (data.disabledAccessOptions) {
      formatted.push({
        title: "Options d'Accès pour les Handicapés",
        items: data.disabledAccessOptions.map(pmr => ({
          value: pmr.value,
          iconUrl: pmr.iconUrl
        }))
      });
    }

    // Traitement d'info sur le parc
    if (data.park) {
      formatted.push({
        title: "Parc",
        items: [{
          value: `${data.park} ha`,
          iconType: "tree",
          iconColor: "#000000",
          iconSize: "2.5rem",
          iconMarginR: "10px"
        }]
      });
    }

    // Traitement des loisirs (Entertainment)
    if (data.Entertainment) {
      formatted.push({
        title: "Loisirs",
        items: data.Entertainment.map(ent => ({
          title: ent.title,
          iconUrl: ent.iconUrl
        }))
      });
    }

    // Traitement de LinenDetails
    if (data.LinenDetails) {
      const items = [];
      if (data.LinenDetails.Provided) {
        items.push(...data.LinenDetails.Provided.map(provided => ({
          title: provided.title,
          iconUrl: provided.iconUrl,
          value: 'Fourni sans coût supplémentaire'
        })));
      }
      if (data.LinenDetails.AdditionalCost > 0) {
        items.push({
          title: "Coût supplémentaire pour le linge de lit",
          value: `${data.LinenDetails.AdditionalCost} €`
        });
      }
      formatted.push({
        title: "Linge de Lit",
        items
      });
    }

    if (data.TowelDetails) {
      const items = [];
      if (data.TowelDetails.Provided) {
        items.push(...data.TowelDetails.Provided.map(provided => ({
          title: provided.title,
          iconUrl: provided.iconUrl,
          value: 'Fourni sans coût supplémentaire'
        })));
      }
      if (data.TowelDetails.AdditionalCost > 0) {
        items.push({
          title: "Coût supplémentaire pour le linge de lit",
          value: `${data.TowelDetails.AdditionalCost} €`
        });
      }
      formatted.push({
        title: "Linge de Toilette",
        items
      });
    }

    // Traitement de Parking
    if (data.Parking) {
      const items = [];
      if (data.Parking.Equipment) {
        items.push(...data.Parking.Equipment.map(equip => ({
          title: equip.title,
          iconUrl: equip.iconUrl
        })));
      }
      if (data.Parking.ParkingType) {
        items.push(...data.Parking.ParkingType.map(type => ({
          title: type.title,
          iconUrl: type.iconUrl
        })));
      }
      if (data.Parking.Security) {
        items.push(...data.Parking.Security.map(sec => ({
          title: sec.title,
          iconUrl: sec.iconUrl
        })));
      }
      items.push({
        title: "Nombre de places",
        value: data.Parking.TotalSpaces,
        iconType: "car-rear",
        iconColor: "#000000",
        iconSize: "2.5rem",
        iconMarginR: "10px"
      });
      formatted.push({
        title: "Parking",
        items
      });
    }

    // Traitement politique Animaux de compagnies
    if (data.petPolicies) {
      formatted.push({
        title: "Animaux de compagnie",
        items: data.petPolicies.map(pet => ({
          value: pet.value,
          iconUrl: pet.iconUrl
        }))
      });
    }

    // Traitement politique fumeur
    if (data.SmokingPolicy) {
      formatted.push({
        title: "Politique Fumeur",
        items: [{
          value: data.SmokingPolicy.value,
          iconUrl: data.SmokingPolicy.iconUrl
        }]
      });
    }

    return formatted;
  };

  const venueDetailsFormatted = formatDetails(venueEquip);

  const allItems = Object.keys(venueEquip[0] || {}).reduce((acc, key) => {
    const item = venueEquip[0][key];
    if (Array.isArray(item)) {
      // C'est un tableau d'objets, probablement avec des titres et des iconUrl
      const items = item.map(subItem => ({
        title: subItem.title || key,
        value: subItem.value,
        iconUrl: subItem.iconUrl
      }));
      return [...acc, ...items];
    } else if (typeof item === 'object' && item !== null) {
      if (item.Provided && Array.isArray(item.Provided)) {
        // Gérer les détails des linges fournis comme un cas spécial
        const providedItems = item.Provided.map(providedItem => ({
          title: providedItem.value || providedItem.title || key,
          value: `Fourni sans coût supplémentaire`,
          iconUrl: providedItem.iconUrl
        }));
        acc = [...acc, ...providedItems];
      }
      if (typeof item.AdditionalCost === 'number' && item.AdditionalCost > 0) {
        acc.push({
          title: `Coût supplémentaire pour ${key}`,
          value: `${item.AdditionalCost} €`,
        });
      }
      return acc;
    }
    return acc;
  }, []);

  return (
    <>
      <div className={styles.detailsContainer}>
        <h2 className={styles.detailsH2}>Ce que propose ce lieu</h2>
        <div className={styles.contentContainer}>
          <div className={styles.iconDisplay}>
            {allItems.slice(0, 5).map((item, index) => (
              <div key={index} className={styles.itemDisplay}>
                <img src={item.iconUrl} alt={item.title} className={styles.iconImage} />
                <span>{item.title || item.value}</span>
              </div>
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={openModal} className={styles.moreDetailsButton}>
              Voir les autres équipements
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <div className={styles.modalHeader}>
          <button onClick={closeModal} className={styles.closeButton}>
            <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '24px' }} />
          </button>
          <h2 className={styles.modalTitle}>Ce que propose ce lieu</h2>
        </div>
        <div className={styles.modalBody}>
          {venueDetailsFormatted.map((section, idx) => (
            <div key={idx}>
              <h3 className={styles.modalH2}>{section.title}</h3>
              {section.items.map((item, itemIdx) => (
                <div key={itemIdx} className={styles.itemDisplay}>
                  {item.iconUrl ? (
                    <img src={item.iconUrl} alt={item.title} title={item.title} className={styles.modalIcon} />
                  ) : item.iconType ? (
                    <FontAwesomeIcon icon={iconMap[item.iconType]} style={{ color: item.iconColor, fontSize: item.iconSize, marginRight: item.iconMarginR }} />
                  ) : null}
                  <span>
                    {item.title && item.value ? `${item.title}: ${item.value}` :
                      item.title ? item.title :
                        item.value ? item.value :
                          'Information non disponible'}
                  </span>
                </div>
              ))}
              <div className={styles.divider}></div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default VenueDetails;
