
import { useCallback } from "react";
import { SubmitData } from "../api/api";
import { mapRelationshipToGroupID } from "./relationship";

export const useBusinessLogic = (fileData, setFileData, setHasUnsavedChanges, setSuccessMessage, setErrorMessage, setIsFamily, setIsPartner, setIsOtherGroup, uniqueKey, setUniqueKey) => {

    const handleSubmit = async (projectId) => {
        try {
            
            const response = await SubmitData({ projectId, fileData }); // Envoyer fileData directement
            
            if (response.data && response.data.updatedData) {

                setFileData(response.data.updatedData.map(row => ({ ...row, isSelected: false, _id: row._id })));

                const allIsSelectedFalse = response.data.updatedData.every(row => row.isSelected === false);

                setSuccessMessage("Données sauvegardées avec succès");
                setErrorMessage("");

                localStorage.setItem('unsavedChanges', false);

            } else {
                console.error("updatedData manquant dans la réponse", response.data);
                setSuccessMessage("");  // Réinitialisez le message de succès
                setErrorMessage("Erreur lors de la sauvegarde des données");  // Message d'erreur
            }

        } catch (error) {
            setSuccessMessage("");  // Réinitialisez le message de succès
            setErrorMessage(`Erreur lors de l'appel API: ${error.message}`);  // Message d'erreur
        }
    };

    //Ajouter une ligne
    const handleAddRow = () => {
        // const updatedrowsData = [...fileData];
        const newRow = {
            id: Date.now(),
            NOM: 'Nom',
            PRENOM: 'Prénom',
            EMAIL: 'Email@email.com',
            TELEPHONE: '06...',
            relationship: '',
            groupID: -1,
            subGroupID: {
                id: '-1', // Par défaut
                name: '',  // Par défaut
                custGroupName: '',  // Par défaut
                color: '#FFFFFF',  // Par défaut
                textColor: '#000000',  // Par défaut
                sleepInVenue: false,  // Par défaut
                sleepInBnB: false  // Par défaut
            },
            offert: false,
            isSelected: false
        }
        // updatedrowsData.push({ id: Date.now(), nom: '', prenom: '', mail: '', telephone: '', relationship: '', offert: false });
        setFileData([...fileData, newRow]);
        setUniqueKey(uniqueKey + 1);
    };

    const handleEdit = useCallback((id, field, value) => {
        setFileData((prevFileData) => {
            const updatedFileData = [...prevFileData];
            const index = updatedFileData.findIndex(row => row._id === id || row.id === id);
            if (index !== -1) {
                updatedFileData[index][field] = value;
            }
            return updatedFileData;
        });
        setHasUnsavedChanges(true);
    }, [setFileData, setHasUnsavedChanges]);

    // Supprimer une ligne
    const handleDelete = (idToDelete) => {
        if (!fileData) return;

        setFileData((prevFileData) => {
            const newFileData = prevFileData.filter(row => row._id !== idToDelete && row.id !== idToDelete);
            return newFileData;
        });
        setHasUnsavedChanges(true);
    };

    //Relation invités
    const handleRelationshipChange = (e, _id, id) => {
        const actualId = _id || id;  // Utilisez l'id fourni
        setFileData((prevFileData) => {
            const index = prevFileData.findIndex(row => row._id === actualId || row.id === actualId);

            if (index === -1) {
                console.error(`Row with id ${actualId} not found`);
                return prevFileData;
            }

            const updatedRows = [...prevFileData];
            const selectedValue = e.target.value;

            if (updatedRows[index]) {
                updatedRows[index].relationship = selectedValue;
                const newGroupID = mapRelationshipToGroupID(selectedValue);
                updatedRows[index].groupID = newGroupID;

                // Utilisez updatedRows pour vérifier subGroupID
                if (updatedRows[index].subGroupID) {
                    updatedRows[index].subGroupID = updatedRows[index].subGroupID;
                }
            } else {
                console.error("Index invalide");
            }

            return updatedRows;
        });
    };

    const handleOfferChange = (id) => {
        setFileData(prevFileData =>
            prevFileData.map(row => {
                if (row._id === id || row.id === id) {
                    return { ...row, offert: !row.offert };
                }
                return row;
            })
        );
    };

    const handleGroupSelection = () => {
        const selectedRows = fileData.filter(row => row.isSelected);
        const isFamilyGroup = selectedRows.length > selectedRows.every(row => ["Parent", "Enfant"].includes(row.relationship));
        setIsFamily(isFamilyGroup);
        const isPartnerGroup = selectedRows.length === 2 && selectedRows.every(row => row.relationship === "Partenaire");
        setIsPartner(isPartnerGroup);
        const isFriendGroup =  selectedRows.length >= 3 && selectedRows.every(row => row.relationship === "Groupe");
        setIsOtherGroup(isFriendGroup)
    };

    const handleCheckboxClick = (id) => {
        setFileData(prevFileData =>
            prevFileData.map(row => {
                if (row._id === id || row.id === id) {
                    return { ...row, isSelected: !row.isSelected };
                }
                return row;
            })
        );
    };

    return {
        handleSubmit,
        handleAddRow,
        handleEdit,
        handleOfferChange,
        handleDelete,
        handleRelationshipChange,
        handleGroupSelection,
        handleCheckboxClick
    };
};
