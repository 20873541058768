import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import iconHotels from '../../assets/icons/Icône hôtel YG Gmap.png';
import iconChalet from '../../assets/icons/Icône chalet YG Gmap.png';
import iconBnb from '../../assets/icons/Icône bnb YG Gmap.png';
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa"; // Import star icons
import styles from '../../GoogleReviewsCarousel.module.css';

// Helper function to determine icon based on keyword
const getIconForKeyword = (keyword) => {
  const keywordLower = keyword.toLowerCase();

  if (keywordLower.includes('lodging')) return iconHotels;
  if (keywordLower.includes('guest house')) return iconBnb;
  if (keywordLower.includes('bed and breakfast') || keywordLower.includes('bnb')) return iconBnb;
  if (keywordLower.includes('chalet')) return iconChalet;
  if (keywordLower.includes('hostel')) return iconHotels;

  // Fallback to default icon if no types match
  return iconHotels;
};

const RenderPlaces = ({ places }) => {
  const [selectedPlace, setSelectedPlace] = useState(null);

  if (!window.google) {
    return null; // or a loading indicator
  }


  const handleMarkerClick = (place) => {
    setSelectedPlace(place); // Set the clicked place as selected
  };


  const handleCloseInfoWindow = () => {
    setSelectedPlace(null); // Close the InfoWindow
  };
  // Safeguard to handle null or undefined `places`
  if (!places || places.length === 0) {
    return null; // Render nothing or a message indicating no places found
  }

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Full stars
    const halfStar = rating % 1 >= 0.5; // Half star
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Empty stars

    return (
      <div className={styles.starsContainer}>
        {[...Array(fullStars)].map((_, i) => <FaStar key={`full-${i}`} className={styles.starFilled} />)}
        {halfStar && <FaStarHalfAlt className={styles.starHalf} />}
        {[...Array(emptyStars)].map((_, i) => <FaRegStar key={`empty-${i}`} className={styles.starEmpty} />)}
      </div>
    );
  };

  return (
    <>
      {places.map((place) => {
        if (!place || !place.geometry || !place.geometry.location || !place.place_id) {
          console.warn('Invalid place data:', place);
          return null;
        }

        return (
          <Marker
            key={place.place_id}
            position={place.geometry.location}
            icon={{
              url: getIconForKeyword(place.keyword),
              scaledSize: new window.google.maps.Size(45, 58.8),
              anchor: new window.google.maps.Point(20, 52),
            }}
            onClick={() => handleMarkerClick(place)}
          >
            {selectedPlace && selectedPlace.place_id === place.place_id && (
              <InfoWindow
                position={place.geometry.location}
                onCloseClick={handleCloseInfoWindow}
              >
                <div style={{ maxWidth: '300px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                    <h3 style={{ margin: 0, fontWeight: 'bold', fontSize: '16px' }}>{place.name}</h3>
                  </div>
                  <p>Note: {renderStars(place.rating)}</p>
                  <p>{place.vicinity}</p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </>
  );
};

export default RenderPlaces;



// BNB <a href="https://www.flaticon.com/fr/icones-gratuites/lit-et-petit-dejeune" title="lit et petit déjeuné icônes">Lit et petit déjeuné icônes créées par Freepik - Flaticon</a>
// HOTEL <a href="https://www.flaticon.com/fr/icones-gratuites/lit-et-petit-dejeune" title="lit et petit déjeuné icônes">Lit et petit déjeuné icônes créées par Freepik - Flaticon</a>
// CHALET <a href="https://www.flaticon.com/fr/icones-gratuites/chalet" title="chalet icônes">Chalet icônes créées par surang - Flaticon</a>